import { useMutation, useQuery } from '@apollo/client';
import React,{useState} from 'react'
import { Container,Form,Button,Col,Row,Image,Spinner,Modal } from 'react-bootstrap'
import { MUTATION_EDIT_PAYMENT_GATEWAY, MUTATION_PAYMENT } from '../user/grqphqloperation/mutation';
import Resizer from "react-image-file-resizer";
import uniqid from "uniqid";
import swal from "sweetalert";
import AWS from "aws-sdk";
import { FiEdit } from "react-icons/fi";
import { PAYMENT_INTRAGATION } from '../user/grqphqloperation/query';
import AdminPageLayout from '../admin/AdmnPageLayout';

const ID = "AKIA6GB4RFKTDTDA6E2O";
const SECRET = "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";
const BUCKET_NAME = "byaahlagan-profile-image";

const s3 = new AWS.S3({
    accessKeyId: ID,
    secretAccessKey: SECRET,
  });
export default function PaymentGateway() {

  const{data,laoding} =  useQuery(PAYMENT_INTRAGATION)
  console.log("data",data)

  const[editState,setEditState] =useState(false)
 
    const [validated, setValidated] = useState(false);

    const[imageFile,setImage] =useState('')
    const[upiId,setUpiID] =useState('')

    const[imageFileEdit,setImageEdit] =useState('')
    const[upiIdEdit,setUpiIDEdit] =useState(data && data.getPayament[0].upiID)
    const[getId,setGetId] =useState(data && data.getPayament[0].id)
    const[stop,setStop]=useState(false)

    console.log(imageFileEdit)

    if( stop === false && data){
   
     setUpiIDEdit(data && data.getPayament[0].upiID)
     setGetId(data && data.getPayament[0].id)
     setStop(true)
    }
     


    console.log("imageFile",imageFile)

    
  const resizeFile = (file) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      500,
      500,
      "JPEG",
      100,
      0,
      (uri) => {
        resolve(uri);
      },
      "base64"
    );
  });

   const[createPaymentGateway] =  useMutation(MUTATION_PAYMENT)


    const handleSubmit = async (event) => {
    


      const form = event.currentTarget;
      if (form.checkValidity() === false) {
        event.preventDefault();
        event.stopPropagation();
      }  else {
        event.preventDefault();
        try {
          const uniqueId = uniqid();
          const image = await resizeFile(imageFile);
  
          var byteString = atob(image.split(",")[1]);
          var mimeString = image.split(",")[0].split(":")[1].split(";")[0];
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], { type: mimeString });
          var uniqueFileName = uniqueId + "-" + uniqueId + ".jpeg";
  
          const params = {
            Bucket: BUCKET_NAME,
            Key: uniqueFileName,
            Body: blob,
          };
  
          s3.upload(params, function (err, data) {
            if (err) {
              throw err;
            } else {
                createPaymentGateway({
                    variables:{
                        "paymentInput": {
                            "imageQR": `${uniqueFileName}`,
                            "upiID": `${upiId}`
                          } 
                    }
                })
                setImage("")
                setUpiID("")
              swal({
                title: "Successful",
                text: "Create Profile Successfully!!!",
                icon: "success",
              });
            }
          });
        } catch (err) {
          console.log(err);
        }
      }
  
    };

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


  const[updatePaymentGateway,{loading:uploadLoading}] = useMutation(MUTATION_EDIT_PAYMENT_GATEWAY,{
   refetchQueries:[
    PAYMENT_INTRAGATION,
    "getPayament"
   ]
  })

   async function  handleUpldate (){
      updatePaymentGateway({
        variables:{
          "editpaymentInput": {
            "paymentId": `${getId}`,
            "upiID": `${upiIdEdit}`
          }
        }
    
      
      }).then(()=>{
        handleClose()
      })
    
     
  
 
  }


  return (
    <>
    <AdminPageLayout>
    <Container>
        <h5 style={{textAlign:"center",marginTop:100}}>Payment Gateway</h5>
        <Row>
            <Col md={4}></Col>
            <Col md={4}>

                {
                     laoding ?
                     <Spinner animation="border" role="status">
                     <span className="visually-hidden">Loading...</span>
                   </Spinner>

                     :
                     data && data.getPayament.length === 0 ?

       
                     <Form noValidate validated={validated} onSubmit={handleSubmit}>
                     <Row className="mb-3">
                       <Form.Group as={Col} md="12" controlId="validationCustom01">
                      
                         <Form.Control
                           required
                           type="file"
                           placeholder="First name"
                           onChange={(e)=>setImage(e.target.files[0])}
                         
                         />
                     
                       </Form.Group>
                      
                     
                     </Row>
               
                     <Row>
                     <Form.Group as={Col} md="12" controlId="validationCustom02">
                        
                         <Form.Control
                           required
                           type="text"
                           placeholder="Enter UPI ID"
                           onChange={(e)=>setUpiID(e.target.value)}
                          
                         />
                      
                       </Form.Group>
                     </Row>
                    
                   
                     <Button type="submit" className='mx-auto d-block' style={{marginTop:20}}>Submit form</Button>
                   </Form>
                     :
                     <>
                     <div style={{border:"1px solid blue",width:230,height:75}} className='mx-auto d-block'>
                      <div style={{display:"flex",justifyContent:"end"}}>
                      <FiEdit style={{marginRight:10,marginTop:10}} onClick={handleShow}/>
                      </div>
                  
                      <p style={{textAlign:"center",color:"green",marginTop:10}}>UPI ID : <span style={{fontStyle:"italic"}}>{data && data.getPayament[0].upiID}</span></p>
                     </div>
                     <div style={{marginTop:20}}>
                     </div>
                     </>


                }
             
        

            </Col>
            <Col md={4}></Col>
        </Row>
    


    </Container>
    </AdminPageLayout>
    <Modal show={show} onHide={handleClose}>
      <Container>
        <h5>Change UPI ID</h5>
    <Form style={{marginTop:20}}>
    
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
       
        <Form.Control type="text" placeholder='Enter UPI ID'   value={upiIdEdit} onChange={(e)=>setUpiIDEdit(e.target.value)} />
      </Form.Group>
    </Form>
    {
      uploadLoading?
      <Spinner animation="border" role="status"className='mx-auto d-block' >
      <span className="visually-hidden">Loading...</span>
    </Spinner>
      :
      <Button style={{marginBottom:10}} className='mx-auto d-block' onClick={()=>handleUpldate()}>Change Now</Button>

    }
   
    </Container>
      </Modal>
    
    </>
  )
}
