import React from 'react'
import AdminPageLayout from '../admin/AdmnPageLayout.js'
import { useQuery } from '@apollo/client'
import { Admin_Blocked_Users } from '../user/grqphqloperation/query.js'
import ProfileList from '../admin/partisian/ProfileList.js'
import LoadingBar from '../LoadingBar.jsx'

function BlockedUsers() {
    const { data, loading } = useQuery(Admin_Blocked_Users, {
        pollInterval: 10000,
    })
    return (
        <>
            <LoadingBar isLoading={loading} />
            <AdminPageLayout>
                {
                    data && <ProfileList profiles={data.getBlokedUsers ? data.getBlokedUsers : []} />
                }
            </AdminPageLayout>
        </>
    )
}

export default BlockedUsers