import React, { useEffect, useState } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import "../ComponentsCss/Footer.css";
import { useMutation } from "@apollo/client";
import { MUTATION_CONTACT_US } from "./user/grqphqloperation/mutation";
import swal from "sweetalert";
import { GET_ALL_MESSAGE } from "./user/grqphqloperation/query";
import TermofServices from "./TermofServices";
import PrivacyPolicy from "./PrivacyPolicy";
import CancellationRefundPolicy from "./CancellationRefundPolicy";
import { useNavigate } from "react-router-dom";

export default function Footer1() {
  const [validated, setValidated] = useState(false);
  const [name, setName] = useState("");
  const [contact, setContact] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [year,setYear] = useState(null);
  let navigate = useNavigate();

  useEffect(()=>{
    let date = new Date();
    let year = date.getFullYear();
    setYear(year);
  },[])

  const [createContactUs, { loading: contactLoading }] = useMutation(
    MUTATION_CONTACT_US,
    {
      refetchQueries: [GET_ALL_MESSAGE],
    }
  );

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      createContactUs({
        variables: {
          contactUsInput: {
            contact: `${contact}`,
            email: `${email}`,
            message: `${message}`,
            name: `${name}`,
          },
        },
      });
      swal({
        title: "Thank You For Contacting..",
        text: "We Will Contact You Soon...",
        icon: "success",
      });
      setName("");
      setContact("");
      setEmail("");
      setMessage("");
    }
  };

  const handlePrivacyPolicy = ()=>{
    navigate('/PrivacyPolicy');
  }

  const handleTermOfService = ()=>{
    navigate('/TermofServices');
  }

  const handleRefundPolicey = ()=>{
    navigate('/CancellationRefundPolicy');
  }

  return (
    <>
      <Container
        style={{ marginTop: 40, fontFamily: "DM Sans", padding: 20}}
        fluid
      >
        <Row>
          
          <Col md={3}>
            <div
              style={{
                textAlign: "start",
                borderRight: "2px solid #ccc",
                height: "100%",
                padding: "20px",
              }}
            >
              <h4 style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
                <center>Help & Support</center>
              </h4>
              <ul className="custom-list" style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ color: "black", marginTop: "10px" }}>24x7 Live help</li>
                <li style={{ color: "black", marginTop: "10px" }}>Contact us</li>
                <li style={{ color: "black", marginTop: "10px" }}>Feedback</li>
                <li style={{ color: "black", marginTop: "10px" }}>FAQs</li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                textAlign: "start",
                borderRight: "2px solid #ccc",
                height: "100%",
                padding: "20px",
              }}
            >
              <h4 style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
                <center>Information</center>
              </h4>
              <ul className="custom-list" style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ color: "black", marginTop: "10px" }}>Awards</li>
                <li style={{ color: "black", marginTop: "10px" }}>Milestones</li>
                <li onClick={handleTermOfService} style={{ color: "black", marginTop: "10px" }}>Terms and Services</li>
                <li onClick={handlePrivacyPolicy} style={{ color: "black", marginTop: "10px" }}>Privacy Policy</li>
                <li onClick={handleRefundPolicey} style={{ color: "black", marginTop: "10px" }}>Cancellation & Refund Policy</li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                textAlign: "start",
                borderRight: "2px solid #ccc",
                height: "100%",
                padding: "20px",
              }}
            >
              <h4 style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
                <center>Our Wedding Services</center>
              </h4>
              <ul className="custom-list" style={{ listStyleType: "none", padding: 0 }}>
                <li style={{ color: "black", marginTop: "10px" }}>Advertise with us</li>
                <li style={{ color: "black", marginTop: "10px" }}>Careers</li>
                <li style={{ color: "black", marginTop: "10px" }}>Media Room</li>
              </ul>
            </div>
          </Col>
          <Col md={3}>
            <div
              style={{
                textAlign: "center",
                height: "100%",
              }}
            >
              <h4 style={{ color: "black", fontWeight: "bold", fontSize: 18 }}>
                Contact Us
              </h4>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="mb-3">
                  <Form.Group as={Col} md="12" controlId="validationCustom01">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Full Name"
                      style={{borderRadius:'7px',borderColor:'#ccc'}}
                      className="inputtype mx-auto d-block"
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Control
                      required
                      type="number"
                      placeholder="Contact"
                      style={{borderRadius:'7px',borderColor:'#ccc'}}
                      className="inputtype mx-auto d-block"
                      onChange={(e) => setContact(e.target.value)}
                      value={contact}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Control
                      required
                      type="email"
                      style={{borderRadius:'7px',borderColor:'#ccc'}}
                      placeholder="Email"
                      className="inputtype mx-auto d-block"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} md="12" controlId="validationCustom02">
                    <Form.Control
                      required
                      style={{borderRadius:'7px',borderColor:'#cccc'}}
                      as="textarea"
                      placeholder="Message"
                      className="inputtype mx-auto d-block"
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                    />
                    <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                  </Form.Group>
                </Row>
                <Button type="submit" style={{ borderRadius: "7px" }}>
                  Submit
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row
          style={{ backgroundColor: "#2c3e50", marginTop: 15, height: "100px" }}
        >
          <Col md={6}>
            <p
              style={{
                textAlign: "left",
                color: "#fff",
                fontWeight: "bold",
                marginTop: "30px",
                fontSize: "13px",
              }}
            >
              This website is strictly for matrimonial purpose only and not a
              dating website. Copyright © {year ? year : 'Loading...'}. All rights reserved.
            </p>
          </Col>
          <Col md={6}>
            <p
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
                marginTop: 30,
              }}
            >
              Developed & Maintained by{" "}
              <span
                style={{
                  color: "#ff5a60",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                <a href="https://softseekersinfotech.com/" target="_blank" style={{ textDecoration: "none", color: "#ff5a60" }}> Softseekers Infotech Pvt. Ltd. </a>
              </span>
            </p>
          </Col>
        </Row>
      </Container>
    </>
  );
}
