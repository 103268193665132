import React, { useLayoutEffect } from "react";
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import "../ComponentsCss/About.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function About() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".backabout",
        start: "top bottom-=200px",
        toggleActions: "play none none none",
        // markers: true,
      },
    });

    tl.from(".backabout_row", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      delay: -0.5,
    });

    return () => {
      tl.kill();
    };
  }, []);
  return (
    <Container fluid className="backabout">
      <Container style={{ marginTop: 50 }}>
        <Row className="backabout_row">
          <Col md={3} style={{ marginTop: 50 }}>
            <h1
              style={{
                color: "black",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "Dm Sans",
              }}
            >
              About{" "}
              <span
                style={{
                  color: "#ff5a60",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                {" "}
                Us
              </span>
            </h1>
          </Col>
          <Col md={9} style={{ marginTop: 10 }}>
            <p
              style={{
                color: "black",
                textAlign: "center",
                marginTop: 30,
                fontWeight: "bold",
                fontFamily: "Dm Sans",
              }}
            >
              At Byaahlagan.com, we are more than a matrimonial platform; we are
              architects of enduring love stories. Committed to fostering
              meaningful connections, we understand the significance of finding
              a life partner who shares your values and aspirations. Our mission
              is to provide a trusted space where individuals embark on the
              journey of matrimony with confidence. With a blend of technology
              and human touch, we strive to be the catalyst for your happily
              ever after, guiding you towards a future filled with love,
              companionship, and cherished moments.
                 

            </p>
            <h6 style={{textAlign:"center",color:"#ff5a60"}}>" For any further query please contact: +91-9993575990 "</h6>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
