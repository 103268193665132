import React, { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Outlet, Link } from 'react-router-dom';
import { FaChevronDown ,FaRupeeSign,FaIdCardAlt } from 'react-icons/fa';
import { FaBarsStaggered } from "react-icons/fa6";
import {
    AiOutlineLogout,
    AiOutlineUserAdd,
    AiOutlineHome,
} from 'react-icons/ai';
import { MdOutlineNoteAlt } from 'react-icons/md';
import { BiSolidContact } from 'react-icons/bi';
import { TbDeviceAnalytics } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';

import './css/SuperAdminDashboard.css';
import './css/AdminDashboard.css';

export default function AdminPageLayout({ children }) {

    const navigate = useNavigate();

    useEffect(() => {
        var dropdown = document.querySelectorAll('.dropdown');
        var flag = 1;
        dropdown.forEach((item) => {
            item.addEventListener('click', function () {
                if (flag == 1) {
                    item.classList.add('active');
                    flag = 0;
                } else {
                    item.classList.remove('active');
                    flag = 1;
                }
            });
        });
    }, []);

    useEffect(() => {
        var sidebar = document.querySelector('.sidebar');
        var flag2 = 0;
        const menu = document.querySelector('.bx-menu');
        menu.addEventListener('click', function () {
            if (flag2 == 0) {
                sidebar.classList.add('close');
                flag2 = 1;
            } else {
                sidebar.classList.remove('close');
                flag2 = 0;
            }
        });
    }, []);


    const logoutHandel = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("userId");
        localStorage.removeItem("adminToken");
        localStorage.removeItem("adminId");
        localStorage.removeItem("isAdmin");
        localStorage.removeItem("rzp_device_id");
        localStorage.removeItem("rzp_checkout_anon_id");
        localStorage.removeItem("MERCHANT_STATIC_CONFIG");
        navigate('/');
    }

    return (
        <>
            <div className={'sidebar'}>
                <Link to="/complaint" className="logo-box" style={{ textDecoration: 'none' }}>
                    <div className="logo-name">
                        <h6
                            style={{
                                color: '#fff',
                                fontFamily: 'poppins',
                                fontWeight: 'bold',
                                fontSize: 22,
                                textAlign: 'center',
                                marginTop: 30,
                             
                            }}
                        >
                            Super Admin Dashboard
                        </h6>
                    </div>
                </Link>
                <ul className="sidebar-list m-0">
                    <li className='m-0 p-0'>
                        <div className="title">
                            <Link to="/" className="link" style={{ textDecoration: 'none' }}>
                                <i className="bx bx-grid-alt">
                                    <AiOutlineHome />
                                </i>
                                <span className="name">Home</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/"
                                className="link submenu-title"
                                style={{ textDecoration: 'none' }}
                            >
                                Home
                            </Link>
                        </div>
                    </li>
                 
                    <li className="dropdown">
                        <div className="title">
                            <Link className="link" style={{ textDecoration: 'none' }}>
                                <i className="bx bx-book-alt">
                                    <AiOutlineUserAdd />
                                </i>
                                <span className="name">Users</span>
                            </Link>
                            <i className="bx bxs-chevron-down">
                                <FaChevronDown />
                            </i>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/admin/user/all"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                All Users
                            </Link>
                            <Link
                                to="/admin/user/premium"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                Subscription
                            </Link>
                            <Link
                                to="/admin/user/block"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                Block
                            </Link>
                        </div>
                    </li>

                    <li>
                        <div className="title">
                            <Link
                                to="/admin/contact"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                <i className="bx bx-grid-alt">
                                    <BiSolidContact />
                                </i>
                                <span className="name">Contact requests</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/admin/contact"
                                className="link submenu-title"
                                style={{ textDecoration: 'none' }}
                            >
                                Contact requests
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link
                                to="/admin/payment"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                <i className="bx bx-grid-alt">
                                    <FaRupeeSign />
                                </i>
                                <span className="name">Payment</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/admin/payment"
                                className="link submenu-title"
                                style={{ textDecoration: 'none' }}
                            >
                                Payment
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link
                                to="/admin/adminPlan"
                                className="link"
                                style={{ textDecoration: 'none' }}
                            >
                                <i className="bx bx-grid-alt">
                                    <FaIdCardAlt  />
                                </i>
                                <span className="name">Plan</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/admin/adminPlan"
                                className="link submenu-title"
                                style={{ textDecoration: 'none' }}
                            >
                                Plan
                            </Link>
                        </div>
                    </li>
                    <li>
                        <div className="title">
                            <Link
                                to="/"
                                className="link"
                                style={{ textDecoration: 'none' }}
                                onClick={logoutHandel}
                            >
                                <i className="bx bx-grid-alt">
                                    <AiOutlineLogout />
                                </i>
                                <span className="name">Log out</span>
                            </Link>
                        </div>
                        <div className="submenu">
                            <Link
                                to="/"
                                className="link submenu-title"
                                style={{ textDecoration: 'none' }}
                                onClick={() => logoutHandel()}
                            >
                                Log out
                            </Link>
                        </div>
                    </li>
                </ul>
            </div>
            <section className="home">
                <div className="toggle-sidebar">
                    <FaBarsStaggered
                        className="bx bx-menu"
                        style={{
                            width: 30,
                            height: 30,
                            marginLeft: 10,
                            color: '#FD7D72',
                            zIndex: 9999999999999999
                        }}
                    />
                </div>
                <Container style={{ padding: '20px' }} fluid>
                    <Row>
                        <Col md={12}>
                            {children}
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    );
}
