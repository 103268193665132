import { useMutation, useQuery } from "@apollo/client";
import React, { useState } from "react";
import { Dropdown, Image } from "react-bootstrap";

import Dropzone from "react-dropzone-uploader";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Spinner,
  Modal,
} from "react-bootstrap";
import { CREATE_PROFILE } from "./grqphqloperation/mutation";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import uniqid from "uniqid";
import swal from "sweetalert";
import PosterImg from "../../Images/poster.jpg";

import BuySub from "../BuySub";
import { GET_USER } from "./grqphqloperation/query";
import LoadingBar from "../LoadingBar";
import waitWomen from "../../Images/WaitingWomen.png"
import waitMen from "../../Images/WaitingMen.png"
import successMen from "../../Images/successMen.png"
import successWomen from "../../Images/successWomen.png"

const ID = "AKIA6GB4RFKTDTDA6E2O";
const SECRET = "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";
const BUCKET_NAME = "byaahlagan-profile-image";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});

let imageKey = [];

function MakeProfile() {
  const userId = localStorage.getItem("userId");
  const { loading: userLoading, data: userData } = useQuery(GET_USER, {
    variables: { id: `${userId}` },
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [loginError, setLoginError] = useState(false);
  const [multiImgUploaded, setMultiImageUploaded] = useState(false);
  const [spin, setSpin] = useState(false);

  const [CreateFor, setCreate] = useState();

  const [dob, setDob] = useState();
  const [caste, setCaste] = useState();
  const [profile, setProfile] = useState();
  const [picture, setpicture] = useState();
  const [gender, setGender] = useState();
  const [height, setHeight] = useState();
  const [age, setAge] = useState();
  const [religion, setReligion] = useState();
  const [meritalStatus, setMeritalStatus] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [pincode, setPincode] = useState();
  const [occupation, setOccupation] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [email, setEmail] = useState();
  const [income, setIncome] = useState();
  const [idType, setIdType] = useState();
  const [idNumber, setIdNumber] = useState();
  const [idImage, setIdImage] = useState();

  const [updateUserr, { data, error, loading: createProfileLoading }] =
    useMutation(CREATE_PROFILE, {
      errorPolicy: "all",
      onError(error) {
        setLoginError(true);
      },

      refetchQueries: [GET_USER, "getUsers"],
    });
  const [validated, setValidated] = useState(false);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const [selectedImage, setSelectedImage] = useState();

  const handleFileInput = (e) => {
    setSelectedImage(e.target.files[0]);
  };

  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };
  const handleChangeStatus = ({ meta, file }, status) => { };

  const changeImage = async (files) => {
    setSpin(true);
    try {
      files.map(async (fileData) => {
        const uniqueId = uniqid();
        const file = fileData.file;
        const image = await resizeFile(file);
        var byteString = atob(image.split(",")[1]);
        var mimeString = image.split(",")[0].split(":")[1].split(";")[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([ab], { type: mimeString });
        var uniqueFileName = uniqueId + "-" + uniqueId + ".jpeg";

        imageKey.push(uniqueFileName);
        const params = {
          Bucket: BUCKET_NAME,
          Key: uniqueId + "-" + uniqueId + ".jpeg",
          Body: blob,
        };
        s3.upload(params, function (err, data) {
          if (err) {
            swal({
              title: "Error!",
              text: "Error in uploading images!",
              icon: "error",
            });
            throw err;
          } else {
            setSpin(false);
            handleClose();
            setMultiImageUploaded(true);
          }
        });
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      return;
    }
    if (imageKey.length === 0) {
      swal({
        title: "Error",
        text: "Please choose multiple images !!!",
        icon: "error",
      });
      return;
    }
  
    event.preventDefault();
    try {
      const uniqueId = uniqid();
      const profileImage = await resizeFile(profile);
  
      const uploadToS3 = (file, fileName) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => {
            const arrayBuffer = reader.result;
            const blob = new Blob([arrayBuffer], { type: file.type });
  
            const params = {
              Bucket: BUCKET_NAME,
              Key: fileName,
              Body: blob,
            };
  
            s3.upload(params, function (err, data) {
              if (err) {
                reject(err);
              } else {
                resolve(data);
              }
            });
          };
          reader.readAsArrayBuffer(file);
        });
      };
  
      const profileImageFileName = `${uniqueId}-profile.jpeg`;
      const profileBlob = dataURLtoBlob(profileImage);
      await uploadToS3(profileBlob, profileImageFileName);
  
      const idImageFileName = `${uniqueId}-id.jpeg`;
      await uploadToS3(idImage, idImageFileName);
  
      await updateUserr({
        variables: {
          updateNew: {
            userid: `${userId}`,
            dob: `${dob}`,
            caste: `${caste}`,
            firstName: `${firstName}`,
            lastName: `${lastName}`,
            email: `${email}`,
            profile: `${profileImageFileName}`,
            picture: `${imageKey}`,
            gender: `${gender}`,
            height: `${height}`,
            age: `${age}`,
            religion: `${religion}`,
            meritalStatus: `${meritalStatus}`,
            city: `${city}`,
            state: `${state}`,
            country: `${country}`,
            pincode: `${pincode}`,
            occupation: `${occupation}`,
            income: `${income}`,
            userIdType: `${idType}`,
            userIdNumber: `${idNumber}`,
            userIdPic: `${idImageFileName}`,
            status: 'pending',
          },
        },
      });
  
      setDob("");
      setCaste("");
      setProfile("");
      setpicture("");
      setGender("");
      setHeight("");
      setAge("");
      setReligion("");
      setMeritalStatus("");
      setState("");
      setCity("");
      setCountry("");
      setPincode("");
      setOccupation("");
      setFirstName("");
      setLastName("");
      setEmail("");
      swal({
        title: "Successful",
        text: "Create Profile Successfully!!!",
        icon: "success",
      });
    } catch (err) {
      swal({
        title: "Error",
        text: err.message,
        icon: "error",
      });
    }
    setValidated(true);
  };
  
  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };
  
  


  console.log("userData", userData)

  if (userData && userData.getOneUser.status === "pending") {
    return (
      <>
        {
          userData && userData.getOneUser.gender === "Female" ?
            <Image
              src={waitWomen}
              // src={PosterImg}
              style={{ width: "35%", height: "35%" }}
              className="img-fluid mx-auto d-block"
              alt="banner image"
            />
            :
            <Image
              src={waitMen}
              // src={PosterImg}
              style={{ width: "35%", height: "35%" }}
              className="img-fluid mx-auto d-block"
              alt="banner image"
            />


        }


        <h3
          style={{
            textAlign: "center",
            fontFamily: "DM sans",
            fontWeight: "600",
            color: "#EC4C5A",
          }}
        >
          Wait your are profile pending
        </h3>
      </>
    );
  } else if (userData && userData.getOneUser.status === "verify") {
    return (
      <>
        {

          userData && userData.getOneUser.gender === "Female" ?
            <Image
              src={successWomen}
              // src={PosterImg}
              style={{ width: "35%", height: "35%" }}
              className="img-fluid mx-auto d-block"
              alt="banner image"
            />
            :

            <Image
              src={successMen}
              // src={PosterImg}
              style={{ width: "35%", height: "35%" }}
              className="img-fluid mx-auto d-block"
              alt="banner image"
            />
        }


        <h3
          style={{
            textAlign: "center",
            fontFamily: "DM sans",
            fontWeight: "600",
            color: "#EC4C5A",
          }}
        >
          Your Profile Is Live
        </h3>
      </>
    );



  }

  return (
    <div>
      <LoadingBar isLoading={createProfileLoading} />
      <Container>
        <Row style={{ marginTop: "20px" }}>
          <Col></Col>
          <Col xs={12}>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label style={{ fontSize: "17px", fontFamily: "Dm Sans", fontWeight: "bold" }}>
                    First Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Enter First Name"
                    onChange={(e) => setFirstName(e.target.value)}
                    value={firstName}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Last Name
                  </Form.Label>
                  <Form.Control
                    required
                    type="text"
                    name="name"
                    placeholder="Enter Last Name"
                    onChange={(e) => setLastName(e.target.value)}
                    value={lastName}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Email
                  </Form.Label>
                  <Form.Control
                    required
                    type="email"
                    name="email"
                    placeholder="Enter Your Email"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Date of Birth
                  </Form.Label>
                  <Form.Control
                    required
                    type="date"
                    name="dob"
                    placeholder="DD/MM/YYYY"
                    onChange={(e) => setDob(e.target.value)}
                    value={dob}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom01">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Religion
                  </Form.Label>
                  <Form.Select
                    required
                    name="religion"
                    onChange={(e) => setReligion(e.target.value)}
                    value={religion}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose Religion{" "}
                    </option>
                    <option value="Adivasi" style={{ fontWeight: 'bold', color: "#34495e" }}>Adivasi</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="validationCustom02">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Caste/Surname
                  </Form.Label>
                  <Form.Select
                    required
                    onChange={(e) => setCaste(e.target.value)}
                    value={caste}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose Caste
                    </option>
                    <optgroup style={{ color: '#2980b9' }} label="Korku">
                      <option value="Mowasi" style={{ fontWeight: 'bold', color: "#34495e" }}>Mowasi</option>
                      <option value="Sheeloo" style={{ fontWeight: 'bold', color: "#34495e" }}>Sheeloo</option>
                      <option value="Rajdarshama" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajdarshama</option>
                      <option value="Rabhopa" style={{ fontWeight: 'bold', color: "#34495e" }}>Rabhopa</option>
                      <option value="Rajbosam" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajbosam</option>
                      <option value="Mausee" style={{ fontWeight: 'bold', color: "#34495e" }}>Mausee</option>
                      <option value="Lobo" style={{ fontWeight: 'bold', color: "#34495e" }}>Lobo</option>
                      <option value="Rajbaithe" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajbaithe</option>
                      <option value="Rajnikhame" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajnikhame</option>
                      <option value="Changree" style={{ fontWeight: 'bold', color: "#34495e" }}>Changree</option>
                      <option value="Atkom" style={{ fontWeight: 'bold', color: "#34495e" }}>Atkom</option>
                      <option value="Sakom" style={{ fontWeight: 'bold', color: "#34495e" }}>Sakom</option>
                      <option value="Kayada" style={{ fontWeight: 'bold', color: "#34495e" }}>Kayada</option>
                      <option value="Akhadee" style={{ fontWeight: 'bold', color: "#34495e" }}>Akhadee</option>
                      <option value="Thakhair" style={{ fontWeight: 'bold', color: "#34495e" }}>Thakhair</option>
                    </optgroup>
                    <optgroup style={{ color: '#2980b9' }} label="Gond">
                      <option value="Uikey" style={{ fontWeight: 'bold', color: "#34495e" }}>Uikey</option>
                      <option value="Dhurvey" style={{ fontWeight: 'bold', color: "#34495e" }}>Dhurvey</option>
                      <option value="Batti" style={{ fontWeight: 'bold', color: "#34495e" }}>Batti</option>
                      <option value="Bhalavi" style={{ fontWeight: 'bold', color: "#34495e" }}>Bhalavi</option>
                      <option value="Barkade" style={{ fontWeight: 'bold', color: "#34495e" }}>Barkade</option>
                      <option value="Katlam" style={{ fontWeight: 'bold', color: "#34495e" }}>Katlam</option>
                      <option value="Tekam" style={{ fontWeight: 'bold', color: "#34495e" }}>Tekam</option>
                      <option value="Marpachi" style={{ fontWeight: 'bold', color: "#34495e" }}>Marpachi</option>
                      <option value="Karpayti" style={{ fontWeight: 'bold', color: "#34495e" }}>Karpayti</option>
                      <option value="Kulrate" style={{ fontWeight: 'bold', color: "#34495e" }}>Kulrate</option>
                      <option value="Marskota" style={{ fontWeight: 'bold', color: "#34495e" }}>Marskota</option>
                      <option value="Meshram" style={{ fontWeight: 'bold', color: "#34495e" }}>Meshram</option>
                      <option value="Urpayti" style={{ fontWeight: 'bold', color: "#34495e" }}>Urpayti</option>
                      <option value="Kumare" style={{ fontWeight: 'bold', color: "#34495e" }}>Kumare</option>
                      <option value="Parteli" style={{ fontWeight: 'bold', color: "#34495e" }}>Parteli</option>
                      <option value="Ivnati" style={{ fontWeight: 'bold', color: "#34495e" }}>Ivnati</option>
                    </optgroup>
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Gender
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default select example"
                    onChange={(e) => setGender(e.target.value)}
                    value={gender}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled="disabled">
                      Choose Gender
                    </option>
                    <option value="Male" style={{ fontWeight: 'bold', color: "#34495e" }}>Male</option>
                    <option value="Female" style={{ fontWeight: 'bold', color: "#34495e" }}>Female</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="validationCustom01">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Choose Profile Image
                  </Form.Label>

                  <Form.Control
                    required
                    type="file"
                    onChange={(e) => setProfile(e.target.files[0])}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="validationCustom02">
                  {multiImgUploaded ? (
                    <h1
                      style={{
                        fontSize: "17px",
                        fontFamily: "DM Sans",
                        fontWeight: "bold",
                        color: "green",
                      }}
                    >
                      Images uploaded Successfully!!!
                    </h1>
                  ) : (
                    <>
                      <Form.Label>Choose Multiple Images</Form.Label>
                      <Button
                        variant="outline-secondary"
                        onClick={handleShow}
                        style={{
                          fontSize: "15px",
                          fontFamily: "Dm Sans",
                          fontWeight: "bold",
                        }}
                      >
                        Choose Multiple Images
                      </Button>
                    </>
                  )}
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Annual Income
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default select annual income"
                    onChange={(e) => setIncome(e.target.value)}
                    value={income}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled="disabled">
                      Choose Annual Income
                    </option>
                    <option value="0-1 Lakh" style={{ fontWeight: 'bold', color: "#34495e" }}>0-1 Lakh</option>
                    <option value="1-2 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>1-2 Lakhs</option>
                    <option value="2-3 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>2-3 Lakhs</option>
                    <option value="3-4 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>3-4 Lakhs</option>
                    <option value="4-5 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>4-5 Lakhs</option>
                    <option value="5-7.5 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>5-7.5 Lakhs</option>
                    <option value="7.5-10 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>7.5-10 Lakhs</option>
                    <option value="10-15 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>10-15 Lakhs</option>
                    <option value="15-20 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>15-20 Lakhs</option>
                    <option value="20-25 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>20-25 Lakhs</option>
                    <option value="25-35 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>25-30 Lakhs</option>
                    <option value="35-50 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>35-50 Lakhs</option>
                    <option value="50-70 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>50-70 Lakhs</option>
                    <option value="70 Lakh-1 Crore" style={{ fontWeight: 'bold', color: "#34495e" }}>70 Lakh-1 Crore</option>
                    <option value="Above 1 Crore" style={{ fontWeight: 'bold', color: "#34495e" }}>Above 1 Crore</option>
                  </Form.Select>
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group
                  as={Col}
                  md="3"
                  name="Age"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Height
                  </Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="In CM"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => setHeight(e.target.value)}
                    value={height}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 0In">4ft 0In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 1In">4ft 1In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 2In">4ft 2In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 3In">4ft 3In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 4In">4ft 4In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 5In">4ft 5In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 6In">4ft 6In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 7In">4ft 7In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 8In">4ft 8In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 9In">4ft 9In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 10In">4ft 10In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 11In">4ft 11In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 0In">5ft 0In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 1In">5ft 1In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 2In">5ft 2In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 3In">5ft 3In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 4In">5ft 4In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 5In">5ft 5In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 6In">5ft 6In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 7In">5ft 7In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 8In">5ft 8In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 9In">5ft 9In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 10In">5ft 10In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 11In">5ft 11In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 0In">6ft 0In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 1In">6ft 1In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 2In">6ft 2In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 3In">6ft 3In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 4In">6ft 4In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 5In">6ft 5In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 6In">6ft 6In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 7In">6ft 7In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 8In">6ft 8In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 9In">6ft 9In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 10In">6ft 10In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 11In">6ft 11In</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="7ft">7ft</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group
                  as={Col}
                  md="3"
                  name="Age"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Age
                  </Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="Enter Age"
                    aria-describedby="inputGroupPrepend"
                    required
                    onChange={(e) => setAge(e.target.value)}
                    value={age}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="18">18</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="19">19</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="20">20</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="21">21</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="22">22</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="23">23</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="24">24</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="25">25</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="26">26</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="27">27</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="28">28</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="29">29</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="30">30</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="31">31</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="32">32</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="33">33</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="34">34</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="35">35</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="36">36</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="37">37</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="38">38</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="39">39</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="40">40</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="41">41</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="42">42</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="43">43</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="44">44</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="45">45</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="46">46</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="47">47</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="48">48</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="49">49</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="50">50</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="51">51</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="52">52</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="53">53</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="54">54</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="55">55</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="56">56</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="57">57</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="58">58</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="59">59</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="60">60</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="61">61</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="62">62</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="63">63</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="64">64</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="65">65</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="66">66</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="67">67</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="68">68</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="69">69</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="70">70</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Your Marital Status
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default select example"
                    onChange={(e) => setMeritalStatus(e.target.value)}
                    value={meritalStatus}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Never Merried">Never Married</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Awaiting Dovicer">Awaiting Dovicer</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Dovicer">Dovicer</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Widowed">Widowed</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Annulled">Annulled</option>
                  </Form.Select>
                </Form.Group>

                <Form.Group as={Col} md="3" controlId="validationCustom03">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    City
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="City"
                    anme="City"
                    required
                    onChange={(e) => setCity(e.target.value)}
                    value={city}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>
              </Row>

              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="validationCustom04">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    State
                  </Form.Label>
                  <Form.Select
                    type="text"
                    placeholder="State"
                    required
                    onChange={(e) => setState(e.target.value)}
                    value={state}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Andhra Pradesh">Andhra Pradesh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Arunachal Pradesh">Arunachal Pradesh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Assam">Assam</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Bihar">Bihar</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Chandigarh">Chandigarh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Chhattisgarh">Chhattisgarh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Daman and Diu">Daman and Diu</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Delhi">Delhi</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Goa">Goa</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Gujarat">Gujarat</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Haryana">Haryana</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Himachal Pradesh">Himachal Pradesh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Jammu and Kashmir">Jammu and Kashmir</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Jharkhand">Jharkhand</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Karnataka">Karnataka</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Kerala">Kerala</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Ladakh">Ladakh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Lakshadweep">Lakshadweep</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Madhya Pradesh">Madhya Pradesh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Maharashtra">Maharashtra</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Manipur">Manipur</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Meghalaya">Meghalaya</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Mizoram">Mizoram</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Nagaland">Nagaland</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Odisha">Odisha</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Puducherry">Puducherry</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Punjab">Punjab</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Rajasthan">Rajasthan</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Sikkim">Sikkim</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Tamil Nadu">Tamil Nadu</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="elangana">Telangana</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Tripura">Tripura</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Uttar Pradesh">Uttar Pradesh</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Uttarakhand">Uttarakhand</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="West Bengal">West Bengal</option>
                  </Form.Select>
                </Form.Group>
                <Form.Group as={Col} md="3" controlId="validationCustom05">
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Pincode
                  </Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Enter Here"
                    required
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                    onChange={(e) => setPincode(e.target.value)}
                    value={pincode}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Country
                  </Form.Label>

                  <Form.Control
                    required
                    type="text"
                    placeholder="Enter Here"
                    onChange={(e) => setCountry(e.target.value)}
                    value={country}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  />
                </Form.Group>

                <Form.Group
                  as={Col}
                  md="3"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Occupation
                  </Form.Label>

                  <Form.Select
                    required
                    type="text"
                    placeholder="Enter Here"
                    onChange={(e) => setOccupation(e.target.value)}
                    value={occupation}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Private Company">Private Company</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Goverment / Public Sector">Goverment / Public Sector</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Defence / Civil Services">Defence / Civil Services</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Business / Self Employed">Business / Self Employed</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Farmer">Farmer</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Not Working">Not Working</option>
                  </Form.Select>
                </Form.Group>

              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="3" controlId="validationCustom06">
                  <Form.Label style={{ fontSize: "17px", fontFamily: "Dm Sans", fontWeight: "bold" }}> Id Type</Form.Label>
                  <Form.Select
                    required
                    type="text"
                    placeholder="Enter Here"
                    onChange={(e) => setIdType(e.target.value)}
                    value={idType}
                    style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled>
                      Choose
                    </option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Aadhaar">Aadhaar</option>
                    <option style={{ fontWeight: 'bold', color: "#34495e" }} value="PAN">PAN</option>

                  </Form.Select>
                </Form.Group>
                {
                  idType ?
                    <>
                      <Form.Group as={Col} md="3" controlId="validationCustom07">
                        <Form.Label style={{ fontSize: "17px", fontFamily: "Dm Sans", fontWeight: "bold" }}> {idType} Number</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Enter Here"
                          required
                          onChange={(e) => setIdNumber(e.target.value)}
                          value={idNumber}
                          style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                        />
                      </Form.Group>
                      <Form.Group as={Col} md="3" controlId="validationCustom08">
                        <Form.Label style={{ fontSize: "17px", fontFamily: "Dm Sans", fontWeight: "bold" }}> {idType} Image</Form.Label>
                        <Form.Control
                          type="file"
                          required
                          onChange={(e) => setIdImage(e.target.files[0])}
                          style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                        />
                      </Form.Group>
                    </>
                    : ""}
              </Row>
              <Form.Group className="mb-3">
                <Form.Check
                  required
                  label="Agree to terms and conditions"
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                  style={{ fontSize: "17px", fontFamily: "Dm Sans" }}
                />
              </Form.Group>
              {createProfileLoading ? (
                <Button variant="primary" disabled>
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Loading...
                </Button>
              ) : (
                <Button
                  type="submit"
                  variant="outline-primary"
                  style={{
                    fontSize: "17px",
                    fontFamily: "Dm Sans",
                    marginBottom: "20px",
                  }}
                  className="mx-auto d-block"
                >
                  Submit form
                </Button>
              )}
            </Form>
          </Col>

          <Col></Col>
        </Row>
      </Container>
      {/*   model for multiple imge */}
      <Modal show={show} onHide={handleClose} style={{ marginTop: '15vh' }}>
        <Modal.Header closeButton>
          <Modal.Title>Choose Image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {spin ? (
            <Spinner animation="border" role="status" className="mx-auto d-block">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) : (
            <Dropzone
              getUploadParams={getUploadParams}
              getChangeStatus={handleChangeStatus}
              onSubmit={changeImage}
              accept="image/*"
              styles={{ dropzone: { minHeight: 200, maxHeight: 250 } }}
              inputContent="Drop or Change Images"
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default MakeProfile;
