import React, { useEffect, useState } from "react";
import Navigation from "./Navigation";
import Hero from "./Hero";
import FIndsomeone from "./FIndsomeone";
import Knoweachother from "./Knoweachother";
import Stories from "./Stories";
import MatrimonySite from "./MatrimonySite";
import About from "./About";
import Footer1 from "./Footer1";
import LoadingBar from "./LoadingBar";

export default function Home() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(false);
  }, [])
  return (
    <>
      <LoadingBar isLoading={loading} />
      <Navigation />
      <Hero />
      <FIndsomeone />
      <Knoweachother />
      <Stories />
      <MatrimonySite />
      <About />
      <Footer1 />
    </>
  );
}
