import React from "react";
import {
  Nav,
  Tab,
  Row,
  Col,
  Container,
  Card,
  Image,
  Button,
} from "react-bootstrap";
import "../UserCss/SelectProfile.css";
import { useNavigate, useParams } from "react-router-dom";
import golu from "../../Images/golu.jpg";
import { useQuery } from "@apollo/client";
import { CONNECTION_FROM_PROFILE, GET_FRIEND_BY_ID_USERID, GET_USER } from "./grqphqloperation/query";



export default function SelectedProfile() {
  const userId = localStorage.getItem("userId");


  const navigate = useNavigate()
  const { data: connectionDataFrom } = useQuery(CONNECTION_FROM_PROFILE, {
    variables: {
      userId: `${userId}`,
    }
  })

  function handleClickView(id) {
    navigate("/userProfile/"+ id);
  }


  console.log("connectionDataFrom",connectionDataFrom)

  const{data,loading} = useQuery(GET_FRIEND_BY_ID_USERID,{
    variables:{
      "userId": `${userId}`
    }
  })

  console.log("data use",data)

  const { loading:loadingUser, data:DataUser, error } = useQuery(GET_USER, {
    variables: {
        "id": `${userId}`
    }

})


console.log("dataUser",DataUser)
   


  
  return (
    <div>
      <Container>
        <Row style={{ marginTop: 20 }}>
          <Container>
            <h3 style={{color:'#EC4C59'}}>Connection Profile </h3>
            <hr />
            {
              DataUser && DataUser.getOneUser.friends.map(fdata => {
                return (
                  <Card style={{ marginBottom: 30 }} className="ProfilesCard">
                    <Card.Body>
                      <Row>
                        <Col md={3} style={{ marginTop: "10px" }}>
                          <Row>
                            <Col md={7}>
                              <p style={{ marginTop: 12, fontSize: "15px", fontFamily: "Dm sans",color:"red",fontWeight:"800",letterSpacing:0.5 }}>{fdata.firstName} {fdata.lastName}</p>
                            </Col>
                            <Col
                              md={3}
                              style={{ marginTop: 12, textAlign: "center" }}
                            >
                              <p style={{ fontSize: "15px", fontFamily: "Dm sans" }}></p>

                            </Col>
                          </Row>
                        </Col>
                        <Col md={3} style={{ marginTop: 20 }}>
                          <p style={{ fontSize: "15px", fontFamily: "Dm sans",color:"#000" }}>{fdata.age}</p>
                        </Col>
                        <Col md={3} style={{ marginTop: 20 }}>
                          <p style={{ fontSize: "15px", fontFamily: "Dm sans",color:"#000" }}>{fdata.mobile}</p>
                        </Col>
                        <Col md={3} style={{ marginTop: 12, fontSize: "12px", fontFamily: "Dm sans" }}>
                          <Button className="btn-1 btn2" onClick={() => handleClickView(fdata.id)} >View</Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              })
            }

          </Container>
        </Row>
      </Container>
    </div>
  );
}
