import React, { useEffect, useState } from 'react';
import './1.css'; // Make sure to have the CSS file imported
import { useNavigate } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { GET_USER, USER_NOTIFICATION } from './user/grqphqloperation/query';
import LoadingBar from './LoadingBar';
import swal from 'sweetalert';
import { MUTATION_EMAIL, USER_CONNECTION } from './user/grqphqloperation/mutation';
import logo from "../Images/logo.png"

function ProfileCard(user) {
  let { userA } = user;
  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");
  let navigate = useNavigate();
  const handleProfileClick = (profile) => {
    if (userId && token) {
      navigate(`/user/${profile}`);
    } else {
      navigate('/userLogin');
    }
  };

  console.log("user",user)



  const { data: userData, loading, error } = useQuery(GET_USER, {
    variables: {
      "id": `${userId}`
    }
  });

  const [connUser, setConnUser] = useState('');
  const { data: newData } = useQuery(GET_USER, {
    variables: {
      id: connUser
    }
  });

  const [createConnection] = useMutation(USER_CONNECTION, {
    refetchQueries: [
      USER_NOTIFICATION,
      'getConnectionFor'
    ]
  })

   const[createMail] = useMutation(MUTATION_EMAIL)

  useEffect(() => {
    if (newData && userData) {
      for(let i=1;i<=5;i++){
        if(i===3){
          createConnectionA(newData.getOneUser,userData.getOneUser);
        }
      }
    }
  }, [newData])

  const createConnectionA = (user,logUser) => {
    createMail({
      variables:{
        "email": `${user && user.email}`,
        "subject": "Byaahlagan Connection",
        "text": "Byaahlagan Connection",
        "userName": `${logUser.firstName}`,
        "img": `${`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${logUser.profile}`}`,
        "logo": `${logo}`

      }
    })
    createConnection({
      variables: {
        userConnection: {
          connectionFor: `${user.id}`,
          connectionForFname: `${user && user.firstName}`,
          connectionForLname: `${user && user.lastName}`,
          connectionForAge: `${user && user.age}`,
          connectionForCaste: `${user && user.caste}`,
          connectionForContact: `${user && user.mobile}`,
          connectionFrom: `${logUser.id}`,
          connectionFromFname: `${logUser.firstName}`,
          connectionFromLname: `${logUser.lastName}`,
          connectionFromCaste: `${logUser.caste}`,
          connectionFromAge: `${logUser.age}`,
          connectionFromContact: `${logUser.mobile}`,
        }

      }, refetchQueries: [
        USER_NOTIFICATION,
        'getConnectionFor'
      ]
    })
    

      swal({
        title: "Successful",
        text: "Connection Successfully!!!",
        icon: "success",
      });
  

  
  };

  const handleConnectUser = (userId) => {
    if (userId && token) {
      let { getOneUser } = userData;
      if (getOneUser) {
        if (getOneUser.subscriptionPlanStatus === 'active') {
          setConnUser(userId);
        } else {
          navigate('/Subcriptionplan');
        }
      }
    } else {
      navigate('/userLogin');
    }
  };

  return (
    <>
      <LoadingBar isLoading={loading} />

      <div className="card-profile">
        <div className="img">
          <img src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${userA && userA.profile}`} alt="Profile" />
        </div>
        <div className="infos ms-5 ms-md-3 ">
          <div className="name">
            <h2>{userA && userA.firstName ? userA.firstName : null} {userA && userA.lastName ? userA.lastName : null}</h2>
            <h4>@{userA && userA.id ? userA.id : null}</h4>
          </div>
          <ul className="stats">
            <li>
              <h4>Age : {userA && userA.age ? userA.age : null}</h4>
              <h4>Height : {userA && userA.height ? userA.height : null} </h4>
            </li>

            <li>
              <h4>Cast : {userA && userA.caste ? userA.caste : null} </h4>
              <h4>Occupation : {userA && userA.occupation ? userA.occupation : null} </h4>
            </li>

          </ul>
          {/* <p className="text">
            <b>Address : </b>{userA && userA.city ? userA.city : null} &nbsp; {userA && userA.state ? userA.state : null} &nbsp; {userA && userA.country ? userA.country : null}
          </p> */}
          <div className="links">
            {userA && userA.isConnected == 'true' ? <button className="follow" >Connected</button> : <button className="follow" onClick={() => handleConnectUser(userA.id)}>Connect</button>}
            <button className="view ms-2" onClick={() => handleProfileClick(userA.id)}>Detail</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfileCard;