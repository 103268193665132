/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Form, Spinner } from "react-bootstrap";
import Navigation from "./Navigation";
import "../ComponentsCss/AllMatchingProfile.css";
import { FiUserPlus } from "react-icons/fi";
import { IoMdAddCircle } from "react-icons/io";
import { GoDotFill } from "react-icons/go";
import { FiChevronUp, FiChevronDown } from "react-icons/fi";
import { FaCheckCircle } from "react-icons/fa";
import { GET_SINGLE_USER_ALL_CONNECTIONS, GET_USER, GET_USER_INFO, USER_NOTIFICATION ,GET_FRIEND_REQUEST_BY_ID_USERID} from "./user/grqphqloperation/query";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from '@apollo/client';
import { MUTATION_EMAIL, SEND_REQUEST, USER_CONNECTION } from "./user/grqphqloperation/mutation";
import logo from "../Images/logo.png"
import swal from 'sweetalert';
import LoadingBar from "./LoadingBar";
import { IoMdMale, IoMdFemale } from "react-icons/io";

export default function AllMatChingProfile() {

  let navigate = useNavigate();
  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");

  const [foundValue, setFoundValue] = useState();
  const [localLoading, setLocalLoading] = useState(true);
  const [age, setAge] = useState('')
  const [height, setHeight] = useState('')
  const [job, setJob] = useState('')
  const [dropDown, setDropDown] = useState(false)
  const [connUser, setConnUser] = useState('');
  const [getId,setGetId] =useState('')

  const { data, loading, refetch } = useQuery(GET_USER_INFO, {
    pollInterval: 300
  });


  const { data: userData } = useQuery(GET_USER, {
    variables: {
      "id": `${userId}`
    }
  });


  const { data: newData } = useQuery(GET_USER, {
    variables: {
      id: connUser
    }
  });

  const{dataFriend,loading:loadingFRU} =useQuery(GET_FRIEND_REQUEST_BY_ID_USERID,{
    variables:{
      "userId": `${userId}`
    }
  })


  console.log('data',dataFriend)

  const [createConnection, { loading: createConnectioLoading }] = useMutation(USER_CONNECTION, {
    refetchQueries: [
      USER_NOTIFICATION,
      GET_SINGLE_USER_ALL_CONNECTIONS,
      GET_USER,
      GET_USER_INFO
    ]
  })

  const [createMail, { loading: createEmailLoading }] = useMutation(MUTATION_EMAIL, {
    refetchQueries: [
      USER_NOTIFICATION,
      GET_SINGLE_USER_ALL_CONNECTIONS,
      GET_USER,
      GET_USER_INFO
    ]
  })

  const { data: connectionData, loading: connectionLoading } = useQuery(GET_SINGLE_USER_ALL_CONNECTIONS, {
    variables: {
      "connId": `${userId}`
    }
  })


  useEffect(() => {
    setInterval(() => {
      refetch()
    }, 1000);
  }, [newData, connectionData]);

  const handleProfileClick = (profile) => {
    if (userId && token) {
      navigate(`/userProfile/${profile}`);
    } else {
      navigate('/userLogin');
    }
  };

  useEffect(() => {
    if (data) {
      setInterval(() => {
        setFoundValue(data.getUsers);
      }, 1000);
    }
  }, [data, newData, connectionData]);

  useEffect(() => {
    setLocalLoading(false);
  }, [])

  const filterByAge = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = data.getUsers.filter((data) => {
        return data.age.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getUsers);
    }
    setAge(keyword);
  };

  const filterByheight = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = data.getUsers.filter((data) => {
        return data.height.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getUsers);
    }
    setHeight(keyword);
  };

  const filterByjob = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = data.getUsers.filter((data) => {
        return data.occupation.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(data.getUsers);
    }
    setJob(keyword);
  };



  useEffect(() => {
    if (newData && userData) {
      for (let i = 1; i <= 5; i++) {
        if (i === 3) {
          createConnectionA(newData.getOneUser, userData.getOneUser);
        }
      }
    }
  }, [newData])

  const createConnectionA = (user, logUser) => {
    createMail({
      variables: {
        "email": `${user && user.email}`,
        "subject": "Byaahlagan Connection",
        "text": "Byaahlagan Connection",
        "userName": `${logUser.firstName}`,
        "img": `${`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${logUser.profile}`}`,
        "logo": `${logo}`

      }
    }).then(() => {
      createConnection({
        variables: {
          userConnection: {
            connectionFor: `${user.id}`,
            connectionForFname: `${user && user.firstName}`,
            connectionForLname: `${user && user.lastName}`,
            connectionForAge: `${user && user.age}`,
            connectionForCaste: `${user && user.caste}`,
            connectionForContact: `${user && user.mobile}`,
            connectionFrom: `${logUser.id}`,
            connectionFromFname: `${logUser.firstName}`,
            connectionFromLname: `${logUser.lastName}`,
            connectionFromCaste: `${logUser.caste}`,
            connectionFromAge: `${logUser.age}`,
            connectionFromContact: `${logUser.mobile}`,
          }

        },
      }).then(() => {
        swal({
          title: "Successful",
          text: "Connected Successfully!!!",
          icon: "success",
        });
        window.reload()
      })
    })
  };

  const[sendFriendRequest,{loading:loadingAus}] =  useMutation(SEND_REQUEST)

  const handleConnectUser = (recipientId) => {
    setGetId(recipientId)

    if (userId && token){
      let { getOneUser } = userData;
      if (getOneUser) {
        if (getOneUser.subscriptionPlanStatus === 'active') {
          setConnUser(userId);
          sendFriendRequest({
            variables:{
              "requesterId": `${userId}`,
              "recipientId": `${recipientId}`
            }
          }).then(() => {
            swal({
              title: "Successful",
              text: "Connected Successfully!!!",
              icon: "success",
            });
            window.reload()
          })
        } else {
          navigate('/Subcriptionplan');
        }
      }
    } else {
      navigate('/userLogin');
    }
  };

  function Capitalize(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  console.log("data", data);


console.log("userData",userData)


  return (
    <>
      <LoadingBar isLoading={loading || localLoading || loadingFRU} />
      <Navigation />
      <br></br> <br></br> <br></br>
      <br></br>
      <br></br>
   
      <Container>
        <Row>
          <Col md={3}>
            <Card style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card style={{ width: "95%", marginTop: 9, display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <p style={{ marginLeft: 10, marginTop: 10, }}>Filter</p>
                    {
                      dropDown === true ?
                        <FiChevronUp size={25} style={{ marginRight: 15, marginTop: 12, color: "#000" }} onClick={() => setDropDown(!dropDown)} />
                        :
                        <FiChevronDown size={25} style={{ marginRight: 15, marginTop: 12, color: "#000" }} onClick={() => setDropDown(!dropDown)} />
                    }

                  </div>
                </Card>

              </div>

              {
                dropDown === true ?
                  <>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Card style={{ width: "95%", marginTop: 9 }}>
                        <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
                          Age
                        </p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>

                          <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={age}
                            onChange={filterByAge}

                            style={{ fontSize: 12, marginBottom: 10, width: "90% " }}
                          />
                        </div>
                      </Card>
                    </div>

                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Card style={{ width: "95%", marginTop: 9 }}>
                        <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
                          Height
                        </p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>

                          <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={height}
                            onChange={filterByheight}
                            style={{ fontSize: 12, marginBottom: 10, width: "90% " }}
                          />
                        </div>
                      </Card>
                    </div>
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                      <Card style={{ width: "95%", marginTop: 9 }}>
                        <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
                          Occupation
                        </p>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%" }}>
                          <Form.Control
                            aria-label="Default"
                            aria-describedby="inputGroup-sizing-default"
                            value={job}
                            onChange={filterByjob}
                            style={{ fontSize: 12, marginBottom: 10, width: "90% " }}
                          />
                        </div>
                      </Card>
                    </div>
                  </>
                  :
                  <></>
              }

            </Card>
          </Col>
          <Col md={9}>
            <div className="window-screen">
              {
                foundValue && foundValue.slice().reverse().map((item) => {
                  return (
                    <>
                      <div className="match-card" >
                        <div className="match-card-row">
                          <div className="match-card-col-1">
                            <img src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.profile}`} className="img-match" />
                            {item.subscriptionPlanName === null ? (
                              <></>
                            ) : (
                              <span className="stick-match" data-before-content={`${item.subscriptionPlanName}`}></span>
                            )}
                          </div>
                          <div className="match-card-col-2">
                            <div className="match-des">
                              <h5 style={{ marginTop: 20 }}>{Capitalize(item.firstName)} {Capitalize(item.lastName)}
                                <span>
                                  {
                                    item.gender === "Male" ?
                                      (
                                        <IoMdMale style={{ marginTop: '-4px', color: '#0984e3' }} />
                                      )
                                      :
                                      (
                                        <IoMdFemale style={{ marginTop: '-4px', color: '#e84393' }} />
                                      )
                                  }
                                </span>
                              </h5>
                              <Row>
                                <Col md={3}>
                                  <GoDotFill className="Blink" style={{ color: '#2ecc71' }} /><span style={{ fontSize: 12 }}> Online Now</span>
                                </Col>
                              </Row>
                              <hr></hr>
                              <Row style={{height:30,overflow:"hidden"}}>
                                <Col>
                                  <p style={{ fontSize: '15px', color: '#192a56' }}>
                                    {item.age} yrs, {item.height}
                                  </p>
                                 
                              
                                </Col>
                                <Col  >
                                  {item.meritalStatus === null ? (
                                    <p style={{ fontSize: '15px', color: '#192a56' }}>Never Married</p>
                                  ) : (
                                    <p style={{ fontSize: '15px', color: '#192a56' }}>{item.meritalStatus}</p>
                                  )}
                                
                                 
                                </Col>
                              </Row>
                              <Row  style={{height:30,overflow:"hidden",}}>
                                <Col>
                                <p style={{ fontSize: '15px', color: '#192a56' }}>
                                    {item.religion} , {item.caste}
                                  </p>
                                </Col>
                                <Col><p style={{ fontSize: '15px', color: '#192a56', }}>{item.city} ,{item.state}</p></Col>
                              </Row>
                              <Row style={{height:30,overflow:"hidden",}} >
                                <Col>    <p style={{ fontSize: '15px', color: '#192a56' }}>Hindi</p></Col>

                                <Col> <p style={{ fontSize: '15px', color: '#192a56' }}>{item.occupation}</p></Col>
                              </Row>
                            </div>
                          </div>
                          <div className="match-card-col-3">
                            <h6 style={{ fontStyle: "italic", fontSize: 15, fontWeight: "500", textAlign: "center", marginTop: 50 }}>
                              Like this Profile ?
                            </h6>
                            {
                               userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ? (
                                <div className="match-card-connection">
                                  <FaCheckCircle style={{ fontSize: 60, color: '#2ecc71' }} />

                                </div>
                              ) :

                                createConnectioLoading ?
                                  <Spinner animation="border" role="status" className="mx-auto d-block">
                                    <span className="visually-hidden">Loading...</span>
                                  </Spinner>
                                  :
                                  createEmailLoading ?
                                    <Spinner animation="border" role="status" className="mx-auto d-block">
                                      <span className="visually-hidden">Loading...</span>
                                    </Spinner>
                                    :
                
                                      userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?
                                    <div className="match-card-connection">
                                      <IoMdAddCircle style={{ fontSize: 70, color: '#e74c3c', cursor: "pointer" }} />
                                    </div>
                                      :
                                    
                                    <div className="match-card-connection" onClick={() => handleConnectUser(item.id)}>
                                      <IoMdAddCircle style={{ fontSize: 70, color: '#e74c3c', cursor: "pointer" }} />
                                    </div>
                            }
                            <h6 style={{ textAlign: "center" }}>Connect Now</h6>
                            {
                              userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id )  ?
                              <h6 style={{ textAlign: "center", fontSize: 13, color: "blue", cursor: 'pointer' }} onClick={() => handleProfileClick(item.id)}>More Detail</h6>
                            
                               :
                               <></>
                               

                            }
                           
                           
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
            </div>
            <div className="mobile-screen">
              <div className="mobile-row">
                {
                  foundValue && foundValue.slice().reverse().map(item => {
                    return (
                      <div className="mobile-match-card" style={{ marginTop: 10 }} onClick={()=>{ userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?handleProfileClick(item.id) : alert("Not See More Detail")}}>
                        <div className="mobile-match-card-box">
                          <img
                            src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.profile}`}
                            className="mobile-match-img"
                          />

                          <div className="overlay-mobile">
                            <div className="mobile-match-desc">
                              <div className="mobile-match-desc-row">
                                <h5 onClick={() => handleProfileClick(item.id)}>{Capitalize(item.firstName)} {Capitalize(item.lastName)} <span>
                                  {
                                    item.gender === "Male" ?
                                      (
                                        <IoMdMale style={{ marginTop: '-4px', color: '#0984e3' }} />
                                      )
                                      :
                                      (
                                        <IoMdFemale style={{ marginTop: '-4px', color: '#e84393' }} />
                                      )
                                  }
                                </span></h5>
                                <p style={{ fontSize: 12, marginTop: 10, color: "#fff" }}>
                                  <GoDotFill className="Blink" style={{ color: '#2ecc71' }} /><span style={{ fontSize: 12 }}> Online Now</span>
                                </p>
                              </div>
                              <div className="mobile-match-desc-row" style={{ marginTop: -10 }}>
                                <h6 style={{ fontSize: 12 }}>{item.age} yrs,{item.height}</h6>
                                <h6 style={{ fontSize: 12 }}>{item.religion}, {item.caste}</h6>
                                <h6 style={{ fontSize: 12 }}>Hindi </h6>
                              </div>
                              <div className="mobile-match-desc-row" style={{ marginTop: -10 }}>
                                {
                                  item.meritalStatus === null ?
                                    <h6 style={{ fontSize: 10 }}>Never Married</h6>
                                    :
                                    <h6 style={{ fontSize: 10 }}>{item.meritalStatus}</h6>
                                }
                                <h6 style={{ fontSize: 10 }}>
                                  {item.city}, {item.state}
                                </h6>
                                <h6 style={{ fontSize: 10 }}>{item.occupation} </h6>
                              </div>

                              <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <h6 style={{ fontStyle: "italic", fontWeight: "500", textAlign: "center", marginLeft: 10, fontSize: 12 }}>
                                  Like this Profile ?
                                </h6>

                               
                                {
                                userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id )  ?
                                <h6 style={{ textAlign: "center", fontSize: 13, color: "blue", cursor: 'pointer' }} onClick={() => handleProfileClick(item.id)}>More Detail</h6>
                               :
                               <></>
                             

                            }
                                {
                                  userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ? (
                                    <div className="match-card-connection">
                                      <FaCheckCircle style={{ fontSize: 40, color: '#2ecc71' }} />

                                    </div>
                                  ) :

                                  loadingAus && getId === item.id ?
                                      <Spinner animation="border" role="status" className="mx-auto d-block">
                                        <span className="visually-hidden">Loading...</span>
                                      </Spinner>
                                      :
                                      createEmailLoading && getId === item.id ?
                                        <Spinner animation="border" role="status" className="mx-auto d-block">
                                          <span className="visually-hidden">Loading...</span>
                                        </Spinner>
                                        :
                                         userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id )  ?
                                          <div className="match-card-connection" >
                                          <IoMdAddCircle style={{ fontSize: 50, color: '#e74c3c', cursor: "pointer" }} />
                                        </div>
                                        :
                                        <div className="match-card-connection" onClick={() => handleConnectUser(item.id)}>
                                          <IoMdAddCircle style={{ fontSize: 50, color: '#e74c3c', cursor: "pointer" }} />
                                        </div>
                                        
                                }

                              </div>
                          
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
}