import React from 'react'
import AdminPageLayout from '../admin/AdmnPageLayout.js'
import AllMessage from './AllMessage.js'

function ContactRequests() {
  return (
    <>
        <AdminPageLayout>
            <AllMessage />
        </AdminPageLayout>
    </>
  )
}

export default ContactRequests