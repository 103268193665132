import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { Badge, Button, Card, Col, Container, Image, Row, Table, Modal } from 'react-bootstrap';
import { GET_ALL_MESSAGE } from '../user/grqphqloperation/query';
import { FaTrash } from 'react-icons/fa';
import LoadingBar from '../LoadingBar';
import { MUTATION_CONTACT_US_DELETE } from '../user/grqphqloperation/mutation';
import swal from 'sweetalert';

export default function AllMessage() {

    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [selectedMessage, setSelectedMessage] = useState(''); // State to store the selected message

    const { data, loading,refetch } = useQuery(GET_ALL_MESSAGE);

    useEffect(()=>{
        refetch();
    },[])

    const [deleteContactUs, { loading: contactLoading }] = useMutation(
        MUTATION_CONTACT_US_DELETE,
        {
            refetchQueries: [GET_ALL_MESSAGE],
        }
    );

    const openModal = (message) => {
        setSelectedMessage(message);
        setShowModal(true);
    };

    const closeModal = () => {
        setShowModal(false);
    };

    const handleDeleteContact = (id) => {
        deleteContactUs({
            variables: {
                "contactId": id
            }
        });
        swal({
            title: "Data Deleted.",
            text: "Contact deleted successfully.",
            icon: "success",
        });
    };

    return (
        <>
            <LoadingBar isLoading={loading} />
            <Container>
                <Row style={{ marginTop: 20 }}>
                    <Container>
                        <h3 style={{ color: '#EC4C5A' }}> All Contacts</h3>
                        <hr />
                        <Table striped bordered hover responsive>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Contact</th>
                                    <th>Email</th>
                                    <th>Message</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data && data.getContact && data.getContact.length > 0 ? (
                                    data.getContact.slice().reverse().map(list => (
                                        <tr key={list.id}>
                                            <td>{list.name}</td>
                                            <td>{list.contact}</td>
                                            <td>{list.email}</td>
                                            <td>
                                                <Badge variant="secondary" onClick={() => openModal(list.message)}>View Message</Badge>
                                            </td>
                                            <td>
                                                <Button onClick={() => handleDeleteContact(list.id)} className='mx-auto d-block' style={{ backgroundColor: 'red', border: 'none', borderRadius: '50%' }}>
                                                    <FaTrash />
                                                </Button>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="5">No records found...</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </Container>
                </Row>
            </Container>

            {/* Modal for displaying messages */}
            <Modal show={showModal} onHide={closeModal}>
                <Modal.Header closeButton>
                    <Modal.Title>Message</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{selectedMessage}</p>
                </Modal.Body>
            </Modal>
        </>
    )
}
