import { useLazyQuery, useMutation } from "@apollo/client";
import React, { useState } from "react";
import {
  Card,
  Row,
  Col,
  Form,
  Container,
  Button,
  Spinner,
} from "react-bootstrap";
import {
  MUTATION_ADMIN_PASSWORD_CHANGE,
  SMS_OTP,
} from "../user/grqphqloperation/mutation";
import { GET_ADMIN_PASSWORD } from "../user/grqphqloperation/query";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";

export default function AdminPassword() {
  const [sendSms, { data: smsData }] = useMutation(SMS_OTP);
  const [contact, setContact] = useState("");
  const [getOTP, setGetOTP] = useState("");
  const [checkOTP, setCheckOTP] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [adminUserName,setAdminUserName] =useState("")

  const [getAdminContact, { data, loading, error }] =
    useLazyQuery(GET_ADMIN_PASSWORD);

  const navigate = useNavigate();

  const [adminChangePassword, { loading: loadingAdmin }] = useMutation(
    MUTATION_ADMIN_PASSWORD_CHANGE
  );

  function generateOtp() {
    const otp = Math.floor(1000 + Math.random() * 9000);
    localStorage.setItem("otp", otp);
    sendSms({
      variables: {
        contact: `${contact}`,
        otp: `${otp}`,
      },
    }).then(() => {
      setCheckOTP(true);
    });
  }

  function handleVerify() {
    if (localStorage.getItem("otp") === getOTP) {
      getAdminContact({
        variables: {
          contact: `${contact}`,
        },
      });
    }
  }

  console.log("data", data);
  function handleChange() {
    if (newPassword === confirmPassword) {
      adminChangePassword({
        variables: {
          updateAdminPass: {
            adminId: `${data && data.getAdminContact.id}`,
            userName: `${adminUserName}`,
            password: `${newPassword}`,
          },
        },
      }).then(() => {
        setConfirmPassword("");
        setNewPassword("");
        swal({
          title: "Successful",
          text: "Create Profile Successfully!!!",
          icon: "success",
        });
        navigate("/Adminlogin");
      });
    } else {
      alert("Confirm Password not match");
    }
  }

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100%",
      }}
    >
      <div
        style={{
          width: "90%",
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background:
            "linear-gradient(90deg, rgba(222,196,196,1) 13%, rgba(255,0,0,1) 100%)",
        }}
      >
        <Row style={{ width: "100%" }}>
          <Col md={3}></Col>
          <Col md={6}>
            {" "}
            <Card style={{ width: "", height: 300, borderRadius: 10 }}>
              <h6 style={{ textAlign: "center", marginTop: 20 }}>
                Change Password
              </h6>
              <Container style={{ marginTop: 50 }}>
                {data && data.getAdminContact !== null && checkOTP === true ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Enter New Password"
                      onChange={(e) => setAdminUserName(e.target.value)}
                      value={adminUserName}
                    />

                    <Form.Control
                      type="text"
                      placeholder="Enter New Password"
                      onChange={(e) => setNewPassword(e.target.value)}
                      value={newPassword}
                    />
                    <Form.Control
                      type="text"
                      placeholder="Enter Confirm Password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      style={{ marginTop: 10 }}
                      value={confirmPassword}
                    />
                    {loadingAdmin ? (
                      <Spinner animation="border" className="mx-auto d-block"/>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="mx-auto d-block"
                        onClick={() => handleChange()}
                        style={{ marginTop: 20 }}
                      >
                        Change Now
                      </Button>
                    )}
                  </>
                ) : checkOTP === true ? (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Enter OTP"
                      value={getOTP}
                      onChange={(e) => setGetOTP(e.target.value)}
                    />
                    {data && data.getAdminContact === null ? (
                      <h6 style={{ color: "red", fontSize: 12 }}>
                        **this contact number does not exist**
                      </h6>
                    ) : (
                      <></>
                    )}

                    {loading ? (
                      <Spinner animation="border" className="mx-auto d-block"/>
                    ) : (
                      <Button
                        variant="primary"
                        type="submit"
                        className="mx-auto d-block"
                        onClick={() => handleVerify()}
                        style={{ marginTop: 20 }}
                      >
                        Verify OTP
                      </Button>
                    )}
                  </>
                ) : (
                  <>
                    <Form.Control
                      type="text"
                      placeholder="Enter Contact Number"
                      onChange={(e) => setContact(e.target.value)}
                      value={contact}
                    />

                    <Button
                      variant="primary"
                      type="submit"
                      className="mx-auto d-block"
                      onClick={() => generateOtp()}
                      style={{ marginTop: 20 }}
                    >
                      Send OTP
                    </Button>
                  </>
                )}
              </Container>
            </Card>
          </Col>
          <Col md={3}></Col>
        </Row>
      </div>
    </div>
  );
}
