import React, { useState } from "react";
import { Row, Col, Container, Card, Button, Spinner } from "react-bootstrap";
import "../UserCss/Subscription.css";
import { FaCheck } from "react-icons/fa";
import { useMutation } from "@apollo/client";
import { USER_SUBSCRIPTION } from "./grqphqloperation/mutation";
import { useNavigate } from "react-router-dom";
import { GET_USER } from './grqphqloperation/query';
import Navigation from "../Navigation";
import { Elements, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { CardElement } from "@stripe/react-stripe-js"; // Import CardElement
import { MUTATION_CREATE_STRIPE_LINK } from './grqphqloperation/mutation'; // Import your mutation


const stripePromise = loadStripe("pk_live_51NuvyYSBrb5oXsTyyAWehZlWXUW5oeY3WbgMt1Z2TxM2ilmsOGeuUdQqMVPlO1Q7Uh8opL6qny53om7UFhabC4NT000l86dziG"); // Replace with your actual Stripe publishable key


function SubscriptionPlan() {
  const userId = localStorage.getItem("userId");
  const [stop, setStop] = useState(false)
  const [createStripeLink, { data: stripeData, loading: stripeLoading }] = useMutation(MUTATION_CREATE_STRIPE_LINK); // Use the mutation
  const [subscription, { data, loading, error }] = useMutation(USER_SUBSCRIPTION, {
    refetchQueries: [
      GET_USER
    ]
  });

  const bookingPaymentDoneHandle = async (amountprice, subscriptionPlanName) => {
    try {
      await subscription({
        variables: {
          subscriptionInput: {
            userId: `${userId}`,
            subscriptionPlanName: `${subscriptionPlanName}`,
            bookingAmount: `${amountprice}`
          },
        },
      }).then(() => {
        createStripeLink({
          variables: {
            "bookingAmount": `${amountprice}`,
            "subscriptionPlanName": `${subscriptionPlanName}`
          }
        })
      });
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Error processing payment. Please try again.');
    }

  };

  if (stripeData && stop === false) {
    window.open(stripeData.stripeCheckout);
    window.close();
    setStop(true)
  }

  if (stop === true) {
    window.open("blank", "_self");
    window.close();
  }

  return (
    <>
      <Navigation />
      <h2 style={{ textAlign: "center", fontFamily: "Dm sans", marginTop: "110px" }}>Choose Your Plan</h2>
      <Container style={{ marginTop: "20px" }}>
        <Row>
          <Col md={4}>
            <Card className="card  mx-auto d-block " style={{ width: "200px", boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.2)", borderRadius: "20px", marginTop: "20px" }}>
              <div style={{ display: "flex", marginLeft: "50px", marginTop: "20px", fontFamily: "Dm sans" }}>
                <h6 style={{ fontWeight: "bold", textAlign: 'center', color: '#3498db' }}>Silver <span style={{ fontWeight: 'normal', color: "#34495e" }}>1 Month</span></h6>
              </div>
              <h4 style={{ textAlign: "center", marginTop: "20px" }}>₹299</h4>
              <span style={{ marginLeft: "60px", fontSize: "12px", fontFamily: "Dm sans", marginTop: "-20px" }}>₹299 per month</span>
              <Button className="btn-sub" style={{ fontWeight: "600", marginBottom: 50, background: '#3498db' }} onClick={() => bookingPaymentDoneHandle(299, "Silver")}>Continue</Button>
            </Card>
          </Col>

          <Col md={4}>
            <Card className="card  mx-auto d-block" style={{ width: "220px", boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.2)", borderRadius: "20px", marginTop: "20px" }}>
              <div style={{ display: "flex", marginLeft: "50px", marginTop: "20px", fontFamily: "Dm sans" }}>
                <h6 style={{ fontWeight: "bold", textAlign: 'center', color: '#2ecc71' }}>Platinum <span style={{ fontWeight: 'normal', color: "#34495e" }}>5 Months</span></h6>
              </div>
              <h4 style={{ textAlign: "center", marginTop: "20px" }}>₹599</h4>
              <span style={{ marginLeft: "70px", fontSize: "12px", fontFamily: "Dm sans", marginTop: "-20px" }}>₹199 per Month</span>
              <Button className="btn-sub" style={{ marginLeft: "50px", background: "#2ecc71", color: "#fff", fontWeight: "600", marginBottom: 50 }} onClick={() => bookingPaymentDoneHandle(599, "Platinuim")}              >
                Continue
              </Button>
            </Card>
          </Col>
          <Col md={4}>
            <Card className=" card mx-auto d-block" style={{ width: "200px", boxShadow: "5px 5px 5px 0px rgba(0,0,0,0.2)", borderRadius: "20px", marginTop: "20px" }}>
              <div style={{ display: "flex", marginLeft: "50px", marginTop: "20px", fontFamily: "Dm sans" }}>
                <h6 style={{ fontWeight: "bold", textAlign: 'center', color: '#9b59b6' }}>Gold <span style={{ fontWeight: 'normal', color: "#34495e" }}>3 Months</span></h6>
              </div>
              <h4 style={{ textAlign: "center", marginTop: "20px" }}>₹499</h4>
              <span style={{ marginLeft: "60px", fontSize: "12px", fontFamily: "Dm sans", marginTop: "-20px" }}>₹166 per Month</span>
              <Button className="btn-sub" style={{ fontWeight: "600", marginBottom: 50, background: '#9b59b6' }} onClick={() => bookingPaymentDoneHandle(499, "Gold")}              >
                Continue
              </Button>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default function StripeSubscriptionPlan() {
  return (
    <Elements stripe={stripePromise}>
      <SubscriptionPlan />
    </Elements>
  );
}
