import React, { useEffect, useState } from 'react';
import './Loading.css';

function LoadingBar({ isLoading }) {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(isLoading);

    // Disable scrolling on the body if isLoading is true
    if (isLoading) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }

    // Clean up: Re-enable scrolling when component unmounts
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isLoading]);

  return (
    <div className={loading ? 'loadingContainer' : 'loadingContainer none'}>
      <div className='loadingBars'>
        <div className='loadingBar'></div>
        <div className='loadingBar'></div>
        <div className='loadingBar'></div>
      </div>
    </div>
  );
}

export default LoadingBar;
