import { useQuery } from "@apollo/client";
import React from "react";
import { Container, Row, Col, Image, Button, Card } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { USER_NOTIFICATION } from "./grqphqloperation/query";

function Notification() {
  const userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");
  const navigate =useNavigate()

  const { data: connectionData } = useQuery(USER_NOTIFICATION, {
    variables: {
      userId: `${userId}`,

    },refetchQueries:[

      {query:USER_NOTIFICATION}
    ]



  });

 console.log("connectionData",connectionData)


 
  const handleProfileClick = (profile) => {
    if (userId && token) {
      navigate(`/userProfile/${profile}`);
    } else {
      navigate('/userLogin');
    }
  };
   

  return (
    <div>
      <Container>
        <Row style={{ marginTop: 20 }}>
          <Container>
            <h3 style={{color:'#EC4C59'}}> Notification </h3>
            <hr />
           
            {connectionData &&
              connectionData.getConnectionFor.map(uData => {
                  
                return (

                  <Card style={{ marginBottom: 30 }} className="ProfilesCard">
                  <Card.Body>
                    <Row>
                      <Col md={3} style={{ marginTop: "10px" }}>
                        <Row>
                          <Col md={7}>
                          <p style={{marginTop: 12,fontSize:"14px",fontFamily:"Dm sans",fontWeight:"bold",color:"#000",letterSpacing:0.5}}>{uData.connectionFromFname}  {uData.connectionFromLname}</p>
                          </Col>
                          <Col
                            md={3}
                            style={{ marginTop: 12, textAlign: "center" }}
                          >
                          <p style={{fontSize:"13px",fontFamily:"Dm sans"}}>{uData.connectionFromAge}</p>  
                          
                          </Col>
                        </Row>
                      </Col>
                      <Col md={3} style={{ marginTop: 20 }}>
                      <p style={{fontSize:"13px",fontFamily:"Dm sans"}}>{uData.connectionFromCaste}</p>
                      </Col>
                      <Col md={3} style={{ marginTop: 20 }}>
                      <p style={{fontSize:"13px",fontFamily:"Dm sans"}}>{uData.connectionFromContact}</p>
                      </Col>
                      <Col md={3} style={{ marginTop: 12, fontSize: "12px",fontFamily:"Dm sans" }}>
                        <Button className="btn-1 btn2"  onClick={()=>handleProfileClick(uData.connectionFrom)}>View</Button>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>

                )
              })
            }
         
          </Container>
        </Row>
      </Container>
    </div>
  );
}

export default Notification;
