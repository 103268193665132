import React, { useEffect, useState } from 'react';
import { PDFViewer, Document, Page, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import Logo from '../../Images/logo.png'; // Import your logo/image here
import { useParams } from 'react-router-dom';
import { GET_USER } from '../user/grqphqloperation/query';
import { useQuery } from '@apollo/client';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
    padding: 20,
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
    borderRadius: 5,
    backgroundColor: '#FFFFFF',
    boxShadow: '2px 2px 5px #999999',
  },
  logo: {
    width: 100,
    marginBottom: 10,
  },
  table: {
    display: 'table',
    width: '100%',
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#AAAAAA',
    borderRadius: 5,
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableCol: {
    width: '50%',
    padding: 5,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#AAAAAA',
    textAlign: 'left',
    fontSize:'12px'
  },
  heading: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  icon: {
    width: 30,
    height: 30,
    marginRight: 5,
  },
});

const JsonToPdf = () => {

    const { userId } = useParams();
    const { data: userData, loading, error } = useQuery(GET_USER, {
      variables: {
        "id": userId 
      }
    });
  
    const [user, setUser] = useState(null);
  
    useEffect(() => {
      if (userData && userData.getOneUser) {
        setUser(userData.getOneUser);
      }
    }, [userData]);
  
    const selectedData = ["firstName", "lastName", "email","mobile","dob","caste","gender","height","age","religion","city","state","country","occupation"];
  
    const filteredData = Object.fromEntries(
      Object.entries(user || {})
        .filter(([key, value]) => selectedData.includes(key))
    );

  return (
    <PDFViewer style={{ width: '100vw', height: '100vh' }}>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Image style={styles.logo} src={Logo} alt="Logo" />
            <Text style={styles.heading}>User Information</Text>
            <View style={styles.table}>
              {Object.entries(filteredData).map(([key, value]) => (
                <View key={key} style={styles.tableRow}>
                  <Text style={styles.tableCol}>{key}</Text>
                  <Text style={styles.tableCol}>{value}</Text>
                </View>
              ))}
            </View>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
};

export default JsonToPdf;
