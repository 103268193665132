import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Card } from 'react-bootstrap';
import Moment from 'react-moment';
const DefaultProfile = require('../../../Images/user-profile.png');

const UserConnectionModel = ({ show, handleClose, profile, connection }) => {
    let [isImageView, setIsImageView] = useState(false);
    useEffect(() => {
        setIsImageView(false);
    }, [profile])
    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{ profile && profile.firstName ? profile.firstName : '' } { profile && profile.lastName ? profile.lastName : ''}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Row className="align-items-center justify-content-center mb-4">
                    <Col xs={12} md={2} className="text-center">
                        {
                           profile && profile.profile ? 
                            (<img
                                src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${ profile && profile.profile}`}
                                alt="Profile"
                                className="img-fluid rounded-circle"
                                style={{ width: 100, height: 100, objectFit: 'cover' }}
                            />):
                            (<img
                                src={DefaultProfile}
                                alt="Profile"
                                className="img-fluid rounded-circle"
                                style={{ width: 100, height: 100, objectFit: 'cover' }}
                            />)
                        }
                        <h6>{ profile && profile.firstName ? profile.firstName : '' } { profile && profile.lastName ? profile.lastName : ''}</h6>
                    </Col>
                </Row>
                <hr />

                {
                    isImageView && (
                        <Row>
                            {connection &&
                                connection.map((conn, index) => (
                                    <Col key={index} xs={12} md={12} lg={12}>
                                        <Card className="my-3 bg-light">
                                            <Card.Body>
                                                {/* <Card.Title>Connection Details</Card.Title> */}
                                                <Card.Text>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <p style={{fontSize:12}} > Connection For: {conn.connectionForFname} {conn.connectionForLname}</p>
                                                            <p style={{fontSize:12}} >Age: {conn.connectionForAge}</p>
                                                            <p style={{fontSize:12}} >Caste: {conn.connectionForCaste}</p>
                                                            <p style={{fontSize:12}} >Contact: {conn.connectionForContact}</p>
                                                        </div>
                                                        <div>
                                                            <p style={{fontSize:12}} >Connection From: {conn.connectionFromFname} {conn.connectionFromLname}</p>
                                                            <p style={{fontSize:12}} >Age: {conn.connectionFromAge}</p>
                                                            <p style={{fontSize:12}} >Caste: {conn.connectionFromCaste}</p>
                                                            <p style={{fontSize:12}} >Contact: {conn.connectionFromContact}</p>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <div className='d-flex align-items-center justify-content-between h-auto'>
                                                    <p style={{fontSize:18}} >Status: {conn.status}</p>
                                                    <p style={{fontSize:18}} >Date: <Moment format="D MMM YYYY">{conn.timeDate}</Moment></p>
                                                    </div>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                        </Row>
                    )
                }



                {!isImageView && (
                    <Row>
                        <Col md={6}>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Name :</span> {profile && profile.firstName ? profile.firstName : null} {profile && profile.lastName ? profile.lastName : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Email :</span> {profile && profile.email} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Age :</span> {profile && profile.age} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Gender :</span> {profile && profile.gender} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Date Of Birth :</span>{profile && profile.dob}  </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Caste :</span>{profile && profile.caste} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Height :</span>{profile && profile.height} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Religion :</span> {profile && profile.religion} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Occupation :</span> {profile && profile.occupation} </p>
                        </Col>
                        <Col md={6}>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Contact :</span> {profile && profile.mobile} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>City :</span> {profile && profile.city ? profile.city : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Country :</span> {profile && profile.country ? profile.country : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Zip Code :</span> {profile && profile.pincode ? profile.pincode : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Merital Status :</span> {profile && profile.meritalStatus ? profile.meritalStatus : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Booking Ammount :</span> {profile && profile.bookingAmount ? profile.bookingAmount : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Subscription :</span> {profile && profile.subscriptionPlanName ? profile.subscriptionPlanName : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Status :</span> {profile && profile.subscriptionPlanStatus ? profile.subscriptionPlanStatus : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Payment I'D :</span> {profile && profile.razorpayPaymentId ? profile.razorpayPaymentId : null} </p>
                            <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Account I'D :</span> {profile && profile.id ? profile.id : null} </p>
                        </Col>
                    </Row>
                )}

                <center style={{ fontSize: 12 }}><i>Account Since : <Moment format="D MMM YYYY">{profile && profile.createDateTime}</Moment></i></center>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setIsImageView(!isImageView)}>
                    {isImageView ? 'Details' : 'Connections'}
                </Button>

                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>

            </Modal.Footer>
        </Modal>
    );
};

export default UserConnectionModel;
