import React, { useLayoutEffect } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import "../ComponentsCss/Navigation.css";
import { LinkContainer } from "react-router-bootstrap";
import logo from "../Images/logo.png";
import gsap from "gsap";

export default function Navigation() {
  const navigater = useNavigate();
  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("userId");
  const adminToken = localStorage.getItem("adminToken");

  const handleLogOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("userId");
    localStorage.removeItem("adminToken");
    localStorage.removeItem("adminId");
    localStorage.removeItem("isAdmin");
    localStorage.removeItem("rzp_device_id");
    localStorage.removeItem("rzp_checkout_anon_id");
    localStorage.removeItem("MERCHANT_STATIC_CONFIG");
    navigater("/");
  };

  useLayoutEffect(() => {
    gsap.from(".navbar", {
      duration: 0.8,
      y: "-100%",
      opacity: 0,
      ease: "ease",
      delay: 0.2,
    });
  }, []);

  return (
    <Navbar
      collapseOnSelect
      className="navbar"
      expand="lg"
      variant="light"
      bg="white"
      fixed="top"
    >
      <Container>
        <Navbar.Brand href="#home">
          <img
            src={logo}
            width="250"
            height="80"
            className="d-inline-block align-top"
            alt="React Bootstrap logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          style={{ color: "rgb(255, 90, 96)" }}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav
            className="ms-auto"
            style={{ marginRight: "10px", textAlign: "center" }}
          >
            <LinkContainer to="/" style={{ textDecoration: "none" }}>
              <Nav.Link
                className="headerLink"
                href="#home"
                style={{ textDecoration: "none" }}
              >
                Home
              </Nav.Link>
            </LinkContainer>
            <LinkContainer to="/plan" style={{ textDecoration: "none" }}>
              <Nav.Link
                className="headerLink"
                href="#home"
                style={{ textDecoration: "none" }}
              >
                Plan
              </Nav.Link>
            </LinkContainer>
            {!token && !userId && (
              <>
                <LinkContainer
                  to="/MatchesProfile"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Matches</Nav.Link>
                </LinkContainer>
              </>
            )}

            {token ? (
              <>
                <LinkContainer
                  to="/userDashboard"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Profile</Nav.Link>
                </LinkContainer>

                <LinkContainer
                  to="/MatchesProfile"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Matches</Nav.Link>
                </LinkContainer>

                <Nav.Link
                  className="headerLink"
                  style={{ textDecoration: "none" }}
                  onClick={handleLogOut}
                >
                  Logout
                </Nav.Link>
              </>
            ) : adminToken ? (
              <>
                <LinkContainer
                  to="/AdminDashboard"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Admin Dashboard</Nav.Link>
                </LinkContainer>

                <Nav.Link
                  className="headerLink"
                  style={{ textDecoration: "none" }}
                  onClick={handleLogOut}
                >
                  Admin Logout
                </Nav.Link>
              </>
            ) : (
              <>
                <LinkContainer
                  to="/userLogin"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Login</Nav.Link>
                </LinkContainer>

                <LinkContainer
                  to="/Adminlogin"
                  style={{ textDecoration: "none" }}
                >
                  <Nav.Link className="headerLink">Admin Login</Nav.Link>
                </LinkContainer>
              </>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
