import React,{useState} from 'react'
import AdminPageLayout from '../admin/AdmnPageLayout'
import {Table,Button,Modal,Form,Col,Row, Container} from 'react-bootstrap'
import { useMutation, useQuery } from '@apollo/client'
import { GET_ALL_PLAN } from '../user/grqphqloperation/query'
import { MUTATION_EDIT_PLAN } from '../user/grqphqloperation/mutation'

export default function AdminPlan() {
  
 const{data,loading} =  useQuery(GET_ALL_PLAN)

 console.log(data)
 const [show, setShow] = useState(false);

 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);

 const [validated, setValidated] = useState(false);

 const[planName,setPlanName] =useState('')
 const[planPrice,setPlanPrice] =useState('')
 const[getId,setGetId] =useState('')

  
 const handleClick=(id,name,price)=>{
    handleShow()
    setPlanName(name)
    setPlanPrice(price)
    setGetId(id)
 }

  const[updatePlan] = useMutation(MUTATION_EDIT_PLAN,{
    refetchQueries:[
        GET_ALL_PLAN,
        "getPlan"
    ]
  })


  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
        event.preventDefault();
        updatePlan({
            variables:{
                "updatePlanInput": {
                    "planId": `${getId}`,
                    "plantype":`${planName}`,
                    "price": `${planPrice}`
                  }
            }
        }).then(()=>{
            handleClose()
        })

    }

    setValidated(true);
  };




  return (
    <>
    <AdminPageLayout>

     <h5>Subscription plan</h5>
     <Table striped bordered hover>
      <thead>
        <tr>
          <th>#</th>
          <th>Plan Name</th>
          <th>Price</th>
          <th>Edit</th>
        </tr>
      </thead>
      <tbody>
        {
             data && data.getPlan.map((item,Index)=>{
                return(
                    <tr>
                    <td>{Index+1}</td>
                    <td>{item.plantype}</td>
                    <td>{item.price}/-</td>
                    <td><Button variant="primary" onClick={()=>handleClick(item.id,item.plantype,item.price)}  >Edit</Button></td>
                  </tr>
                )
             })


        }
      
      
      
      </tbody>
    </Table>
    <Modal show={show} onHide={handleClose}>
        <Container style={{marginTop:10}}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Form.Group as={Col} md="12" controlId="validationCustom01">
          <Form.Label>Plan Name</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Plan Name"
            value={planName}
            onChange={(e)=>setPlanName(e.target.value)}
            
          />
       
        </Form.Group>
     
       
      </Row>

      <Row className="mb-3">
      <Form.Group as={Col} md="12" controlId="validationCustom02">
          <Form.Label>Plan Price</Form.Label>
          <Form.Control
            required
            type="text"
            placeholder="Plan Price"
            value={planPrice}
            onChange={(e)=>setPlanPrice(e.target.value)}
          />
       
        </Form.Group>
        
      </Row>
   
     
      <Button type="submit" style={{marginBottom:20}}>Change now</Button>
    </Form>
    </Container>
      </Modal>

    </AdminPageLayout>
    </>
  )
}
