import React, { useLayoutEffect } from "react";
import { Container, Row, Col, Carousel, Image, Card } from "react-bootstrap";
import brand1 from "../Images/a.jpg";
import brand2 from "../Images/b.jpg";
import brand3 from "../Images/c.jpg";
import brand4 from "../Images/shadi2.jpg";
import brand5 from "../Images/e.jpg";
import brand6 from "../Images/shadi1.png";
import "../ComponentsCss/Stories.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Stories() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".storymain",
        start: "top bottom-=50px",
        toggleActions: "play none none none",
        // markers: true,
      },
    });

    tl.from(".slider", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      stagger: 0.1,
    });

    return () => {tl.kill();};
  }, []);

  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Container fluid className="storymain">
        <Row>
          <Row style={{ marginTop: 50 }}>
            <Col md={12}>
              <p
                style={{
                  color: "#ff5a60",
                  fontSize: 30,
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "'Amaranth', sans-serif",
                  letterSpacing: 1,
                }}
              >
                Matrimony Service with Millions of Success Stories
              </p>
            </Col>
          </Row>

          <Col md={12}>
            <Container style={{ marginTop: "30px" }}>
              <Row style={{ marginTop: "10px" }}>
                <Slider {...settings}>
                  <Col md={4} className="slider">
                    <div
                      className="Couplrcard"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "80%",
                          height: 450,
                          border: "1px solid #000",
                          borderRadius: 10,
                        }}
                      >
                        <img
                          variant="top"
                          src={
                            "https://e1.pxfuel.com/desktop-wallpaper/288/531/desktop-wallpaper-indian-wedding-couple-indian-marriage.jpg"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 5,
                            backgroundSize: "cover",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "70%",
                          position: "absolute",
                          bottom: "30px",
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomLeftRadius: 15,
                          borderTopRightRadius: 15,
                        }}
                      >
                        <h6>Piyush Weds Radhika</h6>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className="slider">
                    <div
                      className="Couplrcard"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "80%",
                          height: 450,
                          border: "1px solid #000",
                          borderRadius: 10,
                        }}
                      >
                        <img
                          variant="top"
                          src={
                            "https://i.pinimg.com/736x/55/4b/84/554b8493facc29b3f77e65a57039fdda.jpg"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 5,
                            backgroundSize: "cover",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "70%",
                          position: "absolute",
                          bottom: "30px",
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomLeftRadius: 15,
                          borderTopRightRadius: 15,
                        }}
                      >
                        <h6>Aman Weds Varsha</h6>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className="slider">
                    <div
                      className="Couplrcard"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "80%",
                          height: 450,
                          border: "1px solid #000",
                          borderRadius: 10,
                        }}
                      >
                        <img
                          variant="top"
                          src={
                            "https://i.pinimg.com/736x/03/ab/81/03ab81ee51776259ac31113260e7f639.jpg"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 5,
                            backgroundSize: "cover",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "70%",
                          position: "absolute",
                          bottom: "30px",
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomLeftRadius: 15,
                          borderTopRightRadius: 15,
                        }}
                      >
                        <h6>Sunil Weds Rashmi</h6>
                      </div>
                    </div>
                  </Col>

                  <Col md={4} className="slider">
                    <div
                      className="Couplrcard"
                      style={{ position: "relative" }}
                    >
                      <div
                        style={{
                          width: "80%",
                          height: 450,
                          border: "1px solid #000",
                          borderRadius: 10,
                        }}
                      >
                        <img
                          variant="top"
                          src={
                            "https://i.pinimg.com/736x/67/06/d9/6706d957e99516937cd08c13fb697eed.jpg"
                          }
                          style={{
                            width: "100%",
                            height: "100%",
                            padding: 5,
                            backgroundSize: "cover",
                            borderRadius: 10,
                          }}
                        />
                      </div>
                      <div
                        style={{
                          width: "70%",
                          position: "absolute",
                          bottom: "30px",
                          background: "#fff",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderBottomLeftRadius: 15,
                          borderTopRightRadius: 15,
                        }}
                      >
                        <h6>Kuldeep Weds Manisha</h6>
                      </div>
                    </div>
                  </Col>
                </Slider>
              </Row>
            </Container>
          </Col>
        </Row>

        <Row
          style={{
            backgroundColor: "#ff5a60",
            marginTop: 35,
            marginBottom: 15,
            height: "100px",
          }}
        >
          <Col md={12}>
            <h3
              style={{
                textAlign: "center",
                color: "#fff",
                fontWeight: "bold",
                marginTop: 30,
                fontFamily: "DM Sans",
              }}
            >
              What's Your Story ?
            </h3>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
