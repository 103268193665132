import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react';
import { Modal, Button, Row, Col, Form, Container } from 'react-bootstrap';
import Moment from 'react-moment';
import { MUTATION_UPDATE_STATUS, SUBSCRIPTION_PLAN } from '../../user/grqphqloperation/mutation';
import DefaultProfile from '../../../Images/user-profile.png';
import { useNavigate } from 'react-router-dom';
import { GET_ALL_USER, GET_SEARCH_USER_FILTER, GET_USER_ALL } from '../../user/grqphqloperation/query';

const ProfileModal = ({ show, handleClose, profile: data }) => {

    let navigate = useNavigate();

    const [profile, setProfile] = useState(null);

    useEffect(() => {
        setProfile(data);
    }, [data])

    let [isImageView, setIsImageView] = useState(false);

    const [changeStatus] = useMutation(MUTATION_UPDATE_STATUS);

    useEffect(() => {
        setIsImageView(false);
    }, [profile])

    const handleUnclock = (id) => {
        changeStatus({
            variables: {
                "updateNewStatus": {
                    "userid": `${id}`,
                    "status": "verify"
                }
            }
        }).then(res => {
            let { updateUserStatus } = res.data;
            if (updateUserStatus) {
                setProfile(prevProfile => ({
                    ...prevProfile,
                    status: updateUserStatus.status
                }));
            }
        }).catch(err => {
            console.log(err.message);
        })
    };

    const handleBlock = (id) => {
        changeStatus({
            variables: {
                "updateNewStatus": {
                    "userid": `${id}`,
                    "status": "block"
                }
            }
        }).then(res => {
            let { updateUserStatus } = res.data;
            if (updateUserStatus) {
                setProfile(prevProfile => ({
                    ...prevProfile,
                    status: updateUserStatus.status
                }));
            }
        }).catch(err => {
            console.log(err.message);
        })
    };

    const handleReject = (id) => {
        changeStatus({
            variables: {
                "updateNewStatus": {
                    "userid": `${id}`,
                    "status": "rejected"
                }
            }
        }).then(res => {
            let { updateUserStatus } = res.data;
            if (updateUserStatus) {
                setProfile(prevProfile => ({
                    ...prevProfile,
                    status: updateUserStatus.status
                }));
            }
        }).catch(err => {
            console.log(err.message);
        })
    };

    const handlePrint = (id) => {
        navigate(`/print/${id}`);
    };

    const handleVerify = (id) => {
        changeStatus({
            variables: {
                "updateNewStatus": {
                    "userid": `${id}`,
                    "status": "verify"
                }
            }
        }).then(res => {
            let { updateUserStatus } = res.data;
            if (updateUserStatus) {
                setProfile(prevProfile => ({
                    ...prevProfile,
                    status: updateUserStatus.status
                }));
            }
        }).catch(err => {
            console.log(err.message);
        })
    }

    const [show2, setShow2] = useState(false);

    const handleClose2 = () => setShow2(false);
    const handleShow2 = () => setShow2(true);
    const [selectPlan, setSelectPlan] = useState('')
    const [amount, setAmount] = useState('')

    const [subscription] = useMutation(SUBSCRIPTION_PLAN, {
        refetchQueries: [
            GET_ALL_USER,
            GET_SEARCH_USER_FILTER,
            GET_USER_ALL,
            "getAllUsers"

        ]
    })

    const [getID, setGetId] = useState('')
    const handlePlan = (id) => {
        handleShow2()
        setGetId(id)
    }

    const handleUpdatePlan = () => {
        subscription({
            variables: {
                "subscriptionInput": {
                    "bookingAmount": `${amount}`,
                    "subscriptionPlanName": `${selectPlan}`,
                    "userId": `${getID}`
                }
            }
        }).then(() => {
            handleClose2()
        })
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Profile Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="align-items-center justify-content-center mb-4">
                        <Col xs={12} md={2} className="text-center">
                            {
                                profile && profile.profile ?
                                    (<img
                                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${profile && profile.profile}`}
                                        alt="Profile"
                                        className="img-fluid rounded-circle"
                                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                                    />) :
                                    (<img
                                        src={DefaultProfile}
                                        alt="Profile"
                                        className="img-fluid rounded-circle"
                                        style={{ width: 100, height: 100, objectFit: 'cover' }}
                                    />)
                            }
                            <h6>{profile && profile.firstName ? profile.firstName : ''} {profile && profile.lastName ? profile.lastName : ''}</h6>
                        </Col>
                    </Row>
                    <hr />

                    {
                        isImageView && (
                            <>
                                <Row>
                                    {
                                        profile && profile.picture && profile.picture.length > 0 ?
                                            profile.picture[0].split(',').map((pic, index) => (
                                                <Col key={index} xs={6} md={4} lg={3} className="mb-3">
                                                    <div className="d-flex justify-content-center">
                                                        <img
                                                            src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${pic.trim()}`}
                                                            alt="Profile"
                                                            className="img-fluid"
                                                            style={{
                                                                width: 100,
                                                                height: 100,
                                                                objectFit: 'cover',
                                                                objectPosition: 'top',
                                                                margin: '0 auto', // Center the image horizontally
                                                            }}
                                                        />
                                                    </div>
                                                </Col>
                                            ))
                                            : 'No Pictures.'
                                    }
                                </Row>
                                <hr />
                                <div className="text-center">
                                    <h3
                                        style={{
                                            fontFamily: "Dm sans",
                                            fontSize: 12,
                                            fontWeight: "bold",
                                            color: "#000",
                                            marginBottom: 10
                                        }}
                                    > 
                                        ID Proof Image
                                    </h3>
                                    <img
                                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${profile?.userIdPic}`}
                                        alt="Profile"
                                        className="img-fluid"
                                        style={{
                                            width: 300,
                                            objectFit: 'cover',
                                            objectPosition: 'top',
                                            margin: '0 auto', // Center the image horizontally
                                        }}
                                    />
                                </div>
                            </>
                        )
                    }


                    {!isImageView && (
                        <Row>
                            <Col md={6}>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Name :</span> {profile && profile.firstName ? profile.firstName : null} {profile && profile.lastName ? profile.lastName : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Email :</span> {profile && profile.email} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Age :</span> {profile && profile.age} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Gender :</span> {profile && profile.gender} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Date Of Birth :</span>{profile && profile.dob}  </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Caste :</span>{profile && profile.caste} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Height :</span>{profile && profile.height} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Religion :</span> {profile && profile.religion} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Occupation :</span> {profile && profile.occupation} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Account Status :</span> {profile && profile.status} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>I'D Type :</span> {profile && profile?.userIdType} </p>
                            </Col>
                            <Col md={6}>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Contact :</span> {profile && profile.mobile} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>City :</span> {profile && profile.city ? profile.city : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Country :</span> {profile && profile.country ? profile.country : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Zip Code :</span> {profile && profile.pincode ? profile.pincode : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Merital Status :</span> {profile && profile.meritalStatus ? profile.meritalStatus : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Booking Ammount :</span> {profile && profile.bookingAmount ? profile.bookingAmount : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Subscription :</span> {profile && profile.subscriptionPlanName ? profile.subscriptionPlanName : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Subscription Status :</span> {profile && profile.subscriptionPlanStatus ? profile.subscriptionPlanStatus : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Payment I'D :</span> {profile && profile.razorpayPaymentId ? profile.razorpayPaymentId : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Account I'D :</span> {profile && profile.id ? profile.id : null} </p>
                                <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 12 }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>I'D Number :</span> {profile && profile?.userIdNumber} </p>

                            </Col>
                        </Row>
                    )}

                    {/* <center style={{ fontSize: 12 }}><i>Account Since : {profile && profile.createDateTime}</i></center> */}
                    <center style={{ fontSize: 12 }}><i>Account Since : <Moment format="D MMM YYYY">{profile && profile.createDateTime}</Moment> </i></center>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="warning" onClick={() => handlePlan(profile && profile.id)}>
                        Add Plan
                    </Button>
                    <Button variant="success" onClick={() => handleVerify(profile && profile.id)}>
                        Verify
                    </Button>
                    {profile && profile.status === 'block' ? (<Button variant="danger" onClick={() => handleUnclock(profile && profile.id)}>
                        Un Block
                    </Button>) : (<Button variant="danger" onClick={() => handleBlock(profile && profile.id)}>
                        Block
                    </Button>)}

                    {profile && (
                        <Button
                            variant="danger"
                            disabled={profile.status === 'rejected'}
                            onClick={() => handleReject(profile.id)}
                        >
                            {profile.status === 'rejected' ? 'Rejected' : 'Reject'}
                        </Button>
                    )}


                    <Button variant="primary" onClick={() => handlePrint(profile && profile.id)}>
                        Print Data
                    </Button>
                    <Button variant="primary" onClick={() => setIsImageView(!isImageView)}>
                        {isImageView ? 'Details' : 'Images'}
                    </Button>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2}>
                <Container>
                    <h6>Add Plan</h6>
                    <Form.Control
                        required
                        type="text"
                        placeholder='Entar Paid Amount'
                        onChange={(e) => setAmount(e.target.value)}
                        style={{ fontSize: "12px", fontFamily: "Dm Sans" }}
                    />
                    <Form.Select
                        type="text"
                        placeholder="Plan"
                        aria-describedby="inputGroupPrepend"
                        required
                        onChange={(e) => setSelectPlan(e.target.value)}
                        value={selectPlan}
                        style={{ fontSize: "13px", fontFamily: "Dm Sans", marginTop: 20, fontWeight: "600" }}
                    >
                        <option value="" selected="selected" disabled>
                            Choose
                        </option>
                        <option value="Silver" style={{ fontWeight: "600", color: "#000" }}>Silver</option>
                        <option value="Gold" style={{ fontWeight: "600", color: "#000" }}>Gold</option>
                        <option value=" Platinuim" style={{ fontWeight: "600", color: "#000" }}> Platinum</option>

                    </Form.Select>
                    <Button style={{ marginTop: 10, marginBottom: 10, width: "100%" }} onClick={() => handleUpdatePlan()}>Save</Button>
                </Container>
            </Modal>
        </>
    );
};

export default ProfileModal;
