import "./App.css";
import { Routes, Route, Link } from "react-router-dom";

import Home from "./Components/Home";
import Login from "./Components/user/Login";
import UserDashboard from "./Components/user/UserDashboard";
import ShowAllProfile from "./Components/user/ShowAllProfile";
import BuySub from "./Components/BuySub";
import Pic from "./Components/user/Pic";
import Detailpage from "./Components/user/Detailpage";
import SubscriptionPlan from "./Components/user/SubscriptionPlan";
import Adminlogin from "./Components/AdminComponents/Adminlogin";
import { ApolloClient, ApolloLink, InMemoryCache, split, ApolloProvider } from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { HttpLink } from "apollo-link-http";
import { WebSocketLink } from "@apollo/client/link/ws";
import SearchMatches from "./Components/SearchMatches";
import TermofServices from "./Components/TermofServices";
import PrivacyPolicy from "./Components/PrivacyPolicy";
import CancellationRefundPolicy from "./Components/CancellationRefundPolicy";
import AdminPanel from "./Components/admin/AdminPanel.js";
import RandomMaches from "./Components/RandomMaches.js";
import BlockedUsers from "./Components/AdminComponents/BlockedUsers.js";
import MembershipUsers from "./Components/AdminComponents/MembershipUsers.js";
import ContactRequests from "./Components/AdminComponents/ContactRequests.js";
import AllMessage from "./Components/AdminComponents/AllMessage.js";
import MatchingProfile from "./Components/MatchingProfile.js";
import JsonToPdf from "./Components/admin/JsonToPDF.js";
import CardPlan from "./Components/CardPlan.js";
import UserProfile from "./Components/user/UserProfile.js";
import AllMatChingProfile from "./Components/AllMatChingProfile.js";
import PaymentGateway from "./Components/AdminComponents/PaymentGateway.js";
import PaymentUser from "./Components/user/PaymentUser.js";
import AdminPlan from "./Components/AdminComponents/AdminPlan.js";
import AdminPassword from "./Components/AdminComponents/AdminPassword.js";
import Down from "./Down.js"
const notFound = require('./Images/bubble-gum-error-404.gif');

function App() {
  const httpLink = new HttpLink({
      //  uri:"http://localhost:4000/graphql",
   uri: "https://shadi-back-gk8f.vercel.app/graphql",
  });

  const wsLink = new WebSocketLink({
      //  uri:"wss:localhost:4000/graphql",
   uri: "wss:shadi-back-gk8f.vercel.app/graphql",
    options: {
      reconnect: true,
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: ApolloLink.from([splitLink]),
  });

  return (
    <ApolloProvider client={client}>
      <Routes>
        {/* <Route path="/" exact={true} element={<Down />} /> */}
        <Route path="/" exact={true} element={<Home />} />
        <Route path="/userLogin" exact={true} element={<Login />} />
        <Route path="/showallprofile" exact={true} element={<ShowAllProfile />} />
        <Route path="/Picture" exact={true} element={<Pic />} />
        <Route path="/user/:id" exact={true} element={<Detailpage />} />
        <Route path="/Subcriptionplan" exact={true} element={<SubscriptionPlan />} />
        <Route path="/userDashboard" exact={true} element={<UserDashboard />} />
        <Route path="/AdminDashboard" exact={true} element={<AdminPanel />} />
        <Route path="/Adminlogin" exact={true} element={<Adminlogin />} />
        <Route path="/Buyplan" exact={true} element={<BuySub />} />
        <Route path="/Searchresult" exact={true} element={<SearchMatches />} />
        <Route path="/TermofServices" exact={true} element={<TermofServices />} />
        <Route path="/PrivacyPolicy" exact={true} element={<PrivacyPolicy />} />
        <Route path="/CancellationRefundPolicy" exact={true} element={<CancellationRefundPolicy />} />
        <Route path="/admin" exact={true} element={<AdminPanel />} />
        <Route path="/matches" exact={true} element={<RandomMaches />} />
        <Route path="/matchingProfile" exact={true} element={<MatchingProfile />} />
        <Route path="/plan" exact={true} element={<CardPlan />} />
        <Route path="/userProfile/:id" exact={true} element={<UserProfile />} />
        <Route path="/MatchesProfile" exact={true} element={<AllMatChingProfile />} />
        <Route path="/PaymentUser" exact={true} element={<PaymentUser />} />
        <Route path="/adminPassword" exact={true} element={<AdminPassword />} /> 
        {/* admin routes */}
        <Route path="/admin/user/block" exact={true} element={<BlockedUsers />} />
        <Route path="/admin/user/premium" exact={true} element={<MembershipUsers />} />
        <Route path="/admin/user/all" exact={true} element={<AdminPanel />} />
        <Route path="/admin/payment" exact={true} element={<PaymentGateway />} />
        <Route path="/admin/contact" exact={true} element={<ContactRequests />} />
        <Route path="/admin/adminPlan" exact={true} element={<AdminPlan />} />
        <Route path="/print/:userId" exact={true} element={<JsonToPdf />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </ApolloProvider>
  );
}

function PageNotFound() {
  return (
    <div style={{ width: '100vw', height: '100vh' }}>
      <center>
        <img src={notFound} alt="" />
      </center>
      <center><h3 style={{ color: 'black' }}>!! 404 !!</h3></center>
      <center>
        <h5 id="returnToHome">
          Return to <Link to="/">Home</Link>
        </h5>
      </center>
    </div>
  );
}

export default App;

//http://localhost:4000/graphql
//https://shadi-back.vercel.app/graphql
