import React from 'react'
import '../UserCss/UserDashboard.css'
import { Nav, Tab, Row, Col, Container, Card, Image, Badge } from 'react-bootstrap';
import Navigation from '../Navigation'
import { IoAddOutline } from "react-icons/io5";
import SelectedProfile from '../user/SelectedProfile'
import MakeProfile from './MakeProfile';
import Notification from './Notification';
import { Link, useNavigate } from 'react-router-dom';
import EditProfile from './EditProfile';
import { useQuery } from '@apollo/client';
import { CONNECTION_FROM_PROFILE, GET_FRIEND_BY_ID_USERID, GET_USER, USER_NOTIFICATION } from './grqphqloperation/query';
import stress from '../../Images/3871893.jpg'
import defaultUserProfile from '../../Images/user-profile.png';
import LoadingBar from '../LoadingBar';
import Request from '../AllRequest';
import AllRequest from '../AllRequest';
export default function UserDashboard() {

    const navigate = useNavigate()
    const userId = localStorage.getItem("userId")

    const { data: connectionData } = useQuery(USER_NOTIFICATION, {
        variables: {
            userId: `${userId}`,
        },
    });
    const { loading, data, error } = useQuery(GET_USER, {
        variables: {
            "id": `${userId}`
        }

    })
    const{data:dataRequest,loading:loadingRequest} = useQuery(GET_FRIEND_BY_ID_USERID,{
        variables:{
          "userId": `${userId}`
        }
      })

    const { data: connectionFrom } = useQuery(CONNECTION_FROM_PROFILE, {
        variables: {
            userId: `${userId}`
        }
    })

    if (!localStorage.getItem("token")) {

        navigate("/userLogin")
        return <p style={{
            color: "red"
        }}>unauthorized</p>
    }


    

    console.log("data",data)
    return (
        <>
            <LoadingBar isLoading={loading} />
            <Navigation />
            <br></br>
            <br></br>

            {
                data && (data.getOneUser.status === "block" || data.getOneUser.status === "rejected") ?
                    <>
                        <Image className='mx-auto d-block' src={stress} style={{ height: '250px', width: '250px', margin: 30 }}></Image>
                        {data && data.getOneUser.status === "block" && (
                            <h4 style={{ textAlign: 'center', color: 'red' }}>Your Profile Is Blocked!!! Please Contact Admin.</h4>
                        )}
                        {data && data.getOneUser.status === "rejected" && (
                            <h4 style={{ textAlign: 'center', color: 'red' }}>Your Profile Is Rejected !!! Please Contact Admin.</h4>
                        )}
                    </>
                    :
                    <>
                        <Container className="sidebarContainer">
                            <Tab.Container defaultActiveKey="MakeProfile">
                                <Row>
                                    <Col md={3}>
                                        <Card className="leftCard">
                                            <Card.Body>
                                                {
                                                    data && data.getOneUser.status !== "pending" ?
                                                        <>
                                                            <div className="image-container">
                                                                <div className="image-box">
                                                                    <div className="edit-button"><IoAddOutline size={27} /></div>
                                                                    <Image className="mx-auto d-block img-fluid ProfileImg" src={`${data && data.getOneUser.profile ? `https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data.getOneUser.profile}` : 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Download-Image.png'}`} rounded />
                                                                </div>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="image-container">
                                                                <div className="image-box">
                                                                    <div className="edit-button"><IoAddOutline size={27} /></div>
                                                                    <Image className="mx-auto d-block img-fluid ProfileImg" src={`${data && data.getOneUser.profile ? `https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data.getOneUser.profile}` : 'https://www.pngall.com/wp-content/uploads/5/User-Profile-PNG-Download-Image.png'}`} rounded />
                                                                </div>
                                                            </div>
                                                        </>
                                                }

                                                <h4 style={{ textAlign: 'center', fontFamily: 'DM Sans', fontSize: '15px', fontWeight: 'bold', color: '#303030', marginTop: 10 }}>{data && data.getOneUser.firstName} {data && data.getOneUser.lastName}  </h4>
                                                {
                                                    data && data.getOneUser.subscriptionPlanStatus === "pending" ?
                                                        <>
                                                            <p style={{ fontSize: "12px", textAlign: "center", fontFamily: "Dm Sans", fontWeight: "600" }}><Link to="/plan" style={{
                                                                textDecoration
                                                                    : 'none'
                                                            }}>Subscription <Badge pill bg="warning" text="dark">No</Badge></Link> </p>
                                                        </>
                                                        :
                                                        <>
                                                            <p style={{ fontSize: "12px", textAlign: "center", fontFamily: "Dm Sans", fontWeight: "600" }}><Link to="/plan" style={{
                                                                textDecoration
                                                                    : 'none'
                                                            }}>Subscription <Badge pill bg="warning" text="dark"> <Badge pill bg="warning" text="dark">{data && data.getOneUser.subscriptionPlanName}</Badge></Badge></Link> </p>
                                                            {
                                                                data && data.getOneUser.status === "verify" ?
                                                                    <p style={{ textAlign: "center", color: "green", letterSpacing: 1 }}><Badge bg="success"> {data && data.getOneUser.status}</Badge>  </p>
                                                                    :
                                                                    <p style={{ textAlign: "center", color: "green", letterSpacing: 1 }}><Badge bg="primary"> {data && data.getOneUser.status}</Badge>  </p>
                                                            }

                                                        </>
                                                }

                                                <hr />
                                               <div  style={{cursor:"pointer"}}>
                                                <Nav activeKey="first" className={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending' ||  data.getOneUser.status === null) ? 'user-not-click' : ''} >
                                                    <div style={{cursor:"pointer",width:"100%"}}>
                                                    <Nav.Item>
                                                        <Nav.Link disabled={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending' ||  data.getOneUser.status === null) ? true : false} className={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending') ? 'user-not-click' : ''} eventKey="first" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Connection Profile <Badge bg="danger">{data && data.getOneUser.friends.length}</Badge></Nav.Link>
                                                    </Nav.Item>
                                                    </div>
                                                    <div style={{cursor:"pointer",width:"100%"}}>
                                                    <Nav.Item>
                                                        <Nav.Link disabled={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending' ||  data.getOneUser.status === null) ? true : false} className={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending') ? 'user-not-click' : ''} eventKey="second" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>Edit Profile</Nav.Link>
                                                    </Nav.Item>
                                                    </div>
                                                   
                                                    <div style={{cursor:"pointer",width:"100%"}}>
                                                    <Nav.Item>
                                                        <Nav.Link disabled={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending' ||  data.getOneUser.status === null) ? true : false} className={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending') ? 'user-not-click' : ''} eventKey="third" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}>All Request {
                                                            dataRequest && dataRequest.getFriendRequests.length === 0 ?
                                                           <></>
                                                            :
                                                            <Badge bg="danger">{dataRequest && dataRequest.getFriendRequests.length}</Badge>
                                                            }  </Nav.Link>
                                                    </Nav.Item>
                                                    </div>
                                                    <div style={{cursor:"pointer",width:"100%"}}>
                                                    <Nav.Item>
                                                        <Nav.Link disabled={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending'  || data.getOneUser.status === null ) ? true : false} className={data && (data.getOneUser.status === 'created' || data.getOneUser.status === 'pending') ? 'user-not-click' : ''} eventKey="Notification" style={{ color: '#8D448B', fontFamily: 'DM Sans', fontSize: '15px', cursor: 'pointer' }}> Notification <Badge bg="danger">{connectionData && connectionData.getConnectionFor.length}</Badge></Nav.Link>
                                                    </Nav.Item>
                                                    </div>
                                                </Nav>
                                                </div>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={9}>
                                        <Card className="rightCard">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="first" >
                                                    <SelectedProfile />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="second" >
                                                    <EditProfile />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="third">
                                           <AllRequest/>
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="MakeProfile">
                                                    <MakeProfile />
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="Notification">
                                                    <Notification />
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </Card>

                                    </Col>
                                </Row>
                            </Tab.Container>
                        </Container>
                    </>
            }
        </>
    )
}
