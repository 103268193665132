import React, { useEffect, useState } from 'react';
import { Card, Button, Row, Col, Form, Modal, Container } from 'react-bootstrap';
import ProfileModal from './ProfileModel.js';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ALL_USER, GET_SINGLE_USER_ALL_CONNECTIONS, GET_USER_ALL, RANDOM_MATCHES } from '../../user/grqphqloperation/query.js';
import UserConnectionModel from './UserConnectionsModel.js';
import UserDefaultProfile from '../../../Images/user-profile.png';
import { FaRegTrashAlt } from "react-icons/fa";
import { MUTATION_USER_DELETE } from '../../user/grqphqloperation/mutation.js';

const ProfileList = ({ profiles }) => {
    console.log("profiles", profiles)
    const [showModal, setShowModal] = useState(false);
    const [connectionModel, setConnectionModel] = useState(false);
    const [selectedProfile, setSelectedProfile] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [userId, setUserId] = useState(null);
    const [newConnectionView, setNewConnectionView] = useState(null);

    const { data } = useQuery(GET_SINGLE_USER_ALL_CONNECTIONS, {
        variables: {
            "connId": `${userId}`
        }
    })

    useEffect(() => {
        if (data) {
            setNewConnectionView(data.getSingleUserAllConnection);
        }
    }, [data])

    const handleViewProfile = (profile) => {
        setSelectedProfile(profile);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleProfileConnectionCloseModal = () => {
        setConnectionModel(false);
    };

    // Function to filter profiles based on search term
    const filteredProfiles = profiles.filter(profile => {
        const lowerCaseSearchTerm = searchTerm.toLowerCase();

        return (
            (profile.firstName && profile.firstName.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (profile.lastName && profile.lastName.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (profile.email && profile.email.toLowerCase().includes(lowerCaseSearchTerm)) ||
            (profile.mobile && profile.mobile.toLowerCase().includes(lowerCaseSearchTerm))
        );
    });

    const handleViewConnection = (connId, profile) => {
        setUserId(connId);
        setSelectedProfile(profile);
        setConnectionModel(true);
    }

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [getuserId, setGetUserId] = useState('')

    const [userDetele] = useMutation(MUTATION_USER_DELETE, {
        refetchQueries: [
            GET_ALL_USER,
            "getUsers",
            RANDOM_MATCHES,
            "randomUsers",
            GET_USER_ALL,
            "getAllUsers"

        ]
    })
    const handleViewDelete = (id) => {
        handleShow()
        setGetUserId(id)
    }

    const handleNo = () => {
        handleClose()
    }

    const handleYes = () => {
        userDetele({
            variables: {
                "userId": `${getuserId}`
            }
        }).then(() => {
            handleClose()
        })
    }



    return (
        <div>
            <Form className="mb-3">
                <Form.Group controlId="search">
                    <Form.Control
                        type="text"
                        placeholder="Search by name, email, or phone"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </Form.Group>
            </Form>
            {filteredProfiles.reverse().map((profile, index) => (
                <Card key={index} className="mb-3 bg-light" style={{ borderRadius: '10px', boxShadow: '2px 5px 8px #A1A4AA', ...(profile.status === 'block' ? { border: '2px solid #EC4C5C' } : {}) }}>
                    <Card.Body>
                        <Row className="align-items-center">
                            <Col xs={12} md={9} className="d-flex align-items-center">
                                {
                                    profile.profile ?
                                        (<img
                                            style={{ maxWidth: 60, maxHeight: 'auto', objectFit: 'contain' }}
                                            src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${profile.profile}`}
                                            alt="Profile"
                                            className="img-fluid rounded-circle"
                                        />) :
                                        (<img
                                            style={{ maxWidth: 60, maxHeight: 'auto', objectFit: 'contain' }}
                                            src={UserDefaultProfile}
                                            alt="Profile"
                                            className="img-fluid rounded-circle"
                                        />)
                                }
                                <div className='ms-3'>
                                    <h6>{profile.firstName ? profile.firstName : profile.mobile} {profile.lastName ? profile.lastName : ''}</h6>
                                    <p>{profile.email}</p>
                                </div>
                            </Col>
                            <Col xs={12} md={3} className="text-center mt-3 mt-md-0">
                                <div className="d-flex justify-content-center justify-content-md-end" style={{ gap: '10px' }}>
                                    <Button onClick={() => handleViewProfile(profile)} variant="primary" className="mb-2 me-md-2">View</Button>
                                    <Button onClick={() => handleViewConnection(profile.id, profile)} variant="success" className="mb-2 bg-success m-0">Connection</Button>
                                    <Button onClick={() => handleViewDelete(profile.id)} variant="danger" className="mb-2 bg-danger m-0"><FaRegTrashAlt /></Button>
                                </div>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>

            ))}

            {selectedProfile && (
                <ProfileModal show={showModal} handleClose={handleCloseModal} profile={selectedProfile} />
            )}

            {
                newConnectionView && (
                    <UserConnectionModel show={connectionModel} handleClose={handleProfileConnectionCloseModal} profile={selectedProfile} connection={newConnectionView} />
                )
            }

            <Modal show={show} onHide={handleClose}>
                <h5 style={{ fontFamily: "Dm sans", textAlign: "center" }}>Are You Sure ?</h5>
                <Container style={{ marginBottom: 20 }}>
                    <Row>
                        <Col md={3}>   </Col>
                        <Col md={6}>
                            <Row>
                                <Col><Button variant="primary" className='mx-auto d-block' onClick={() => handleYes()}>Yes</Button></Col>
                                <Col><Button variant="secondary" className='mx-auto d-block' onClick={() => handleNo()}>No</Button></Col>
                            </Row>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                </Container>
            </Modal>

        </div>
    );
};

export default ProfileList;
