import React, { useState } from "react";
import AWS from "aws-sdk";
import Resizer from "react-image-file-resizer";
import dropzone from "react-dropzone-uploader"
import swal from "sweetalert";

import uniqid from "uniqid";
import { Dropdown } from "react-bootstrap";
import Dropzone from "react-dropzone-uploader";
import { hasClientExports } from "@apollo/client/utilities";
import 'react-dropzone-uploader/dist/styles.css'

const ID = "AKIA6GB4RFKTDTDA6E2O";
const SECRET = "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";
const BUCKET_NAME = "byaahlagan-profile-image";

const s3 = new AWS.S3({
  accessKeyId: ID,
  secretAccessKey: SECRET,
});
let imageKey = []
function Pic() {
  
  const [selectedImage, setSelectedImage] = useState();

  const handleFileInput =(e) =>{
    
    setSelectedImage(e.target.files[0]);
  }
  const getUploadParams =({meta}) =>{

  return{url:'https://httpbin.org/post'}

  }
const handleChangeStatus =({meta ,file},status)  =>{


}

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        500,
        500,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

    const changeImage = async (files) => {
      try {
  
        files.map(async (fileData) => {
          const uniqueId = uniqid()
          const file = fileData.file;
          const image = await resizeFile(file);
          var byteString = atob(image.split(',')[1]);
          var mimeString = image.split(',')[0].split(':')[1].split(';')[0]
          var ab = new ArrayBuffer(byteString.length);
          var ia = new Uint8Array(ab);
          for (var i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          var blob = new Blob([ab], { type: mimeString });
          var uniqueFileName = uniqueId + "-" + uniqueId + ".jpeg";
  
          imageKey.push(uniqueFileName)
          const params = {
            Bucket: BUCKET_NAME,
            Key: uniqueId + "-" + uniqueId + ".jpeg",
            Body: blob,
          };
          s3.upload(params, function (err, data) {
            if (err) {
              swal({ title: "Error!", text: "Error in uploading images!", icon: "error" });
              throw err;
            }
            else {
              alert('uploaded')
            }
  
          });
        })
      }
      catch (err) {
        console.log(err);
      }
    }


  return (
    <div>

<Dropzone
 getUploadParams={getUploadParams}
 getChangeStatus={handleChangeStatus}
 onSubmit={changeImage}
accept="image/*"
styles={{dropzone:{minHeight:200,maxHeight:250}}}
inputContent="Drop or Change Images"
/>
    </div>
  );
}

export default Pic;
