import React, { useEffect,useState } from 'react';
import { Button, Card, Col, Container, Image, Row,Form } from 'react-bootstrap';
import Navigation from './Navigation';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery,useMutation } from '@apollo/client';
import { GET_SEARCH_USER_FILTER, GET_USER ,GET_SINGLE_USER_ALL_CONNECTIONS,USER_NOTIFICATION, GET_FRIEND_REQUEST_BY_ID_USERID} from './user/grqphqloperation/query';
import { MUTATION_EMAIL, SEND_REQUEST, USER_CONNECTION } from "./user/grqphqloperation/mutation";
import { FiPlusCircle, FiUserPlus } from "react-icons/fi";
import { FiChevronUp, FiChevronDown  } from "react-icons/fi";
import { FaSadTear } from 'react-icons/fa';
import Profile from './ProfileCard';
import ProfileCard from './ProfileCard';
import LoadingBar from './LoadingBar';
import Footer1 from './Footer1';
import logo from "../Images/logo.png"
import swal from 'sweetalert';


export default function SearchMatches() {
    const userId = localStorage.getItem("userId");
    const token = localStorage.getItem("token");
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(()=>{
        window.scrollTo(0, 0);
      },[]);

    const { data: searchData, loading: filterLoading, error } = useQuery(GET_SEARCH_USER_FILTER, {
        variables: {
            "age": `${location.state.age}`,
            "gender": `${location.state.gender}`,
            "caste": `${location.state.caste}`,
            "logid": `${userId || ''}`
        }
    });

    const { data: userData } = useQuery(GET_USER, {
        variables: {
            "id": `${userId}`
        }
    });

    console.log("userData",userData)


    function handleNavigate() {
        navigate('/');
    }

  

  const handleProfileClick = (profile) => {
    if (userId && token) {
      navigate(`/userProfile/${profile}`);
    } else {
      navigate('/userLogin');
    }
  };

  
  const [foundValue, setFoundValue] = useState();

  useEffect(() => {
    if (searchData) {
      setFoundValue(searchData.searchUser);
    }
  }, [searchData]);

   const[age,setAge] =useState('')
   const[height,setHeight] =useState('')
   const[job,setJob] =useState('')


  const filterByAge = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = searchData.searchUser.filter((data) => {
        return data.age.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(searchData.searchUser);
    }
    setAge(keyword);
  };

  const filterByheight = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = searchData.searchUser.filter((data) => {
        return data.height.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(searchData.searchUser);
    }
    setHeight(keyword);
  };

  const filterByjob = (e) => {
    setAge('');
    setHeight('')
    setJob('')
    const keyword = e.target.value;

    if (keyword !== '') {
      const results = searchData.searchUser.filter((data) => {
        return data.occupation.toLowerCase().startsWith(keyword.toLowerCase());;
      });
      setFoundValue(results);
    } else {
      setFoundValue(searchData.searchUser);
    }
    setJob(keyword);
  };

 
  const[dropDown,setDropDown] = useState(false)

  const [connUser, setConnUser] = useState('');
  const { data: newData } = useQuery(GET_USER, {
    variables: {
      id: connUser
    }
  });


  const [createConnection] = useMutation(USER_CONNECTION, {
    refetchQueries: [
      USER_NOTIFICATION,
      'getConnectionFor'
    ]
  })

   const[createMail] = useMutation(MUTATION_EMAIL)

  useEffect(() => {
    if (newData && userData) {
      for(let i=1;i<=5;i++){
        if(i===3){
          createConnectionA(newData.getOneUser,userData.getOneUser);
        }
      }
    }
  }, [newData])
 
  const{data,loading:loadingFRU} =useQuery(GET_FRIEND_REQUEST_BY_ID_USERID,{
    variables:{
      "userId": `${userId}`
    }
  })


  console.log('data',data)
 

  const createConnectionA = (user,logUser) => {
    createMail({
      variables:{
        "email": `${user && user.email}`,
        "subject": "Byaahlagan Connection",
        "text": "Byaahlagan Connection",
        "userName": `${logUser.firstName}`,
        "img": `${`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${logUser.profile}`}`,
        "logo": `${logo}`

      }
    })
    createConnection({
      variables: {
        userConnection: {
          connectionFor: `${user.id}`,
          connectionForFname: `${user && user.firstName}`,
          connectionForLname: `${user && user.lastName}`,
          connectionForAge: `${user && user.age}`,
          connectionForCaste: `${user && user.caste}`,
          connectionForContact: `${user && user.mobile}`,
          connectionFrom: `${logUser.id}`,
          connectionFromFname: `${logUser.firstName}`,
          connectionFromLname: `${logUser.lastName}`,
          connectionFromCaste: `${logUser.caste}`,
          connectionFromAge: `${logUser.age}`,
          connectionFromContact: `${logUser.mobile}`,
        }

      }, refetchQueries: [
        USER_NOTIFICATION,
        'getConnectionFor'
      ]
    })
    

      swal({
        title: "Successful",
        text: "Connection Successfully!!!",
        icon: "success",
      });
  

  
  };

 const[sendFriendRequest,loading] =  useMutation(SEND_REQUEST)

  const handleConnectUser = (recipientId) => {
    if (userId && token) {
      let { getOneUser } = userData;
      if (getOneUser) {
        if (getOneUser.subscriptionPlanStatus === 'active') {
          setConnUser(userId);
          sendFriendRequest({
            variables:{
              "requesterId": `${userId}`,
              "recipientId": `${recipientId}`
            }
          })
        } else {
          navigate('/Subcriptionplan');
        }
      }
    } else {
      navigate('/userLogin');
    }
  };


  const{data:connectionData,loading:connectionLoading} =  useQuery(GET_SINGLE_USER_ALL_CONNECTIONS,{
   variables:{
    "connId":`${userId}`
   } 
  })


  console.log("connectionData",connectionData)

  const handleAlert =()=>{
    swal({
      title: "Warning",
      text: "You don`t have any subscription plan",
      icon: "warning",
    });

  }






    return (
        <>
        <LoadingBar isLoading={filterLoading} />
       
            {/* <Container fluid style={{ marginTop: 100,backgroundColor:'#EC4C5A',borderBottom:'3px dotted #D6F2FC' }}>
                <Row>
                    {
                        searchData && searchData.searchUser.length === 0 ? (
                            <Row style={{ marginTop: "20px" }}>
                                <Col>
                                    <FaSadTear style={{ width: "100px", height: "100px",color:'#2C3E50' }} className="mx-auto d-block" />
                                    <h3 style={{ textAlign: "center", fontFamily: "DM sans" }}>Don't matched any profile!!!</h3>
                                    <h6 style={{ textAlign: "center", fontFamily: "DM sans" }}>Please Search Other Profile.</h6>
                                    <Button className="mx-auto d-block" style={{ marginBottom: "160px" }} onClick={handleNavigate}>Go Back</Button>
                                </Col>
                            </Row>
                        ) : (
                            <>
                            <div className="cont">
                                {
                                    searchData && searchData.searchUser.map((user,index) => (
                                        <Col key={index} xs={12} md={4}>
                                            <ProfileCard userA={user} />
                                        </Col>
                                    ))
                                }
                            </div>
                            </>
                        )
                    }
                </Row>
            </Container> */}
      <Navigation />
      <br></br> <br></br> <br></br>
      <br></br>
      <br></br>
      <Container>

        {

             searchData && searchData.searchUser.length === 0 ?
             <Row style={{ marginTop: "20px" }}>
             <Col>
                 <FaSadTear style={{ width: "100px", height: "100px",color:'#2C3E50' }} className="mx-auto d-block" />
                 <h3 style={{ textAlign: "center", fontFamily: "DM sans" }}>Don't matched any profile!!!</h3>
                 <h6 style={{ textAlign: "center", fontFamily: "DM sans" }}>Please Search Other Profile.</h6>
                 <Button className="mx-auto d-block" style={{ marginBottom: "160px" }} onClick={handleNavigate}>Go Back</Button>
             </Col>
         </Row>

             :
             <>
             
        <Row>
          <Col md={3}>
            <Card style={{ height: "100%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Card style={{ width: "95%", marginTop:9,display:"flex", justifyContent:"space-between" }}>
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                  <p style={{ marginLeft: 10, marginTop: 10 ,}}>Filter </p>
                  {
                        dropDown === true ?
                        <FiChevronUp size={25} style={{marginRight:15,marginTop:12,color:"#000"}} onClick={()=>setDropDown(!dropDown)}/>
                        :
                        <FiChevronDown size={25} style={{marginRight:15,marginTop:12,color:"#000"}} onClick={()=>setDropDown(!dropDown)}/>


                  }
                 
                  </div>
                </Card>

              </div>

              {
                   dropDown === true ?
                   <>
                   <div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
                   <Card style={{ width: "95%", marginTop: 9 }}>
                     <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
                     Age
                   </p>
                   <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
     
                   <Form.Control
                           aria-label="Default"
                           aria-describedby="inputGroup-sizing-default"
                           value={age}
                           onChange={filterByAge}
                           
                           style={{ fontSize: 12,marginBottom:10,width:"90% "}}
                         />
                         </div>
                     </Card>
                     </div>

<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
<Card style={{ width: "95%", marginTop: 9 }}>
  <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
  Height
</p>
<div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>

<Form.Control
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={height}
        onChange={filterByheight}
        style={{ fontSize: 12,marginBottom:10,width:"90% " }}
      />
      </div>
  </Card>
  </div>

<div style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
<Card style={{ width: "95%", marginTop: 9 }}>
  <p style={{ marginLeft: 12, fontSize: 12, fontWeight: "500" }}>
  Occupation
</p>
<div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>


<Form.Control
        aria-label="Default"
        aria-describedby="inputGroup-sizing-default"
        value={job}
        onChange={filterByjob}
        style={{ fontSize: 12,marginBottom:10,width:"90% " }}
      />
      </div>
  </Card>
  </div>
  </>

                   :
                   <></>


              }

           
           

          

          


      

            </Card>
          </Col>
          <Col md={9}>
            <div className="window-screen">
              {foundValue &&
                foundValue.slice()
                .reverse()
                .map((item) => {
                  return (
                    <>
                  
                      
                           <div className="match-card">
                        <div className="match-card-row">
                          <div className="match-card-col-1">
                            <img
                              src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.profile}`}
                              className="img-match"
                            />
                            {item.subscriptionPlanName === null ? (
                              <></>
                            ) : (
                              <span
                                className="stick-match"
                                data-before-content={`${item.subscriptionPlanName}`}
                              ></span>
                            )}
                          </div>
                          <div className="match-card-col-2">
                            <div className="match-des">
                              <h6>{item.firstName} {item.lastName}</h6>
                              <Row>
                                <Col>
                                  <p>Online Now</p>
                                </Col>
                                <Col>
                                {
                                 item.gender === "Male" ? 
                                 <p>You & Him</p>
                                 :
                                 <p>You & Her</p>
                                }
                                 
                                </Col>
                                <Col>
                                  <p>Astro</p>
                                </Col>
                              </Row>
                              <hr></hr>

                              <Row>
                                <Col>
                                  <h6>
                                    {item.age} yrs, {item.height}
                                  </h6>
                                  <h6>
                                    {item.religion} , {item.caste}
                                  </h6>
                                  <h6>Hindi </h6>
                                </Col>
                                <Col>
                                  {item.meritalStatus === null ? (
                                    <h6>Never Married</h6>
                                  ) : (
                                    <h6>{item.meritalStatus}</h6>
                                  )}

                                  <h6> {item.city} ,{item.state}</h6>
                                  <h6>{item.occupation}</h6>
                                </Col>
                              </Row>
                            </div>
                          </div>
                          <div className="match-card-col-3">
                            <h6
                              style={{
                                fontStyle: "italic",
                                fontWeight: "500",
                                textAlign: "center",
                                marginTop: 50,
                              }}
                            >
                              Like this Profile
                            </h6>
                            {
                               userData && userData.getOneUser.subscriptionPlanStatus === "pending" ?
                               <div className="match-card-connection">
                               <div className="match-card-connection-box"onClick={() => handleAlert()}>
                                 <FiUserPlus size={25} color="#fff" />
                               </div>
                             </div>
                               :
                               <>
                                 {
                                 userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?
                                 <div className="match-card-connection">
                                 <div className="match-card-connection-box" style={{backgroundColor:"#777"}}>
                                   <FiUserPlus size={25} color="#fff" />
                                
                                 </div>
                               </div>
                                  :
                                  userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?
                                  <div className="match-card-connection">
                                  <div className="match-card-connection-box">
                                    <FiUserPlus size={25} color="#fff" />
                                  </div>
                                </div>
                                  :
                                  <div className="match-card-connection">
                                  <div className="match-card-connection-box"onClick={() => handleConnectUser(item.id)}>
                                    <FiUserPlus size={25} color="#fff" />
                                  </div>
                                </div>
                                
                                 }
                               </>
                            }
                            
                            <h6 style={{ textAlign: "center" }}>Connect Now</h6>

                            {
                                userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id )  ?
                                <h6 style={{ textAlign: "center",fontSize:13,color:"blue" }}  onClick={()=>handleProfileClick(item.id)}>More Detail</h6>
                                :
                                <></>
                               

                            }
                           
                            
                          </div>
                        </div>
                      </div>

                         
                       

                     

                    
                     
                     
                    </>
                  );
                })}
            </div>

            <div className="mobile-screen">
              <div className="mobile-row">{
                
                
                
                }

              {
                   foundValue && foundValue.map(item=>{
                    return(
                      <div className="mobile-match-card" style={{marginTop:10}}  onClick={()=>{ userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?handleProfileClick(item.id) : alert("Not See More Detail")}}>
                      <div className="mobile-match-card-box">
                        <img
                          src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.profile}`}
                          className="mobile-match-img"
                        />
      
                        <div className="overlay-mobile">
                          <div className="mobile-match-desc">
                            <div className="mobile-match-desc-row">
                              <h6>{item.firstName} {item.lastName}</h6>
                              <p
                                style={{ fontSize: 12, marginTop: 10, color: "#fff" }}
                              >
                                online
                              </p>
                              {
                                item.gender === "Male" ?
                                <p
                                style={{ fontSize: 12, marginTop: 10, color: "#fff" }}
                              >
                                You & Him
                              </p>
                                :
                                <p
                                style={{ fontSize: 12, marginTop: 10, color: "#fff" }}
                              >
                                You & Her
                              </p>

                              }
                            
                            </div>
                            <div
                              className="mobile-match-desc-row"
                              style={{ marginTop: -10 }}
                            >
                              <h6 style={{ fontSize: 10 }}>{item.age} yrs,{item.height}</h6>
                              <h6 style={{ fontSize: 10 }}>{item.religion}, {item.caste}</h6>
                              <h6 style={{ fontSize: 10 }}>Hindi </h6>
                            </div>
                            <div
                              className="mobile-match-desc-row"
                              style={{ marginTop: -10 }}
                            >

                              {
                                item.meritalStatus === null ? 
                                <h6 style={{ fontSize: 10 }}>Never Married</h6>
                                :
                                <h6 style={{ fontSize: 10 }}>{item.meritalStatus}</h6>

                             }
                            
                              <h6 style={{ fontSize: 10 }}>
                                {item.city} , {item.state}
                              </h6>
                              <h6 style={{ fontSize: 10 }}>{item.occupation} </h6>
                            </div>
      
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <h6
                                style={{
                                  fontStyle: "italic",
                                  fontWeight: "500",
                                  textAlign: "center",
                                  marginLeft: 10,
                                }}
                              >
                                Like this Profile
                              </h6>
                              {

                                 userData && userData.getOneUser.subscriptionPlanStatus === "pending" ?
                                 <div className="match-card-connection">
                                 <div className="match-card-connection-box"onClick={() => handleAlert()}>
                                 <FiUserPlus size={25} color="#fff" />
                                  </div>
                                      </div>
                                      :
                                      <>
                                       {
                                 userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id ) ?
                                  <div
                                  style={{
                                    width: 45,
                                    height: 45,
                                    background: "#777",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    marginRight: 10,
                                    borderRadius: 100,
                                  }}
                                  onClick={() => handleConnectUser(item.id)}
                                >
                                  <FiUserPlus size={20} color="#fff" />
                                </div>
                                  :
                                  userData && userData.getOneUser.friends.some((obj)=>obj.id === item.id )  ?
<div
                              style={{
                                width: 45,
                                height: 45,
                                background: "#EC4C5C",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 10,
                                borderRadius: 100,
                              }}
                             
                            >
                              <FiUserPlus size={20} color="#fff" />
                            </div>
                                  :
                              
<div
                              style={{
                                width: 45,
                                height: 45,
                                background: "#EC4C5C",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                marginRight: 10,
                                borderRadius: 100,
                              }}
                              onClick={() => handleConnectUser(item.id)}
                            >
                              <FiUserPlus size={20} color="#fff" />
                            </div>

                              }
      
                                      
                                      </>




                              }


                             
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    )
                   })


              }
             </div>
            </div>
          </Col>
        </Row>
             
             </>



        }



      </Container>
            <Footer1/>
        </>
    );
}
