import React, { useEffect, useState } from "react";
import "../ComponentsCss/MatchingProfile.css";
import { Button, Card, Container, Image } from "react-bootstrap";
import { FiUserPlus } from "react-icons/fi";
import Navigation from "./Navigation";
import { GET_USER, RANDOM_MATCHES, USER_NOTIFICATION } from "./user/grqphqloperation/query";
import { useNavigate } from "react-router-dom";
import { MUTATION_EMAIL, USER_CONNECTION } from "./user/grqphqloperation/mutation";
import swal from 'sweetalert';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import logo from "../Images/logo.png"


export default function MatchingProfile() {


  let userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");
  let navigate = useNavigate();

  const handleProfileClick = (profile) => {
    if (userId && token) {
      navigate(`/user/${profile}`);
    } else {
      navigate('/userLogin');
    }
  };




  const { data, loading, error, refetch } = useQuery(RANDOM_MATCHES);

  useEffect(() => {
    refetch();
    window.scrollTo(0, 0);
  }, []);

  console.log("data", data)




  const [connUser, setConnUser] = useState('');
  const { data: newData } = useQuery(GET_USER, {
    variables: {
      id: connUser
    }
  });

  const { data: userData, loading: userDataloading, error: userError } = useQuery(GET_USER, {
    variables: {
      "id": `${userId}`
    }
  });

  const [createConnection] = useMutation(USER_CONNECTION, {
    refetchQueries: [
      USER_NOTIFICATION,
      'getConnectionFor'
    ]
  })

  const [createMail] = useMutation(MUTATION_EMAIL)

  useEffect(() => {
    if (newData && userData) {
      for (let i = 1; i <= 5; i++) {
        if (i === 3) {
          createConnectionA(newData.getOneUser, userData.getOneUser);
        }
      }
    }
  }, [newData])

  const createConnectionA = (user, logUser) => {
    createMail({
      variables: {
        "email": `${user && user.email}`,
        "subject": "Byaahlagan Connection",
        "text": "Byaahlagan Connection",
        "userName": `${logUser.firstName}`,
        "img": `${`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${logUser.profile}`}`,
        "logo": `${logo}`

      }
    })
    createConnection({
      variables: {
        userConnection: {
          connectionFor: `${user.id}`,
          connectionForFname: `${user && user.firstName}`,
          connectionForLname: `${user && user.lastName}`,
          connectionForAge: `${user && user.age}`,
          connectionForCaste: `${user && user.caste}`,
          connectionForContact: `${user && user.mobile}`,
          connectionFrom: `${logUser.id}`,
          connectionFromFname: `${logUser.firstName}`,
          connectionFromLname: `${logUser.lastName}`,
          connectionFromCaste: `${logUser.caste}`,
          connectionFromAge: `${logUser.age}`,
          connectionFromContact: `${logUser.mobile}`,
        }

      }, refetchQueries: [
        USER_NOTIFICATION,
        'getConnectionFor'
      ]
    })


    swal({
      title: "Successful",
      text: "Connection Successfully!!!",
      icon: "success",
    });



  };

  const handleConnectUser = (userId) => {
    if (userId && token) {
      let { getOneUser } = userData;
      if (getOneUser) {
        if (getOneUser.subscriptionPlanStatus === 'active') {
          setConnUser(userId);
        } else {
          navigate('/Subcriptionplan');
        }
      }
    } else {
      navigate('/userLogin');
    }
  };





  return (
    <>
      <Navigation />

      <div className="matchingBG" style={{ marginTop: 100 }}>
        <div className="matchBox">
          <Card className="boxProfile">
            <h2 style={{ textAlign: "center", marginTop: 20 }}>
              Let's get started by connecting with few of your Matches
            </h2>
            <br></br>
            <div className="grid-container">
              {
                data && data.randomUsers.map((user, index) => {
                  return (
                    <div className="grid-center">
                      <div className="grid-item" onClick={() => handleProfileClick(user.id)}>
                        <div className="grid-item-img">
                          <Image
                            src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${user.profile}`}
                            style={{ width: "90%", margin: 5, height: "90%" }} className="imgbox-item"
                          />
                        </div>
                        <div className="grid-item-des">
                          <div className="grid-item-des-box">
                            <h6>{user.firstName} {user.lastName}</h6>
                            <p>{user.age} yrs {user.height}</p>
                            <p style={{ marginTop: -10 }}>{user.occupation}</p>
                            <p style={{ marginTop: -10 }}>{user.caste}</p>
                            <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                              <p style={{ marginTop: -10, fontSize: 9 }} >{user.city},{user.country}</p>
                              {
                                user.isConnected == 'true' ?
                                  <div style={{ marginLeft: 10, marginTop: -15, width: 30, height: 30, background: "#777", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 5 }} >
                                    <FiUserPlus color="#fff" />
                                  </div>
                                  :
                                  <div style={{ marginLeft: 10, marginTop: -15, width: 30, height: 30, background: "#EC4C5C", display: "flex", alignItems: "center", justifyContent: "center", borderRadius: 5 }} onClick={() => handleConnectUser(user.id)}>
                                    <FiUserPlus color="#fff" />
                                  </div>
                              }
                            </div>
                          </div>
                        </div>


                        <div className="grid-item-check">

                        </div>
                      </div>
                    </div>

                  )

                })

              }










            </div>
          </Card>
        </div>
      </div>
    </>
  );
}
