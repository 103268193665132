import React, { useState } from 'react'
import { Container, Card, Row, Col, Image, Form, Button, Spinner } from 'react-bootstrap'
import Navigation from '../Navigation'
import Shadi from "../../Images/shadi2.png"
import { useMutation } from '@apollo/client';
import { MUTATION_ADMIN_LOGIN } from '../user/grqphqloperation/mutation';
import { useNavigate } from 'react-router-dom';
import swal from 'sweetalert';
import "../AdminComponentCss/AdminLogin.css"

export default function Adminlogin() {

  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();
  const [userName, setUserName] = useState("")
  const [password, setPassword] = useState("")

  const [adminLogin, { data, loading }] = useMutation(MUTATION_ADMIN_LOGIN, {
    onError: (e) => {
      swal({ title: "Error!!!", text: "Username & Password Not Match", icon: "error" });
    },
  })

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      setValidated(true);
    } else {
      event.preventDefault();
      setValidated(false);
      adminLogin({
        variables: {
          "adminInput": {
            "userName": `${userName}`,
            "password": `${password}`
          }
        }
      })
    }
  };



  if (data) {
    localStorage.setItem("adminToken", data && data.adminLogin.adminToken);
    localStorage.setItem("adminId", data && data.adminLogin.adminid);
    navigate("/AdminDashboard");
    swal({
      title: 'Successfull!!!',
      text: 'Login Successfully',
      icon: 'success',
    });
  }


if(localStorage.getItem('adminToken')){
  navigate("/AdminDashboard");
}



  return (
    <>
      <Navigation />
      <br></br><br></br><br></br><br></br>
      <Container className='mt-md-5'>
        <Card style={{ borderRadius: 20,background:'#EC4C5A',height:"70vh"}}>
          <Row style={{height:"70vh"}}>
            <Col md={6} style={{display:"flex",alignItems:"center",justifyContent:"center"}}>
              <Image src={Shadi}  className="mx-auto d-block" />
            </Col>
            <Col md={6}  style={{display:"flex",alignItems:"center",justifyContent:"center",}}>
              <Card  className='adminlogin-card'>
              <h2 style={{ fontFamily: "Dm sans", fontWeight: "bold", textAlign: "center", marginTop: 100 }}>Admin login </h2>
              <Container>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <br></br>
                <Row className="mb-3">
                  
                  <Form.Group as={Col} md="6" controlId="validationCustom01" className='mx-auto d-block'>
                    <Form.Control
                      required
                      type="text"
                      style={{ fontFamily: "Dm sans", borderRadius: 10 }}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter Username"
                      value={userName}
                    />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="6" controlId="validationCustom01" className='mx-auto d-block'>
                    <Form.Control
                      required
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      style={{ fontFamily: "Dm sans", borderRadius: 10 }}
                      placeholder="Enter Password"
                      value={password}
                    />
                  </Form.Group>
                </Row>
                {
                  loading ?
                    <Spinner animation="border" className="mx-auto d-block" />
                    :
                    <Button type="submit" style={{ fontFamily: "Dm sans",background:"#EC4C5A",border:"1px solid #EC4C5A" }} className="mx-auto d-block">Login Now</Button>
                }
              </Form>
              </Container>
              </Card>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  )
}
