
import React from 'react'
import '../AdminComponentCss/Allusers.css'
import { useQuery } from '@apollo/client';
import { GET_USER_ALL } from '../user/grqphqloperation/query';
import ProfileList from '../admin/partisian/ProfileList.js';
import AdminPageLayout from '../admin/AdmnPageLayout.js';
import LoadingBar from '../LoadingBar.jsx';
export default function AdminPanel() {

    const { data, loading } = useQuery(GET_USER_ALL)

    return (
        <>
            <LoadingBar isLoading={loading} />
            <AdminPageLayout>
                {
                    data && <ProfileList profiles={data.getAllUsers ? data.getAllUsers : []} />
                }
            </AdminPageLayout>
        </>
    )
}
