import React from "react";
import {
  Nav,
  Tab,
  Row,
  Col,
  Container,
  Card,
  Image,
  Button,
} from "react-bootstrap";
import "./UserCss/SelectProfile.css";

import { useNavigate, useParams } from "react-router-dom";

import { useMutation, useQuery } from "@apollo/client";
import { GET_FRIEND_BY_ID_USERID } from "./user/grqphqloperation/query";
import { ACCEPT_REQUEST, REJECT_REQUEST } from "./user/grqphqloperation/mutation";


export default function AllRequest() {

    const userId = localStorage.getItem("userId");


    const navigate = useNavigate()
    // const { data: connectionDataFrom } = useQuery(CONNECTION_FROM_PROFILE, {
    //   variables: {
    //     userId: `${userId}`,
    //   }
    // })
  
    function handleClickView(id) {
      navigate("/userProfile/"+ id);
    }
  

  
    const{data,loading} = useQuery(GET_FRIEND_BY_ID_USERID,{
      variables:{
        "userId": `${userId}`
      }
    })
  
    console.log("data use",data)

  const[acceptFriendRequest,{loading:loadingAccept}] =  useMutation(ACCEPT_REQUEST,{
    refetchQueries:[
      GET_FRIEND_BY_ID_USERID,
      "getFriendRequests" 
      
    ]
  })

   const[rejectFriendRequest,{loading:loadingReject}] = useMutation(REJECT_REQUEST,{
    refetchQueries:[
      GET_FRIEND_BY_ID_USERID ,
      "getFriendRequests" 
    ]
   })
  



    const handleClickAccept =(id)=>{
      acceptFriendRequest({
        variables:{
          "requestId": `${id}`
        }
      })
    }

    const handleClickVCancel =(id)=>{
      rejectFriendRequest({
        variables:{
          "requestId": `${id}`
        }
      })
    }
  
  return (
    <div>
      <Container>
        <Row style={{ marginTop: 20 }}>
          <Container>
            <h3 style={{color:'#EC4C59'}}>All Request </h3>
            <hr />
            {
              data &&
              data.getFriendRequests.map(fdata => {
                return (
                  <Card style={{ marginBottom: 30 }} className="ProfilesCard">
                    <Card.Body>
                      <Row>
                        <Col md={3} style={{ marginTop: "10px" }}>
                          <Row>
                            <Col md={7}>
                              <p style={{ marginTop: 12, fontSize: "13px", fontFamily: "Dm sans",color:"red",fontWeight:"800",letterSpacing:0.5 }}>{fdata.requester.firstName} {fdata.requester.lastName}</p>
                            </Col>
                            <Col
                              md={3}
                              style={{ marginTop: 12, textAlign: "center" }}
                            >
                              <p style={{ fontSize: "12px", fontFamily: "Dm sans" }}></p>

                            </Col>
                          </Row>
                        </Col>
                        <Col md={3} style={{ marginTop: 20 }}>
                          <p style={{ fontSize: "13px", fontFamily: "Dm sans",color:"#000" }}>{fdata.requester.age}</p>
                        </Col>
                        <Col md={3} style={{ marginTop: 20 }}>
                          <p style={{ fontSize: "13px", fontFamily: "Dm sans",color:"#000" }}>{fdata.requester.mobile}</p>
                        </Col>
                        <Col md={3} style={{ marginTop: 12, fontSize: "12px", fontFamily: "Dm sans" }}>
                          <Button className="btn-1 btn2" style={{height:30,fontSize:12}} onClick={() => handleClickAccept(fdata.id)} >Accept</Button>
                          <br></br><br></br>
                          <Button className="btn-1 btn2" style={{height:30,fontSize:12,}} onClick={() => handleClickVCancel(fdata.id)} >Cancel</Button>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              })
            }

          </Container>
        </Row>
      </Container>
    </div>
  )
}
