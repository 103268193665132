import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Spinner, Image, Card } from 'react-bootstrap'
import { PAYMENT_INTRAGATION } from './grqphqloperation/query'
import { useLocation } from 'react-router-dom'
import { QRCode } from 'react-qrcode-logo';
import logo from "../../Images/fevicon.png"
import { v4 as uuidv4 } from 'uuid';


export default function PaymentUser() {

  const { data, loading } = useQuery(PAYMENT_INTRAGATION)
  const location = useLocation();



  const transactionId = "T-byaahlagan-" + uuidv4().toString(36).slice(-6)

  const [vpa, setVpa] = useState(data && data.getPayament[0].upiID);
  const [status, setStatus] = useState('');
  const [stop, setStop] = useState(false)

  if (stop === false && data) {
    setVpa(data && data.getPayament[0].upiID)
    setStop(true)
  }


  console.log("transactionId", transactionId)


  // const[getNumberupiId,setGetNumberUPiId] =useState(data && data.getPayament[0].upiID)
  // useEffect(()=>{
  //   setGetNumberUPiId(data && data.getPayament[0].upiID)
  // },[data])
  

  function replacePhoneNumbers(number){
   if( number != null ){
    var cuantos = number.length;
    var tele = [];
    for (var i = 0; i < cuantos; i++) {
        if(i < 7){
            tele[i] = number[i].replace(/^\d+$/, "*");
        }
        else{
            tele[i] = number[i];
        }
    }
    
    var full_phone = "";
    for (var i = 0; i < cuantos; i++) {
        full_phone += tele[i];
    }
    return full_phone;
   }else{
      return number
    }
  
   
}














  // const invokeUpiPayment = () => {
  //   const upiUrl = `upi://pay?pa=${data && data.getPayament[0].upiID}&pn=Byalagan&tid=${transactionId}&tr=${transactionId}&tn=${transactionId}&am=${location.state.price}&cu=INR&url=https://byaahlagan.com/`;
  //   window.location.href = upiUrl;
  // };

  // let paytran = "T-byaahlagan-920b2f"
  // const invokeUpiPaymentStatusCheck = () => {
  //   const upiUrl = `upi://paystatus?tn=${paytran}&tid=${paytran}`;
  //   window.location.href = upiUrl;
  //   console.log("upiUrl", upiUrl)
  // };



  // const checkStatus = async () => {
  //   try {
  //     const response = await fetch(`https://example.com/api/check-payment-status?transactionId=${paytran}`, {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json',
  //         // Add any other headers required by your API
  //       },
  //     });
  //     const data = await response.json();
  //     setStatus(data.status);
  //   } catch (error) {
  //     console.error('Error checking payment status:', error);
  //     // Handle error
  //   }
  // };

  // const constructUpiPaymentStatusCheckUrl = (transactionId, transactionNote) => {
  //   const upiUrl = `upi://paystatus?tn=${transactionNote}&tid=${transactionId}`;
  //   return upiUrl;
  // };


  return (
    <div>
      <Container style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Card style={{ width: "100%", marginTop: 20 }}>
          <h5 style={{ textAlign: "center", marginTop: 30 }}>Pay now</h5>
          {
            loading ?
              <Spinner animation="border" role="status" className='mx-auto d-block'>
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              :
              <>
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <QRCode
                    size={236}
                    style={{ height: "auto", maxWidth: 150, width: 150 }}
                    value={`upi://pay?pa=${data && data.getPayament[0].upiID}&pn=byaahlagan&tid=${transactionId}&tr=${transactionId}&tn=${transactionId}&am=${location.state.price}&cu=INR&url=https://byaahlagan.com/`}
                    viewBox={`0 0 256 256`}
                    logoImage={logo}
                    qrStyle='dots'
                  />
                </div>


                {/* <Image src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getPayament[0].imageQR}`} style={{width:230,height:200}} className='mx-auto d-block'/> */}

                <h6 style={{ textAlign: "center" }}>Amount : <span>{location.state.price}/-</span></h6>
                <h6 style={{ textAlign: "center" }}>{location.state.plan}</h6>

                <div style={{ marginTop: 10 }}>
                  <p style={{ textAlign: "center" }}>UPI ID : <span style={{ fontStyle: "italic" }}>{replacePhoneNumbers(data && data.getPayament[0].upiID)}</span></p>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginBottom: 10 }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      <div></div>
                      <a href={`upi://pay?pa=${data && data.getPayament[0].upiID}&pn=byaahlagan&tid=${transactionId}&tr=${transactionId}&tn=${transactionId}&am=${location.state.price}&cu=INR&url=https://byaahlagan.com/`} className='mx-auto d-block' style={{ textDecoration: "none", backgroundColor: "#ee5253", padding: 10, paddingLeft: 15, paddingRight: 15, borderRadius: 100, color: "#fff", fontSize: 15, fontWeight: "500", letterSpacing: 1, textAlign: 'center' }}>Click to Pay Now <span>{location.state.price}/-</span> <br />
                        (Open payment app)</a>

                    </div>

                  </div>
                </div>

                {/* <button onClick={invokeUpiPaymentStatusCheck}>Check Status</button> */}
                <ul>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>कदम 1: <span style={{ fontWeight: 'bold' }}>  भुगतान के लिए आप स्कैन या क्लिक करें और अपना भुगतान को पूरा करें</span></li>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>Step 1: <span style={{ fontWeight: 'bold' }}>Please send the amount to above UPI/Scanner</span></li>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>कदम 2: <span style={{ fontWeight: 'bold' }}>भुगतान के बाद, नीचे क्लिक करें और लेनदेन का स्क्रीनशॉट अपने संपर्क नंबर और ईमेल पते के साथ व्हाट्सएप पर भेजें और सुनिश्चित करें कि भुगतान स्क्रीनशॉट में यूटीआर नंबर और लेनदेन का समय शामिल हो।</span></li>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>Step 2: <span style={{ fontWeight: 'bold' }}>After payment, click below and send the transaction's screenshot on whatsapp with your contact number and email address and make sure the payment screenshot covers the UTR number and transaction time</span></li>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>कदम 3: <span style={{ fontWeight: 'bold' }}>कुछ मिनटों के बाद, टीम आपके लेनदेन की जांच करेगी और आपके खाते में सदस्यता जोड़ देगी</span></li>
                  <li style={{ fontWeight: 'bold', textAlign: 'center', fontFamily: 'arial' }}>Step 3: <span style={{ fontWeight: 'bold' }}>After few minutes, team will check your transaction and add subscription to your account</span></li>
                </ul>
                <p style={{ textAlign: "center", background: "#ee5253", color: "#fff" }}>Please Confirm your payment screenshot with the whatsapp support learn listed below , it will take them 10 to 15 minutes to verify your payment and add plan</p>
                <Image src="https://logolook.net/wp-content/uploads/2021/06/Logo-Whatsapp.png" style={{ width: 150, height: 100 }} className='mx-auto d-block' />
                <a href="https://api.whatsapp.com/send?phone=919993575990" className='mx-auto d-block'>व्हाट्सएप पर भुगतान स्क्रीनशॉट भेजने के लिए यहां क्लिक करें</a>
                <a href="https://api.whatsapp.com/send?phone=919993575990" className='mx-auto d-block'>Click Here to Send Payment Screenshot on whatsapp</a>

                <p style={{ textAlign: 'center', color: 'red', fontWeight: 'bold' }}>
                  पेमेंट मैं कोई समस्या या प्रोब्लोम आये तो इस नम्बर पे कॉल करे 9993575990
                </p>
              </>
          }
        </Card>
      </Container>
    </div>
  )
}
