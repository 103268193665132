/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useRef } from 'react'
import '../../ComponentsCss/UserProfile.css'
import { Card, Row, Col, Container, Image } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import { GET_USER, USER_DETAIL } from './grqphqloperation/query'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Navigation from '../Navigation'
import About from '../About'
import Footer1 from '../Footer1'
export default function UserProfile() {
  const { id } = useParams()

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { data } = useQuery(GET_USER, {
    variables: {
      id: `${id}`
    }
  })

  console.log('data', data)

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 2000,
    cssEase: 'linear',
    customPaging: function (i) {
      return (
        <a>
          <img src={data && data.getOneUser.picture[i]} />
        </a>
      )
    },

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  const sliders = () => {
    return (
      data &&
      data.getOneUser.picture.map(data => {
        return (
          <div key={data}>
            <img alt='image' src={data} />
          </div>
        )
      })
    )
  }

  const getSlidesPerView = () => {
    if (window.innerWidth >= 1024) {
      // Laptop and larger screens
      return 3;
    } else if (window.innerWidth >= 768) {
      // Tablet
      return 2;
    } else {
      // Mobile
      return 1;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      Swiper.params.slidesPerView = getSlidesPerView();
      Swiper.update();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Navigation />
      <br></br> <br></br>  <br></br> <br></br> <br></br> <br></br>
      <Container style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
     <div style={{width:"85%",}}>
      <Row>
        <Col md={3} >
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
          <Card style={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center",height:430,marginTop:10,marginBottom:10,boxShadow:"5px 5px 5px 5px rgba(0,0,0,0.2)",borderRadius:20}}>
            <Image src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getOneUser.profile}`} style={{width:"100%",height:"100%",borderRadius:20,objectFit:"cover"}} />
          </Card>
          </div>
        </Col>
        <Col md={9}>
        <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
        <Card style={{width:"98%",height:200,marginTop:10,fontSize:14,marginBottom:10,boxShadow:"5px 5px 5px 5px rgba(0,0,0,0.2)",borderRadius:20,justifyContent:"center"}}>
        <h5 style={{marginLeft:20,color: '#EC4C5C'}}>Contact Information</h5>
        <h6 style={{marginTop:20,marginLeft:20,fontSize:13}}>Name :- <span style={{fontWeight:"520",color:"#777"}}>{data && data.getOneUser.firstName} {data && data.getOneUser.lastName} </span>  </h6>
        <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>Mobile :- <span style={{fontWeight:"520",color:"#777"}}>{data && data.getOneUser.mobile} </span>  </h6>
        <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>Email :- <span style={{fontWeight:"520",color:"#777"}}>{data && data.getOneUser.email} </span>  </h6>
        <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>Addrass :-  <span style={{fontWeight:"520",color:"#777"}}>  {data && data.getOneUser.city}, {" "}{ data && data.getOneUser.pincode}, {data && data.getOneUser.state}, {data && data.getOneUser.country}</span>  </h6>
          </Card>
          </div>

          <Row>
            <Col md={6}>

            <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
        <Card style={{width:"95%",justifyContent:"center",height:200,marginTop:10,marginBottom:10,boxShadow:"5px 5px 5px 5px rgba(0,0,0,0.2)",borderRadius:20}}>
        <h5 style={{marginLeft:20,color: '#EC4C5C'}}>Basic Information</h5>
        <h6 style={{marginLeft:20,fontSize:13,}}>
                            Religion / Community :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.religion}, {" "}{data && data.getOneUser.caste} </span>
                          </h6>
                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>Gender :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.gender} </span></h6>
                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>
                            Date of Birth :- <span style={{ontWeight:"520",color:"#777"}}> {data && data.getOneUser.dob} </span>
                          </h6>
                        
                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>
                          Mother Tonque :- <span style={{fontWeight:"520",color:"#777"}}>Hindi</span>
                          </h6>

                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>
                            Age/Height :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.age}, {data && data.getOneUser.height}</span>
                          </h6>



          </Card>
          </div>
            </Col>
            <Col  md={6}>
              
          <div style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100%"}}>
        <Card style={{width:"95%",justifyContent:"center",height:200,marginTop:10,marginBottom:10,boxShadow:"5px 5px 5px 5px rgba(0,0,0,0.2)",borderRadius:20}}>
          <h5 style={{marginLeft:20,color: '#EC4C5C'}}>Professional Life</h5>
       
       
        <h6 style={{marginLeft:20,fontSize:13}}>Occupation :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.occupation} </span></h6>

                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>
                            Annual Income :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.income} </span>
                          </h6>

                          <h6 style={{marginLeft:20,fontSize:13,marginTop:-1}}>
                          Merital Status :- <span style={{ontWeight:"520",color:"#777"}}>{data && data.getOneUser.meritalStatus} </span>
                          </h6>  
          </Card>
          </div>
            </Col>
          </Row>



        </Col>

      </Row>
     
     <h6 style={{textAlign:"center"}}>View Pcture</h6>


      <Container>
              <Swiper
                spaceBetween={50}
                slidesPerView={getSlidesPerView()}
                onSlideChange={() => console.log('slide change')}
                onSwiper={swiper => console.log(swiper)}
              >
                {data &&
                  data.getOneUser.picture &&
                  data.getOneUser.picture[0].split(',').map((item, index) => (
                   
                    <SwiperSlide key={index}>
                       <Card style={{height:350,boxShadow:"5px 5px 5px 5px rgba(0,0,0,0.2)",borderRadius:20,marginBottom:5}}>
                      <img
                        className='shadow rounded-3'
                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.trim()}`}
                        alt=''
                        style={{objectFit:"cover",height:"100%" }}
                      />
                      </Card>
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Container>

      </div>
      </Container>
      

      {/* <div className='porfileBG' style={{ marginTop: '16vh' }}>

        <div className='profile-container1'>

          <div className='profile-detail'>
            <div className='profile-detail-row'>
              <div className='profile-img'>
                <img src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getOneUser.profile}`} style={{ height: 300, width: 300 }} />
              </div>
              <div className='profile-des'>
                <div className='profile-des-row'>
                  <div className='profile-des-col'>
                    <div style={{ width: 280 }}>
                      <Row style={{ width: '100%' }}>
                        <Col>
                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Age/Height{' '}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Marital Status{' '}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Posted by{' '}
                          </h6>
                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Annual Income{' '}
                          </h6>
                        </Col>
                        <Col>
                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            : {data && data.getOneUser.age}/
                            {data && data.getOneUser.height}{' '}
                          </h6>

                          {
                            data && data.getOneUser.meritalStatus === null ? (
                            <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                              : Never Married{' '}
                            </h6>
                          ) : (
                            <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                              : {data && data.getOneUser.meritalStatus}{' '}
                            </h6>
                          )}

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            : Self
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            : {data && data.getOneUser.income}
                          </h6>
                        </Col>
                      </Row>
                    </div>
                  </div>
                  <div className='profile-des-col'>
                    <div style={{ width: 280 }}>
                      <Row style={{ width: '100%' }}>
                        <Col>
                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Religion / Community{' '}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Location{' '}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            Mother Tonque{' '}
                          </h6>
                        </Col>
                        <Col>
                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            : {data && data.getOneUser.religion},
                            {data && data.getOneUser.caste}{' '}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 12 }}>
                            : {data && data.getOneUser.city} ,
                            {data && data.getOneUser.state}
                          </h6>

                          <h6 style={{ fontWeight: 450, fontSize: 13 }}>
                            : Hindi
                          </h6>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>

                <div className='profile-manage'>
                  <div style={{ width: '96%' }}>
                    <h6 style={{ marginLeft: 10, color: '#EC4C5C' }}>
                      Basics & LifeStyle
                    </h6>
                    <Row>
                      <Col md={3}>
                        <h6 style={{ marginLeft: 10, fontSize: 13 }}>Mobile</h6>
                        <h6 style={{ marginLeft: 10, fontSize: 13 }}>Email</h6>
                        <h6 style={{ marginLeft: 10, fontSize: 13 }}>Date of Birth</h6>
                        <h6 style={{ marginLeft: 10, fontSize: 13 }}>Gender</h6>
                        <h6 style={{ marginLeft: 10, fontSize: 13 }}>Occupation</h6>
                      </Col>
                      <Col md={9}>
                        <h6 style={{ fontSize: 13, color:'#34495e' }}>: +91-**********
                        </h6>
                        <h6 style={{ fontSize: 13, color:'#34495e' }}>: **********@gmail.com
                        </h6>
                     
                        <h6 style={{ fontSize: 13, color:'#34495e' }}>: &nbsp; {data && data.getOneUser.dob}
                        </h6>
                        <h6 style={{ fontSize: 13, color:'#34495e' }}>: &nbsp; {data && data.getOneUser.gender}
                        </h6>
                        <h6 style={{ fontSize: 13, color:'#34495e' }}>: &nbsp; {data && data.getOneUser.occupation}
                        </h6>
                      </Col>
                    </Row>
                  </div>
                </div>
              </div>
            </div>
          </div>
          
          <br />
          <div className='about-other'>

            <Container>
              <Swiper
                spaceBetween={50}
                slidesPerView={getSlidesPerView()}
                onSlideChange={() => console.log('slide change')}
                onSwiper={swiper => console.log(swiper)}
              >
                {data &&
                  data.getOneUser.picture &&
                  data.getOneUser.picture[0].split(',').map((item, index) => (
                    <SwiperSlide key={index}>
                      <img
                        className='shadow rounded-3'
                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${item.trim()}`}
                        alt=''
                        style={{ height: 270, objectFit: 'cover' }}
                      />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Container>
          </div>
          <br />
        </div>
      </div> */}
      <About />
      <Footer1 />
    </>
  )
}
