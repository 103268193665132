import React, { useEffect } from 'react'
import LoadingBar from './LoadingBar.jsx'
import { useQuery } from '@apollo/client';
import { RANDOM_MATCHES } from './user/grqphqloperation/query.js';
import ProfileCard from './ProfileCard.js';
import './1.css';
import Navigation from './Navigation.js';
import { Button, Col, Row } from 'react-bootstrap';
import Footer1 from './Footer1.js';
import { FaSadTear } from 'react-icons/fa';

function RandomMaches() {

  const { data, loading, error,refetch } = useQuery(RANDOM_MATCHES);

  useEffect(() => {
    refetch();
    window.scrollTo(0, 0);
  }, []);

  return (
    <React.StrictMode>
      <LoadingBar isLoading={loading} />
      <Navigation />
      <br /><br /><br /><br />
      <div className="cont" style={{ background: '#EC4C59', borderBottom: '3px dotted #D6F2FC' }}>
        {/* {data && data.randomUsers && data.randomUsers.map((user,index)=>(
          <Col key={index} xs={12} md={4}>
            <ProfileCard userA={user} />
          </Col>
        )) } */}
      {/* {
          data && data.randomUsers.map((user, index) => (
            <Col key={index} xs={12} md={4}>
              <ProfileCard userA={user} />
            </Col>
          ))
          } */}

          {

              data && data.randomUsers.map((user,index)=>{
                return(
                  <Col key={index} xs={12} md={4}>
                  <ProfileCard userA={user} />
                </Col>
                )

              })




          }



      </div>
      <Footer1 />
    </React.StrictMode>
  )
}

export default RandomMaches