import React from "react";
import Navigation from "../Navigation";
import { Container, Row, Col, Image, Card, Button } from "react-bootstrap";
import "../../ComponentsCss/showAllProfile.css";
import { FaEye } from "react-icons/fa";
import { useQuery } from "@apollo/client";
import BuySub from "../BuySub";
import { GET_PROFILE, GET_USER_INFO, GET_USER, GET_SEARCH_USER_FILTER } from "./grqphqloperation/query";

import { useLocation, useNavigate } from "react-router-dom";
import ProfileCard from "../ProfileCard";
import Footer1 from "../Footer1";

function ShowAllProfile() {

  const userId = localStorage.getItem("userId")
  const navigate = useNavigate()

  const { data } = useQuery(GET_USER_INFO)

  const { data: usedate } = useQuery(GET_USER, {
    variables: {
      "id": `${userId}`
    }
  })

  function handleClick(id) {
    if (usedate.getOneUser.subscriptionPlanStatus === "pending") {
      navigate("/Buyplan")
    } else {
      navigate("/user/" + id);
    }
  }

  return (
    <>
      <Navigation />

      <Container fluid  style={{ marginTop: 120,backgroundColor:'#EC4C5A',borderBottom:'3px dotted #D6F2FC' }}>
        <Row>
          {
            data && data.getUsers.map((user,index) => {
              return(
                <Col key={index} xs={12} md={4}>
                  <ProfileCard userA={user} />
                </Col>
              )
            })
          }
        </Row>
      </Container>
      <Footer1 />
    </>
  );
}

export default ShowAllProfile;
