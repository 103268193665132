import React, { useState } from "react";
import { Form, Container, Col, Row, Button, Image, Card } from "react-bootstrap";
import "../UserCss/Login.css";
import Saadipic from "../../Images/sadipic.png";
import Navigation from "../Navigation";
import { useMutation } from "@apollo/client";
import { SMS_OTP, USERLOGIN_OTP } from "./grqphqloperation/mutation";
import { useNavigate } from "react-router-dom";
import Footer1 from "../Footer1";
import "../css/Login.css"
export default function Login() {
  const [clickSendOtp, setClickSendOtp] = useState(false);
  const [otpNotMatch, setOtpNotMatch] = useState(false);
  const [clickResend, setclickResend] = useState(false);

  // const [signInUser, { data, error, loading }] = useMutation(SIGN_IN);
  const [logInUser, { data, error, loading }] = useMutation(USERLOGIN_OTP);

  const [sendSms, { data: smsData }] = useMutation(SMS_OTP);

  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();

  const [contact, setContact] = useState("");
  const [otpState, setOtpState] = useState("");

  const handleSubmit = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setValidated(false);
      generateOtp();
      setClickSendOtp(true);
    }

    function generateOtp() {
      const otp = Math.floor(1000 + Math.random() * 9000);
      localStorage.setItem("otp", otp);
      sendSms({
        variables: {
          contact: `${contact}`,
          otp: `${otp}`,
        },
      });
    }
  };

  const verifyOtpHandle = (event) => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      setValidated(true);
      event.preventDefault();
      event.stopPropagation();
    } else {
      setValidated(false);
      event.preventDefault();
      if (localStorage.getItem("otp") === otpState) {
        logInUser({
          variables: {
            "contact": `${contact}`,
          },
        }).then((res)=>{
          console.log('new r data',res);
          if (res.data.userLoginotp) {
            let { userId, userToken, userTokenExpiration } = res.data.userLoginotp;
            localStorage.removeItem("otp");
            localStorage.setItem("token", userToken);
            localStorage.setItem("userId", userId);
            navigate("/userDashboard");
        
            const expirationParts = userTokenExpiration.split('h');
            const expirationHours = parseInt(expirationParts[0]);
            const expirationInMilliseconds = expirationHours * 60 * 60 * 1000;
        
            if (expirationInMilliseconds > 0) {
                setTimeout(() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("userId");
                }, expirationInMilliseconds);
            }
        }
        })
      } else {
        setclickResend(false);
        setOtpNotMatch(true);
      }
    }
  };

  function resendOtpHandle() {
    setOtpNotMatch(false);
    localStorage.removeItem("otp");
    setclickResend(true);
    setOtpState("");
    const otp = Math.floor(1000 + Math.random() * 9000);
    localStorage.setItem("otp", otp);
  }

  function enterAgainHandle() {
    setOtpNotMatch(false);
    localStorage.removeItem("otp");
    setclickResend(false);
    setOtpState("");
    setContact("");
    setClickSendOtp(false);
  }

 
  function handleClick() {
    navigate("/userSignUp");
  }

  return (
    <>
      <Navigation />
      <br></br>
      <br></br> <br />
      <Container className="containersingup mt-md-5">
        <Row>
          <Container className="max-heigth-change">
            <Row className="d-flex align-items-center justify-content-center">
              {/* <Col md={6} style={{ display:"flex",justifyContent:"center",alignItems:"center" }}>
                <Image src={Saadipic} className="img-fluid mx-auto d-block"  style={{marginTop:30}} />
              </Col> */}
              <Col md={6} className="d-flex align-items-center justify-content-center">
                <Card className="login-card">
                  <h2 style={{ fontWeight: "bold", color: "#EC4C5C", fontFamily: "DM Sans", textAlign: "center", marginTop: 100 }}>
                    Login Using Mobile Number
                  </h2>
                  {
                    clickSendOtp ? (
                      <Row style={{ marginTop: 10 }}>
                        <Col md={3}></Col>
                        <Col md={6}>
                          <p style={{ fontSize: 18, fontWeight: "bold", color: "#000", fontFamily: "DM Sans", textAlign: "center" }}>
                            Enter OTP
                          </p>
                          <Container>
                            <Form noValidate validated={validated} onSubmit={verifyOtpHandle}>
                              <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Control required type="number" placeholder="Ente OTP" style={{ fontSize: "12px", fontFamily: "DM Sans" }} onChange={(e) => setOtpState(e.target.value)} onWheel={() => document.activeElement.blur()} value={otpState} />
                                {
                                  otpNotMatch ? (
                                    <>
                                      <Form.Control.Feedback style={{ fontSize: "12px", fontFamily: "DM Sans", color: "#e74c3c", fontWeight: "bold" }}>
                                        OTP Not Match!!!
                                      </Form.Control.Feedback>
                                      <Row style={{ marginTop: 20 }}>
                                        <Col style={{ fontSize: "14px", fontFamily: "DM Sans", fontWeight: "bold", color: "#0B1516", cursor: "pointer" }} onClick={() => resendOtpHandle()}>
                                          Resend Otp
                                        </Col>
                                        <Col style={{ fontSize: "14px", fontFamily: "DM Sans", fontWeight: "bold", color: "#0B1516", cursor: "pointer" }} onClick={() => enterAgainHandle()}>
                                          Enter Again
                                        </Col>
                                      </Row>
                                    </>
                                  ) : (
                                    <Form.Control.Feedback style={{ fontSize: "12px", fontFamily: "DM Sans", color: "#e74c3c", fontWeight: "bold" }}>
                                      {error && error.message}
                                      <h1 style={{ fontSize: "14px", fontFamily: "DM Sans", fontWeight: "bold", color: "#3498db", cursor: "pointer", marginTop: 20 }} onClick={() => enterAgainHandle()}>
                                        Enter Again
                                      </h1>
                                    </Form.Control.Feedback>
                                  )}
                              </Form.Group>
                              {
                                clickResend ? (
                                  <h1 style={{ fontSize: "12px", fontFamily: "DM Sans", color: "#2ecc71", fontWeight: "bold", marginTop: "10px" }}>
                                    OTP Sent Successfully!!!
                                  </h1>
                                ) : ""
                              }
                              <br></br>
                              <Button type="submit" style={{ fontFamily: "DM Sans", fontSize: "12px", borderRadius: "7px", background: "#EC4C5C", border: "1px solid #EC4C5C" }} className="mx-auto d-block">
                                Verify OTP
                              </Button>
                            </Form>
                          </Container>
                        </Col>
                        <Col md={3}></Col>
                      </Row>
                    ) : (
                      <Row style={{  }}>
                        <Col md={3}></Col>
                        <Col md={6}>
                          <p style={{ fontSize: 16, fontWeight: "bold", color: "#000000", fontFamily: "DM Sans", textAlign: "center" }}>
                            Enter Mobile Number
                          </p>
                          <Container>
                            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                              <Form.Group as={Col} md="12" controlId="validationCustom02">
                                <Form.Control required type="number" placeholder="Enter Mobile" style={{ fontSize: "12px", fontFamily: "DM Sans" }} onChange={(e) => setContact(e.target.value)} onWheel={() => document.activeElement.blur()} value={contact} />
                              </Form.Group>
                              <br></br>
                              <Button type="submit" style={{ fontFamily: "DM Sans", fontSize: "12px", borderRadius: "7px", marginBottom: 10, background: "#EC4C5C", border: "1px solid #EC4C5C" }} className="mx-auto d-block">
                                Send OTP
                              </Button>
                            </Form>
                          </Container>
                        </Col>
                        <Col md={3}></Col>
                      </Row>
                    )
                  }
                </Card>
              </Col>
            </Row>
          </Container>
        </Row>
      </Container>
      <br></br>
      <hr style={{ marginTop: 200 }}></hr>
      <Footer1 />
    </>
  );
}
