import React, { useLayoutEffect } from "react";
import { Card, Form, Container, Col, Row, Button } from "react-bootstrap";
import "../ComponentsCss/Knoweach.css";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default function Knoweachother() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".knowmain",
        start: "top bottom-=50px",
        toggleActions: "play none none none",
        // markers: true,
      },
    });

    tl.from(".Heading", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      delay: -0.5,
    });
    tl.from(".para", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      delay: -0.5,
    });

    return () => {tl.kill();};
  }, []);

  return (
    <Container fluid className="bg2 knowmain" style={{ marginTop: 20 }}>
      <Container style={{}}>
        <Row>
          <Col md={12} style={{ marginTop: "50px" }}>
            <h1
              style={{
                color: "#fff",
                fontSize: 40,
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "DM Sans",
              }}
              className="Heading"
            >
              Where <span style={{ color: "#c0392b" }}>Love's</span> Journey
              Begins – Your Path to{" "}
              <span style={{ color: "#2ecc71" }}>Everlasting</span> Matrimony
            </h1>
          </Col>
          <p
            style={{
              color: "#fff",
              textAlign: "center",
              marginTop: 130,
              fontWeight: "bold",
              fontFamily: "DM Sans",
              fontSize: 20,
              letterSpacing: 1,
            }}
            className="para"
          >
            Explore Byaahlagan.com, your gateway to enduring love and lasting
            unions. Uncover meaningful connections on our exclusive matrimonial
            platform, where compatibility meets commitment. Start your journey
            towards a lifetime of joy and companionship. Join Byaahlagan.com and
            let love lead the way to your perfect match.
          </p>
        </Row>
      </Container>
    </Container>
  );
}
