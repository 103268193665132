import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation Mutation($signup: SignUpInput) {
    signUpUser(signup: $signup) {
      id
    }
  }
`;

export const SIGN_IN = gql`
  mutation SignInUser($signIn: SignInput) {
    signInUser(signIn: $signIn) {
      token
      userId
    }
  }
`;

export const CREATE_PROFILE = gql`
mutation UpdateUser($updateNew: updateUserInput) {
  updateUser(updateNew: $updateNew) {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    status
    razorpayPaymentId
    bookingAmount
    isConnected
    userIdType
    userIdNumber
    userIdPic
  }
}
`;

export const USER_CONNECTION = gql`
mutation Mutation($userConnection: UserConnectionInput) {
  createConnection(userConnection: $userConnection) {
    connectionFor
    connectionForFname
    connectionForLname
    connectionForAge
    connectionForCaste
    connectionForContact
    connectionFrom
    connectionFromFname
    connectionFromLname
    connectionFromContact
    timeDate
    status
  }
}
`;

export const UPDATE_USER = gql`
  mutation Mutation($updateNew: updateUserInput) {
  updateUser(updateNew: $updateNew) {
    id
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
  }
}
`;

export const USER_SUBSCRIPTION = gql`

mutation Mutation($subscriptionInput: subscriptionInput) {
  subscription(SubscriptionInput: $subscriptionInput) {
    
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
  }
}

`;

export const SUBSCRIPTION_PLAN = gql`
mutation Mutation($subscriptionInput: subscriptionInput) {
  subscription(SubscriptionInput: $subscriptionInput) {
    id
  
  }
}
`

export const SMS_OTP = gql`
mutation Mutation($contact: String, $otp: String) {
  sendSms(contact: $contact, otp: $otp)
}
`

export const USERLOGIN_OTP = gql`
mutation Mutation($contact: String, $otp: String) {
  userLoginotp(contact: $contact, otp: $otp) {
    userId
    userToken
    userTokenExpiration
  }
}
`

export const MUTATION_ADMIN_LOGIN = gql`
mutation Mutation($adminInput: AdminInput) {
  adminLogin(adminInput: $adminInput) {
    adminid
    adminToken
    adminTokenExpiration
  }
}
`
export const MUTATION_UPDATE_STATUS = gql`
mutation UpdateUserStatus($updateNewStatus: updateNewStatus) {
  updateUserStatus(UpdateNewStatus: $updateNewStatus) {
    id
    firstName
    lastName
    email
    status
  }
}
`
export const MUTATION_CREATE_STRIPE_LINK = gql`
mutation Mutation($bookingAmount: String, $subscriptionPlanName: String) {
  stripeCheckout(bookingAmount: $bookingAmount, subscriptionPlanName: $subscriptionPlanName)
}
`

export const MUTATION_CONTACT_US = gql`
mutation Mutation($contactUsInput: contactUsInput) {
  createContactUs(ContactUsInput: $contactUsInput) {
    id
    name
    contact
    email
    message
    createdDateTime
    status
  }
}
`

export const MUTATION_CONTACT_US_DELETE = gql`
mutation Mutation($contactId: String) {
  deleteContactUs(contactId: $contactId)
}
`

export const MUTATION_USER_DELETE = gql`
mutation UserDetele($userId: ID) {
  userDetele(userId: $userId) {
    id
   
  }
}

`

export const MUTATION_EMAIL = gql`
mutation Mutation($email: String, $subject: String, $text: String, $userName: String, $img: String, $logo: String) {
  createMail(email: $email, subject: $subject, text: $text, userName: $userName, Img: $img, logo: $logo)
}
`

export const MUTATION_PAYMENT = gql`
mutation Mutation($paymentInput: paymentInput) {
  createPaymentGateway(PaymentInput: $paymentInput) {
    id
  
  }
}
`
export const MUTATION_EDIT_PAYMENT_GATEWAY = gql`
mutation Mutation($editpaymentInput: editpaymentInput) {
  updatePaymentGateway(EditpaymentInput: $editpaymentInput) {
    id
    imageQR
    upiID
    status
  }
}
`
export const MUTATION_EDIT_PLAN = gql`
mutation Mutation($updatePlanInput: updatePlanInput) {
  updatePlan(UpdatePlanInput: $updatePlanInput) {
    id
    plantype
    price
    status
  }
}

`

export const MUTATION_ADMIN_PASSWORD_CHANGE = gql`
mutation Mutation($updateAdminPass: updateAdminPass) {
  adminChangePassword(UpdateAdminPass: $updateAdminPass) {
    id
    name
    contact
    email
    userName
    password
    status
  }
}
`

export const SEND_REQUEST = gql`
mutation SendFriendRequest($requesterId: ID!, $recipientId: ID!) {
  sendFriendRequest(requesterId: $requesterId, recipientId: $recipientId) {
    id
  }
}
`

export const ACCEPT_REQUEST = gql`
mutation AcceptFriendRequest($requestId: ID!) {
  acceptFriendRequest(requestId: $requestId) {
    id
  
}
}
`

export const REJECT_REQUEST = gql`
mutation RejectFriendRequest($requestId: ID!) {
  rejectFriendRequest(requestId: $requestId) {
    id
 
  }
}

`