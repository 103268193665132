import React, { useLayoutEffect, useState } from "react";
import {
  Card,
  Form,
  Container,
  Col,
  Row,
  Button,
  Spinner,
} from "react-bootstrap";
import Video from "../Images/maldivesVideo.mp4";
import "../ComponentsCss/Hero.css";
import { useQuery } from "@apollo/client";
import { GET_SEARCH_USER_FILTER } from "./user/grqphqloperation/query";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";
import gsap from "gsap";

export default function Hero() {
  const [gender, setGender] = useState("");
  const [age, setAge] = useState("");
  const [caste, setCaste] = useState("");
  const navigate = useNavigate();

  function handleRedirect() {
    if (age === "" && gender === "" && caste === "") {
      swal({
        title: "Error",
        text: "Please select any one field!!!",
        icon: "warning",
      });
    } else {
      navigate("/Searchresult", {
        state: {
          age: age,
          gender: gender,
          caste: caste,
        },
      });
    }
  }

  const AgeOptions = () => {
    const options = [];

    for (let i = 20; i <= 40; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  };

  const cast = [
    "Uikey",
    "Dhurvey",
    "Batti",
    "Bhalavi",
    "Barkade",
    "Katlam",
    "Tekam",
    "Marpachi",
    "Karpayti",
    "Kulrate",
    "Marskota",
    "Meshram",
    "Urpayti",
    "Kumare",
    "Parteli",
    "Ivnati",
  ];

  const korku = [
    "Mowasi",
    "Sheeloo",
    "Rajdarshama",
    "Rabhopa",
    "Rajbosam",
    "Mausee",
    "Lobo",
    "Rajbaithe",
    "Rajnikhame",
    "Changree",
    "Atkom",
    "Sakom",
    "Kayada",
    "Akhadee",
    "Thakhair",
  ];

  useLayoutEffect(() => {
    gsap.from(".PreferenceForm_formWrapper__25uHC", {
      duration: 1,
      scale: 0.5,
      opacity: 0,
      ease: "ease",
      delay: 0.8,
    });
  }, []);
  return (
    <div className="content">
      {/* <div className="blurred-background">
                <h2 style={{ fontFamily: 'DM Sans', position: 'relative', marginTop: '200px', color: '#ffffff', fontWeight: 'bold' }}>Trusted Matrimony & Matchmaking Service</h2>
            </div> */}
      <Container
        className="PreferenceForm_formWrapper__25uHC section"
        style={{ borderRadius: "7px", marginTop: 100 }}
      >
        <Row style={{ marginTop: 30 }}>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Select
                aria-label="Default select example"
                style={{ fontFamily: '"Amaranth", sans-serif', fontSize: 18 }}
                onChange={(e) => setGender(e.target.value)}
                value={gender}
              >
                <option> Select Gender</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Select
                aria-label="Default select example"
                style={{ fontFamily: '"Amaranth", sans-serif', fontSize: 18 }}
                onChange={(e) => setAge(e.target.value)}
                value={age}
              >
                <option> Age </option>
                <AgeOptions />
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-3">
              <Form.Select
                aria-label="Default select example"
                style={{ fontFamily: '"Amaranth", sans-serif', fontSize: 18 }}
                onChange={(e) => setCaste(e.target.value)}
                value={caste}
              >
                <option> Caste </option>
                <optgroup label="Korku">
                  {korku.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </optgroup>
                <optgroup label="Gond">
                  {cast.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </optgroup>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col md={3}>
            <Button
              className="beginbutton"
              style={{ fontFamily: '"Amaranth", sans-serif', fontSize: 18 }}
              onClick={() => handleRedirect()}
            >
              Let's begin
            </Button>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
