import React, { useState } from 'react'
import {
  Nav,
  Tab,
  Row,
  Col,
  Container,
  Card,
  Image,
  Button,
  Modal,
  Form,
  Spinner,
  InputGroup
} from 'react-bootstrap'
import Dropzone from 'react-dropzone-uploader'
import '../UserCss/SelectProfile.css'
import { useParams } from 'react-router-dom'

import { useQuery, useMutation } from '@apollo/client'
import { CREATE_PROFILE, UPDATE_USER } from './grqphqloperation/mutation'
import { GET_USER } from './grqphqloperation/query'

import swal from 'sweetalert'
import { CREATE_USER_BY } from './grqphqloperation/query'

function EditProfile() {
  const [validated, setValidated] = useState(false)

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)
  const [loginError, setLoginError] = useState(false)
  const [multiImgUploaded, setMultiImageUploaded] = useState(false) 
  const [income, setIncome] = useState()
  const [userid, setUserId] = useState()
  const [dob, setDob] = useState()
  const [fName, setFname] = useState()
  const [lName, setLname] = useState()
  const [email, setEmail] = useState()
  const [caste, setCaste] = useState()
  const [profile, setProfile] = useState()
  const [picture, setpicture] = useState()
  const [gender, setGender] = useState()
  const [height, setHeight] = useState()
  const [age, setAge] = useState()
  const [religion, setReligion] = useState()
  const [meritalStatus, setMeritalStatus] = useState()
  const [state, setState] = useState()
  const [city, setCity] = useState()
  const [country, setCountry] = useState()
  const [pincode, setPincode] = useState()
  const [occupation, setOccupation] = useState()

  const userId = localStorage.getItem('userId')

  const [updateUser, { loading: createProfileLoading }] = useMutation(
    CREATE_PROFILE,
    {
      errorPolicy: 'all',
      onError(error) {
        setLoginError(true)
      },

      refetchQueries: [GET_USER, 'getUsers']
    }
  )

  const { loading, data, error } = useQuery(GET_USER, {
    variables: {
      id: `${userId}`
    }
  })

  // update Date
  function handleEdit(
    Id,
    fName,
    lname,
    email,
    dob,
    age,
    gender,
    pincode,
    occupation,
    caste,
    state,
    city,
    country,
    religion,
    height
  ) {
    setUserId(Id)
    setFname(fName)
    setLname(lname)
    setEmail(email)
    setDob(dob)
    setAge(age)
    setGender(gender)
    setPincode(pincode)
    setOccupation(occupation)
    setCaste(caste)
    setState(state)
    setCity(city)
    setCountry(country)
    setReligion(religion)
    setHeight(height)

    handleShow()
  }

  const handleSubmit = event => {
    const form = event.currentTarget
    if (form.checkValidity() === false) {
      setValidated(true)
      event.preventDefault()
      event.stopPropagation()
    } else {
      setValidated(false)
      event.preventDefault()
      updateUser({
        variables: {
          updateNew: {
            userid: `${userid}`,
            firstName: `${fName}`,
            lastName: `${lName}`,
            email: `${email}`,
            dob: `${dob}`,
            caste: `${caste}`,
            gender: `${gender}`,
            height: `${height}`,
            age: `${age}`,
            religion: `${religion}`,
            meritalStatus: `${meritalStatus}`,
            state: `${state}`,
            city: `${city}`,
            country: `${country}`,
            pincode: `${pincode}`,
            occupation: `${occupation}`,
            status: 'active',
          }
        }
      })
      handleClose()
    }
  }

  return (
    <>
      <Container>
        <Row style={{ marginTop: 20 }}>
          <Container>
            <h3 style={{ color: '#EC4C5A' }}> Edit Profile</h3>
            <hr />
            <Card style={{ marginBottom: 30 }} className='ProfilesCard'>
              <Card.Body style={{ borderRadius: '7px' }}>
                <Row>
                  <Col md={2}>
                    {data && data.getOneUser.profile === null ? (
                      <Image
                        className='mx-auto d-block img-fluid ProfileImgCard'
                        src='https://media.istockphoto.com/id/1337144146/vector/default-avatar-profile-icon-vector.jpg?s=612x612&w=0&k=20&c=BIbFwuv7FxTWvh5S3vB6bkT0Qv8Vn8N5Ffseq84ClGI='
                      />
                    ) : (
                      <Image
                        className='mx-auto d-block img-fluid ProfileImgCard'
                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getOneUser.profile
                          }`}
                      />
                    )}
                  </Col>
                  <Col md={4}>
                    <p
                      style={{
                        marginTop: 12,
                        textAlign: 'center',
                        fontFamily: 'Dm sans',
                        color: "#000",
                        fontWeight: "600",
                        letterSpacing: 0.5
                      }}
                    >
                      {data && data.getOneUser.firstName}{' '}
                      {data && data.getOneUser.lastName}
                    </p>
                  </Col>
                  <Col md={3}>
                    <p style={{ marginTop: '17px', fontFamily: 'Dm sans', color: "#000" }}>
                      {data && data.getOneUser.mobile}
                    </p>
                  </Col>
                  <Col md={3}>
                    <Button
                      className='btn-1 btn2'
                      onClick={() =>
                        handleEdit(
                          data && data.getOneUser.id,
                          data && data.getOneUser.firstName,
                          data && data.getOneUser.lastName,
                          data && data.getOneUser.email,
                          data && data.getOneUser.dob,
                          data && data.getOneUser.age,
                          data && data.getOneUser.gender,
                          data && data.getOneUser.pincode,
                          data && data.getOneUser.occupation,
                          data && data.getOneUser.caste,
                          data && data.getOneUser.state,
                          data && data.getOneUser.city,
                          data && data.getOneUser.country,
                          data && data.getOneUser.religion,
                          data && data.getOneUser.height
                        )
                      }
                    >
                      Edit
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Container>
        </Row>

        <Modal show={show} onHide={handleClose} size='lg' style={{ padding: '10px' }}>
          <Modal.Header closeButton>
            <Modal.Title>Update Profile</Modal.Title>
          </Modal.Header>
          <Container className='px-5'>
            <Row style={{ marginTop: '20px' }}>
              <Col></Col>
              <Col xs={12}>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                  <Row className='mb-3'>
                    <Form.Group as={Col} md='4' controlId='validationCustom01'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        First name
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        value={fName}
                        placeholder='First name'
                        onChange={e => setFname(e.target.value)}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md='4' controlId='validationCustom02'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Last name
                      </Form.Label>
                      <Form.Control
                        required
                        type='text'
                        value={lName}
                        onChange={e => setLname(e.target.value)}
                        placeholder='Last name'
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md='4'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Email
                      </Form.Label>

                      <Form.Control
                        type='email'
                        placeholder='Email'
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        aria-describedby='inputGroupPrepend'
                        required
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>
                  </Row>

                  <Row className='mb-3'>
                    <Form.Group as={Col} md='4' controlId='validationCustom02'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Date of Birth
                      </Form.Label>
                      <Form.Control
                        required
                        type='date'
                        placeholder='DD/MM/YYYY'
                        onChange={e => setDob(e.target.value)}
                        value={dob}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>

                    <Form.Group as={Col} md='4' controlId='validationCustom01'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Religion
                      </Form.Label>
                      <Form.Select
                        required
                        onChange={e => setReligion(e.target.value)}
                        value={religion}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose Religion{' '}
                        </option>
                        <option value='Adivasi' style={{ fontWeight: 'bold', color: "#34495e" }}>Adivasi</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md='4' controlId='validationCustom02'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Caste
                      </Form.Label>
                      <Form.Select
                        required
                        onChange={e => setCaste(e.target.value)}
                        value={caste}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose Caste
                        </option>
                        <optgroup style={{ color: '#2980b9' }} label="Korku">
                          <option value="Mowasi" style={{ fontWeight: 'bold', color: "#34495e" }}>Mowasi</option>
                          <option value="Sheeloo" style={{ fontWeight: 'bold', color: "#34495e" }}>Sheeloo</option>
                          <option value="Rajdarshama" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajdarshama</option>
                          <option value="Rabhopa" style={{ fontWeight: 'bold', color: "#34495e" }}>Rabhopa</option>
                          <option value="Rajbosam" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajbosam</option>
                          <option value="Mausee" style={{ fontWeight: 'bold', color: "#34495e" }}>Mausee</option>
                          <option value="Lobo" style={{ fontWeight: 'bold', color: "#34495e" }}>Lobo</option>
                          <option value="Rajbaithe" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajbaithe</option>
                          <option value="Rajnikhame" style={{ fontWeight: 'bold', color: "#34495e" }}>Rajnikhame</option>
                          <option value="Changree" style={{ fontWeight: 'bold', color: "#34495e" }}>Changree</option>
                          <option value="Atkom" style={{ fontWeight: 'bold', color: "#34495e" }}>Atkom</option>
                          <option value="Sakom" style={{ fontWeight: 'bold', color: "#34495e" }}>Sakom</option>
                          <option value="Kayada" style={{ fontWeight: 'bold', color: "#34495e" }}>Kayada</option>
                          <option value="Akhadee" style={{ fontWeight: 'bold', color: "#34495e" }}>Akhadee</option>
                          <option value="Thakhair" style={{ fontWeight: 'bold', color: "#34495e" }}>Thakhair</option>
                        </optgroup>
                        <optgroup style={{ color: '#2980b9' }} label="Gond">
                          <option value="Uikey" style={{ fontWeight: 'bold', color: "#34495e" }}>Uikey</option>
                          <option value="Dhurvey" style={{ fontWeight: 'bold', color: "#34495e" }}>Dhurvey</option>
                          <option value="Batti" style={{ fontWeight: 'bold', color: "#34495e" }}>Batti</option>
                          <option value="Bhalavi" style={{ fontWeight: 'bold', color: "#34495e" }}>Bhalavi</option>
                          <option value="Barkade" style={{ fontWeight: 'bold', color: "#34495e" }}>Barkade</option>
                          <option value="Katlam" style={{ fontWeight: 'bold', color: "#34495e" }}>Katlam</option>
                          <option value="Tekam" style={{ fontWeight: 'bold', color: "#34495e" }}>Tekam</option>
                          <option value="Marpachi" style={{ fontWeight: 'bold', color: "#34495e" }}>Marpachi</option>
                          <option value="Karpayti" style={{ fontWeight: 'bold', color: "#34495e" }}>Karpayti</option>
                          <option value="Kulrate" style={{ fontWeight: 'bold', color: "#34495e" }}>Kulrate</option>
                          <option value="Marskota" style={{ fontWeight: 'bold', color: "#34495e" }}>Marskota</option>
                          <option value="Meshram" style={{ fontWeight: 'bold', color: "#34495e" }}>Meshram</option>
                          <option value="Urpayti" style={{ fontWeight: 'bold', color: "#34495e" }}>Urpayti</option>
                          <option value="Kumare" style={{ fontWeight: 'bold', color: "#34495e" }}>Kumare</option>
                          <option value="Parteli" style={{ fontWeight: 'bold', color: "#34495e" }}>Parteli</option>
                          <option value="Ivnati" style={{ fontWeight: 'bold', color: "#34495e" }}>Ivnati</option>
                        </optgroup>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className='mb-3'>
                    <Form.Group
                      as={Col}
                      md='4'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Gender
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label='Default select example'
                        onChange={e => setGender(e.target.value)}
                        value={gender}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option
                          value=''
                          selected='selected'
                          disabled='disabled'
                        >
                          Choose Gender
                        </option>
                        <option value='Male' style={{ fontWeight: 'bold', color: "#34495e" }}>Male</option>
                        <option value='Female' style={{ fontWeight: 'bold', color: "#34495e" }}>Female</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group
                  as={Col}
                  md="4"
                  controlId="validationCustomUsername"
                >
                  <Form.Label
                    style={{
                      fontSize: "17px",
                      fontFamily: "Dm Sans",
                      fontWeight: "bold",
                    }}
                  >
                    Annual Income
                  </Form.Label>
                  <Form.Select
                    required
                    aria-label="Default select annual income"
                    onChange={(e) => setIncome(e.target.value)}
                    value={income}
                    style={{ fontSize: "15px", fontFamily: "Dm Sans" }}
                  >
                    <option value="" selected="selected" disabled="disabled">
                      Choose Annual Income
                    </option>
                    <option value="0-1 Lakh" style={{ fontWeight: 'bold', color: "#34495e" }}>0-1 Lakh</option>
                    <option value="1-2 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>1-2 Lakhs</option>
                    <option value="2-3 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>2-3 Lakhs</option>
                    <option value="3-4 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>3-4 Lakhs</option>
                    <option value="4-5 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>4-5 Lakhs</option>
                    <option value="5-7.5 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>5-7.5 Lakhs</option>
                    <option value="7.5-10 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>7.5-10 Lakhs</option>
                    <option value="10-15 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>10-15 Lakhs</option>
                    <option value="15-20 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>15-20 Lakhs</option>
                    <option value="20-25 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>20-25 Lakhs</option>
                    <option value="25-35 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>25-30 Lakhs</option>
                    <option value="35-50 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>35-50 Lakhs</option>
                    <option value="50-70 Lakhs" style={{ fontWeight: 'bold', color: "#34495e" }}>50-70 Lakhs</option>
                    <option value="70 Lakh-1 Crore" style={{ fontWeight: 'bold', color: "#34495e" }}>70 Lakh-1 Crore</option>
                    <option value="Above 1 Crore" style={{ fontWeight: 'bold', color: "#34495e" }}>Above 1 Crore</option>
                  </Form.Select>
                </Form.Group>

                    <Form.Group
                      as={Col}
                      md='4'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Your Marital Status
                      </Form.Label>
                      <Form.Select
                        required
                        aria-label='Default select example'
                        onChange={e => setMeritalStatus(e.target.value)}
                        value={meritalStatus}
                        style={{ fontSize: '15px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose
                        </option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value='Never Merried'>Never Married</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value='Awaiting Dovicer'>Awaiting Dovicer</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value='Dovicer'>Dovicer</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value='Widowed'>Widowed</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value='Annulled'>Annulled</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>

                  <Row className='mb-3'>
                    <Form.Group
                      as={Col}
                      md='4'
                      name='Age'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Height
                      </Form.Label>
                      <Form.Select
                        type='text'
                        placeholder='In CM'
                        aria-describedby='inputGroupPrepend'
                        required
                        onChange={e => setHeight(e.target.value)}
                        value={height}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose
                        </option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 0In">4ft 0In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 1In">4ft 1In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 2In">4ft 2In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 3In">4ft 3In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 4In">4ft 4In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 5In">4ft 5In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 6In">4ft 6In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 7In">4ft 7In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 8In">4ft 8In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 9In">4ft 9In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 10In">4ft 10In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="4ft 11In">4ft 11In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 0In">5ft 0In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 1In">5ft 1In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 2In">5ft 2In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 3In">5ft 3In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 4In">5ft 4In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 5In">5ft 5In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 6In">5ft 6In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 7In">5ft 7In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 8In">5ft 8In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 9In">5ft 9In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 10In">5ft 10In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="5ft 11In">5ft 11In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 0In">6ft 0In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 1In">6ft 1In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 2In">6ft 2In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 3In">6ft 3In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 4In">6ft 4In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 5In">6ft 5In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 6In">6ft 6In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 7In">6ft 7In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 8In">6ft 8In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 9In">6ft 9In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 10In">6ft 10In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="6ft 11In">6ft 11In</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="7ft">7ft</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md='4'
                      name='Age'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Age
                      </Form.Label>
                      <Form.Select
                        type='text'
                        placeholder='Enter Age'
                        aria-describedby='inputGroupPrepend'
                        required
                        onChange={e => setAge(e.target.value)}
                        value={age}
                        style={{ fontSize: '15px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose
                        </option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="18">18</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="19">19</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="20">20</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="21">21</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="22">22</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="23">23</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="24">24</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="25">25</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="26">26</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="27">27</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="28">28</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="29">29</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="30">30</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="31">31</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="32">32</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="33">33</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="34">34</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="35">35</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="36">36</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="37">37</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="38">38</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="39">39</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="40">40</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="41">41</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="42">42</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="43">43</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="44">44</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="45">45</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="46">46</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="47">47</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="48">48</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="49">49</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="50">50</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="51">51</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="52">52</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="53">53</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="54">54</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="55">55</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="56">56</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="57">57</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="58">58</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="59">59</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="60">60</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="61">61</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="62">62</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="63">63</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="64">64</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="65">65</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="66">66</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="67">67</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="68">68</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="69">69</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="70">70</option>
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} md='4' controlId='validationCustom03'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        City
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='City'
                        anme='City'
                        required
                        onChange={e => setCity(e.target.value)}
                        value={city}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>
                  </Row>

                  <Row className='mb-3'>
                    <Form.Group as={Col} md='3' controlId='validationCustom04'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        State
                      </Form.Label>
                      <Form.Select
                        type='text'
                        placeholder='State'
                        required
                        onChange={e => setState(e.target.value)}
                        value={state}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose
                        </option>
                        <option value='Andaman and Nicobar Islands'>
                          Andaman and Nicobar Islands
                        </option>
                        <option value='Andhra Pradesh'>Andhra Pradesh</option>
                        <option value='Arunachal Pradesh'>
                          Arunachal Pradesh
                        </option>
                        <option value='Assam'>Assam</option>
                        <option value='Bihar'>Bihar</option>
                        <option value='Chandigarh'>Chandigarh</option>
                        <option value='Chhattisgarh'>Chhattisgarh</option>
                        <option value='Dadra and Nagar Haveli'>
                          Dadra and Nagar Haveli
                        </option>
                        <option value='Daman and Diu'>Daman and Diu</option>
                        <option value='Delhi'>Delhi</option>
                        <option value='Goa'>Goa</option>
                        <option value='Gujarat'>Gujarat</option>
                        <option value='Haryana'>Haryana</option>
                        <option value='Himachal Pradesh'>
                          Himachal Pradesh
                        </option>
                        <option value='Jammu and Kashmir'>
                          Jammu and Kashmir
                        </option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Andhra Pradesh">Andhra Pradesh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Arunachal Pradesh">Arunachal Pradesh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Assam">Assam</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Bihar">Bihar</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Chandigarh">Chandigarh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Chhattisgarh">Chhattisgarh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Dadra and Nagar Haveli">Dadra and Nagar Haveli</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Daman and Diu">Daman and Diu</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Delhi">Delhi</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Goa">Goa</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Gujarat">Gujarat</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Haryana">Haryana</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Himachal Pradesh">Himachal Pradesh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Jammu and Kashmir">Jammu and Kashmir</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Jharkhand">Jharkhand</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Karnataka">Karnataka</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Kerala">Kerala</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Ladakh">Ladakh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Lakshadweep">Lakshadweep</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Madhya Pradesh">Madhya Pradesh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Maharashtra">Maharashtra</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Manipur">Manipur</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Meghalaya">Meghalaya</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Mizoram">Mizoram</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Nagaland">Nagaland</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Odisha">Odisha</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Puducherry">Puducherry</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Punjab">Punjab</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Rajasthan">Rajasthan</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Sikkim">Sikkim</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Tamil Nadu">Tamil Nadu</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="elangana">Telangana</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Tripura">Tripura</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Uttar Pradesh">Uttar Pradesh</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Uttarakhand">Uttarakhand</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="West Bengal">West Bengal</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group as={Col} md='3' controlId='validationCustom05'>
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Pincode
                      </Form.Label>
                      <Form.Control
                        type='text'
                        placeholder='Enter Here'
                        required
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                        onChange={e => setPincode(e.target.value)}
                        value={pincode}
                      />
                    </Form.Group>

                    <Form.Group
                      as={Col}
                      md='3'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Country
                      </Form.Label>

                      <Form.Control
                        required
                        type='text'
                        placeholder='Enter Here'
                        onChange={e => setCountry(e.target.value)}
                        value={country}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      />
                    </Form.Group>
                    <Form.Group
                      as={Col}
                      md='3'
                      controlId='validationCustomUsername'
                    >
                      <Form.Label
                        style={{
                          fontSize: '15px',
                          fontFamily: 'Dm Sans',
                          fontWeight: 'bold'
                        }}
                      >
                        Occupation
                      </Form.Label>

                      <Form.Select
                        required
                        type='text'
                        placeholder='Enter Here'
                        onChange={e => setOccupation(e.target.value)}
                        value={occupation}
                        style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                      >
                        <option value='' selected='selected' disabled>
                          Choose
                        </option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Private Company">Private Company</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Goverment / Public Sector">Goverment / Public Sector</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Defence / Civil Services">Defence / Civil Services</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Business / Self Employed">Business / Self Employed</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Farmer">Farmer</option>
                        <option style={{ fontWeight: 'bold', color: "#34495e" }} value="Not Working">Not Working</option>
                      </Form.Select>
                    </Form.Group>
                  </Row>
                  <Form.Group className='mb-3'>
                    <Form.Check
                      required
                      label='Agree to terms and conditions'
                      feedback='You must agree before submitting.'
                      feedbackType='invalid'
                      style={{ fontSize: '14px', fontFamily: 'Dm Sans' }}
                    />
                  </Form.Group>
                  <Button
                    type='submit'
                    variant='outline-primary'
                    style={{
                      fontSize: '14px',
                      fontFamily: 'Dm Sans',
                      marginBottom: '20px'
                    }}
                    className='mx-auto d-block'
                  >
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
    </>
  )
}

export default EditProfile
