import React, { useLayoutEffect } from "react";
import { Card, Form, Container, Col, Row, Button } from "react-bootstrap";
import "../ComponentsCss/FIndsome.css";
import { FiEdit, FiPhoneCall, FiUsers } from "react-icons/fi";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export default function FIndsomeone() {
  useLayoutEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: ".findmain",
        start: "top bottom-=50px",
        toggleActions: "play none none none",
      },
    });

    tl.from(".FindSomeoneHeading", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      delay: -0.5,
    });

    tl.from(".FindSomeonePara", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      delay: -0.5,
    });

    tl.from(".CircleImage", {
      duration: 1,
      y: 100,
      opacity: 0,
      ease: "power4.out",
      stagger: 0.3,
    });

    return () => {tl.kill();};
  }, []);

  return (
    <Container style={{ marginTop: 35 }} className="findmain">
      <Row>
        <Col md={12}>
          <h1
            style={{
              color: "black",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "'Amaranth', sans-serif",
            }}
            className="FindSomeoneHeading"
          >
            Find your{" "}
            <span
              style={{
                color: "#ff5a60",
                fontWeight: "bold",
                textAlign: "center",
                fontFamily: "'Amaranth', sans-serif",
              }}
            >
              Special Someone
            </span>
          </h1>
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          <p
            style={{
              color: "black",
              fontSize: 20,
              textAlign: "center",
              fontFamily: "DM Sans",
            }}
            className="FindSomeonePara"
          >
            Embark on the journey of a lifetime with Byaahlagan.com, your
            trusted companion in the quest for eternal love. Our platform is a
            sanctuary for meaningful connections, where souls unite and stories
            of lifelong happiness unfold. Join us in creating beautiful tales of
            matrimony, guided by compatibility and shared dreams. At
            Byaahlagan.com, we believe in crafting the perfect beginnings for
            your happily ever after. Start your love story today.
          </p>
        </Col>
      </Row>

      <Row style={{ marginTop: 30 }}>
        <Col md={4} className="CircleImage mx-auto d-block">
          <h1
            style={{
              marginTop: 50,
              color: "#fff",
              fontSize: 30,
              textAlign: "center",
              fontFamily: "DM Sans",
              fontWeight: "bold",
            }}
          >
            Sign In
            <br />
            <FiEdit />
          </h1>
        </Col>
        <Col md={4} className="CircleImage mx-auto d-block">
          <h1
            style={{
              marginTop: 50,
              color: "#fff",
              fontSize: 30,
              textAlign: "center",
              fontFamily: "DM Sans",
              fontWeight: "bold",
            }}
          >
            Connect
            <br />
            <FiUsers />
          </h1>
        </Col>
        <Col md={4} className="CircleImage mx-auto d-block">
          <h1
            style={{
              marginTop: 50,
              color: "#fff",
              fontSize: 30,
              textAlign: "center",
              fontFamily: "DM Sans",
              fontWeight: "bold",
            }}
          >
            Interact
            <br />
            <FiPhoneCall />
          </h1>
        </Col>
      </Row>
    </Container>
  );
}
