import React, { useState } from 'react'
import { Container, Row, Col, Image, Button, Card, Carousel } from "react-bootstrap"
import "../../ComponentsCss/detailpage.css";
import Navigation from "../Navigation.js";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/client";
import { USER_DETAIL, GET_USER, GET_CHECK } from "./grqphqloperation/query";
import { FaUserCircle, FaEnvelopeOpen, FaPhoneAlt, FaMapMarkerAlt, FaUserFriends,FaArrowCircleDown   } from "react-icons/fa";
import { USER_CONNECTION } from './grqphqloperation/mutation';
import { USER_NOTIFICATION } from "./grqphqloperation/query"
import swal from "sweetalert";
import LoadingBar from '../LoadingBar.jsx';
import './details.css'
import Footer1 from '../Footer1.js';

function Detailpage() {
  const { id } = useParams();
  const userFor = localStorage.getItem("userId");
  const token = localStorage.getItem('token');
  const [connId,setConnId] = useState('');
  const navigate = useNavigate()
  const { data: userData, loading, error } = useQuery(GET_USER, {
    variables: {
      "id": `${userFor}`
    }
  })

  const { data:newData } = useQuery(GET_USER, {
    variables: {
      "id": `${connId}`
    }
  })


  const [createConnection] = useMutation(USER_CONNECTION, {
    refetchQueries: [
      USER_NOTIFICATION,
      'getConnectionFor'
    ]
  })

  const { data } = useQuery(USER_DETAIL, {
    variables: {
      id: `${id}`,
    },
  });

  const handleConnection = (id) => {

    setConnId(id);

    if(userFor && token){
      let {getOneUser} = userData;
      if(getOneUser){
        if(getOneUser.subscriptionPlanStatus === 'active'){
          createConnection({
      variables: {
        userConnection: {
          connectionFor: `${id}`,
          connectionForFname: `${data && data.getOneUser.firstName}`,
          connectionForLname: `${data && data.getOneUser.lastName}`,
          connectionForAge: `${data && data.getOneUser.age}`,
          connectionForCaste: `${data && data.getOneUser.caste}`,
          connectionForContact: `${data && data.getOneUser.mobile}`,
          connectionFrom: `${userFor}`,
          connectionFromFname: `${userData.getOneUser.firstName}`,
          connectionFromLname: `${userData.getOneUser.lastName}`,
          connectionFromCaste: `${userData.getOneUser.caste}`,
          connectionFromAge: `${userData.getOneUser.age}`,
          connectionFromContact: `${userData.getOneUser.mobile}`,
        }

      }, refetchQueries: [
        USER_NOTIFICATION,
        'getConnectionFor'
      ]
    })
    swal({
      title: "Successful",
      text: "Connection Successfully!!!",
      icon: "success",
    });
    window.location.reload(true)
        }else{
          navigate('/Subcriptionplan');
        }
      }
    }else{
      navigate('/userLogin');
    }
  }

  
  if (data && data.getOneUser.dob === "pending") {
    navigate("/userDashboard")
  }

  const { data: check } = useQuery(GET_CHECK, {
    variables: {
      userFor: `${id}`,
      userFrom: `${userFor}`
    }
  })

  const handleDownload = (userId)=>{
    navigate(`/print/${userId}`);
  };

  return (
    <>
      <LoadingBar isLoading={loading} />
      <Navigation />
      <br></br>
      <Container style={{ marginTop: "70px", background: "#dfe4ea"}}>
        <Row>
          <Col md={12} className="boxIn" style={{borderRadius:'7px'}}>
            <Row>
              <Col md={4}>
                <Image src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getOneUser.profile }`} style={{ width: "200px", height: "200px", marginTop: "50px", borderRadius: "50%", marginLeft: "50px" }} className="img-fluid mx-auto d-block" alt="Responsive image" />
              </Col>
              <Col md={4}>
                <h5 style={{ marginTop: "80px", color: "#fff", fontSize: "20px", marginLeft: "25px", fontFamily: "Dm Sans" }}><FaUserCircle /> {data && data.getOneUser.firstName ? data.getOneUser.firstName : 'loading...'}  {data && data.getOneUser.lastName ? data.getOneUser.lastName : ''} </h5>
                <h5 style={{ marginTop: "10px", color: "#fff", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> <FaUserFriends /> {data && data.getOneUser.gender ? data.getOneUser.gender : 'loading...'}</h5>
                <h5 style={{ marginTop: "10px", color: "#fff", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> <FaPhoneAlt />  {data && data.getOneUser.mobile ? data.getOneUser.mobile : 'loading...'}</h5>
                <h5 style={{ marginTop: "10px", color: "#fff", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> <FaEnvelopeOpen />  {data && data.getOneUser.email ? data.getOneUser.email : 'loading...'}</h5>
                <h5 style={{ marginLeft: "25px", color: "#fff", fontSize: "15px", fontFamily: "Dm Sans" }} ><FaMapMarkerAlt /> {data && data.getOneUser.city ? data.getOneUser.city : 'loading...'}</h5>
              </Col>
              <Col md={4}>
                {
                  check && check.getCheck === "true" ?
                    <>
                      <Button style={{ minWidth: "150px", marginTop: "30px", background: "transparent", border: "2px solid #fff", borderRadius: "50px" }} disabled >Connected</Button>
                    </>
                    :
                    <>
                      <Button style={{ minWidth: "150px", marginTop: "30px", background: "transparent", border: "2px solid #fff", borderRadius: "50px" }} onClick={() => handleConnection(data && data.getOneUser.id)}  >Connect</Button>
                    </>
                }
                <Button style={{ marginLeft:'5px',minWidth: "150px", marginTop: "30px", background: "transparent", border: "2px solid #fff", borderRadius: "50px" }} onClick={()=>handleDownload(data && data.getOneUser.id)} ><FaArrowCircleDown/> Download</Button>
              </Col>
            </Row>
          </Col>
          <Row>
            <Col md={6}>
              <Carousel style={{ marginTop: "20px" }}>
                {
                  data && data.getOneUser.picture[0].split(',').map(pdata =>
                    <Carousel.Item>
                      <Image
                        style={{ height: "400px", borderRadius: "20px",objectFit:'contain',borderRadius:'7px' }}
                        className="d-block w-100 "
                        src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${pdata}`}
                        alt="First slide"
                      />
                    </Carousel.Item>
                  )}
              </Carousel>
            </Col>
            <Col md={6}>
              <Row>
                <Col className='boxIx2 ' style={{ marginTop: "20px" }}>
                  <h6 style={{ textAlign: "center", marginTop: "20px" }}>Personal Information</h6>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}>Merital Status : {data && data.getOneUser.meritalStatus}  </h5>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}>Age : {data && data.getOneUser.age}  </h5>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> Height : {data && data.getOneUser.height}</h5>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }} > Date of Birth : {data && data.getOneUser.dob}</h5>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> Religion : {data && data.getOneUser.religion}</h5>
                  <h5 style={{ marginTop: "10px", fontSize: "15px", marginLeft: "25px", fontFamily: "Dm Sans" }}> Caste : {data && data.getOneUser.caste}</h5>
                </Col>
              </Row>
              <Row>
                <Col >
                </Col>
              </Row>
            </Col>
          </Row>
        </Row>
      </Container>
      {/* <div className="profile-container">
        <div className="inner-card">
          <div className="img-box">
            <img src={`https://byaahlagan-profile-image.s3.us-east-2.amazonaws.com/${data && data.getOneUser.profile}`} />
          </div>
          <center className='name' style={{ color: 'white' }}> <h3>{data && data.getOneUser.firstName ? data.getOneUser.firstName : 'Loading...'} {data && data.getOneUser.lastName ? data.getOneUser.lastName : ''}</h3> </center>
          <Row className='p-3'>
            <Col sm={6}>
              <Card style={{background:'transparent',color:'white',border:'none'}} className="mb-2">
                <Row>
                  <Col md={6}>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Name :</span> {data && data.getOneUser.firstName ? data.getOneUser.firstName : null} {data && data.getOneUser.lastName ? data.getOneUser.lastName : null} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Email :</span> {data && data.getOneUser.email} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Age :</span> {data && data.getOneUser.age} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Gender :</span> {data && data.getOneUser.gender} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Date Of Birth :</span>{data && data.getOneUser.dob}  </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Caste :</span>{data && data.getOneUser.caste} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Height :</span>{data && data.getOneUser.height} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Religion :</span> {data && data.getOneUser.religion} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Occupation :</span> {data && data.getOneUser.occupation} </p>
                    <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Account Status :</span> {data && data.getOneUser.status} </p>
                  </Col>
                </Row>
              </Card>
            </Col>

            <Col sm={6}>
              <Card style={{background:'transparent',color:'white',border:'none'}} className="mb-2">
                <Col md={6}>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Contact :</span> {data && data.getOneUser.mobile} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>City :</span> {data && data.getOneUser.city ? data.getOneUser.city : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Country :</span> {data && data.getOneUser.country ? data.getOneUser.country : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Zip Code :</span> {data && data.getOneUser.pincode ? data.getOneUser.pincode : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Merital Status :</span> {data && data.getOneUser.meritalStatus ? data.getOneUser.meritalStatus : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Booking Ammount :</span> {data && data.getOneUser.bookingAmount ? data.getOneUser.bookingAmount : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Subscription :</span> {data && data.getOneUser.subscriptionPlanName ? data.getOneUser.subscriptionPlanName : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Subscription Status :</span> {data && data.getOneUser.subscriptionPlanStatus ? data.getOneUser.subscriptionPlanStatus : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Payment I'D :</span> {data && data.getOneUser.razorpayPaymentId ? data.getOneUser.razorpayPaymentId : null} </p>
                  <p style={{ marginLeft: 10, fontFamily: "Dm sans", fontSize: 20,color:'white' }}><span style={{ fontWeight: "bold", fontFamily: "Dm sans" }}>Account I'D :</span> {data && data.getOneUser.id ? data.getOneUser.id : null} </p>
                </Col>
              </Card>
            </Col>
          </Row>
          <Card.Footer className=" footer-details mt-3 m-2 d-flex" style={{gap:'10px'}}>
            <div className="contact-item email">+91 {data && data.getOneUser.mobile}</div>
            <div className="contact-item email">{data && data.getOneUser.email}</div>
          </Card.Footer>
        </div>
      </div> */}
      <Footer1 />
    </>
  )
}

export default Detailpage