import React,{useState} from 'react'
import "../ComponentsCss/CardPlan.css"
import { FaRupeeSign ,FaCheck} from "react-icons/fa";
import Navigation from './Navigation';
import logo from '../Images/logo.png';
import {Image,Nav} from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import { loadStripe } from "@stripe/stripe-js";
import { useMutation, useQuery } from "@apollo/client";
import { USER_SUBSCRIPTION ,MUTATION_CREATE_STRIPE_LINK} from "./user/grqphqloperation/mutation";
import { GET_ALL_PLAN, GET_USER } from './user/grqphqloperation/query';
import { useNavigate } from 'react-router-dom';
import Footer1 from "./Footer1";
const stripePromise = loadStripe("pk_live_51NuvyYSBrb5oXsTyyAWehZlWXUW5oeY3WbgMt1Z2TxM2ilmsOGeuUdQqMVPlO1Q7Uh8opL6qny53om7UFhabC4NT000l86dziG"); // Replace with your actual Stripe publishable key

export default function CardPlan() {

 const{data:dataPlan,loading:loadingPlan} = useQuery(GET_ALL_PLAN)




  const userId = localStorage.getItem("userId");
  let token = localStorage.getItem("token");


  const [stop, setStop] = useState(false)
  const [createStripeLink, { data: stripeData, loading: stripeLoading }] = useMutation(MUTATION_CREATE_STRIPE_LINK); // Use the mutation
  const [subscription, { data, loading, error }] = useMutation(USER_SUBSCRIPTION, {
    refetchQueries: [
      GET_USER
    ]
  });

  console.log("dataPlan",dataPlan)

  const bookingPaymentDoneHandle = async (amountprice, subscriptionPlanName) => {
    try {
      await subscription({
        variables: {
          subscriptionInput: {
            userId: `${userId}`,
            subscriptionPlanName: `${subscriptionPlanName}`,
            bookingAmount: `${amountprice}`
          },
        },
      }).then(() => {
        createStripeLink({
          variables: {
            "bookingAmount": `${amountprice}`,
            "subscriptionPlanName": `${subscriptionPlanName}`
          }
        })
      });
    } catch (error) {
      console.error('Error processing payment:', error);
      alert('Error processing payment. Please try again.');
    }

  };

  if (stripeData && stop === false) {
    window.open(stripeData.stripeCheckout);
    window.close();
    setStop(true)
  }

  if (stop === true) {
    window.open("blank", "_self");
    window.close();
  }

  const navigate = useNavigate();

  const handleNavigation = (price,planeName)=>{

    if(userId && token){
      navigate('/PaymentUser',{state:{price:price,plan:planeName}})
    }else{
      navigate('/userLogin');
    }


    
  }



  return (
    <>
   
   
    <div className='cardBG'>
   
    </div>
    
    <div className='card-conatainer'>
  <div className='card-container-box'>
  <div style={{display:"flex",justifyContent:"space-between"}}>
  <div>
  <LinkContainer to='/' style={{ textDecoration: 'none' ,width:250,height:80}}>
   <Image src={logo} style={{width:250,height:80}}/>
   </LinkContainer>

  </div>
  <div>
  <LinkContainer to='/' style={{ textDecoration: 'none',marginTop:20}}>
  <Nav.Link className='headerLink' href="#home" style={{ textDecoration: 'none' }}>Back</Nav.Link>
  </LinkContainer>
  </div>
  </div>
  <h3 style={{textAlign:"center"}} >Upgrade now & Get Premium benefits !</h3>
  <h6 style={{textAlign:"center",fontWeight:"400",color:"#fff"}} >Upgrade now & Get Premium benefits !</h6>
      
      <div className='card-container-box-row'>
        {/* card start */}
        <div  className='card-container-box-col' style={{cursor:"pointer"}}>
        <h4 style={{textAlign:"center",marginTop:20,fontSize:15,color:"#3498db"}}>{dataPlan && dataPlan.getPlan[0].plantype}  <span style={{fontWeight:"480",fontSize:15,marginBottom:5,color:"#777"}}>3 Months</span></h4>
         
         <p style={{textAlign:"center",marginTop:20,color:"#2ecc71",fontWeight:"800"}}><span>20% off</span> <span style={{marginLeft:10,color:"#777",fontWeight:"400",textDecoration:"line-through"}}>₹ 499</span></p>

           <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:30,}}>
            <FaRupeeSign size={29}/>
            <h5 style={{fontSize:33}} >299</h5>
           </div>
           <p style={{textAlign:"center",fontSize:10}}>₹ 299 per month</p>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:45,borderRadius:100,boxShadow:"2px 2px 2px 2px rgba(0,0,0,0.2)",display:"flex",alignItems:"center",justifyContent:"center",border:"1px solid #777"}} onClick={() => handleNavigation("299","Silver")}>
              <p style={{textAlign:"center",marginTop:12,cursor:"pointer"}}>Continue</p>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:20}}>
            <div style={{width:"80%",height:20,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Send unlimited Messages</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center"}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>View upto 75 Contact Number</p>
              </div>
            </div>

           </div>

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Shadi Live passes worth INR2000</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Standout from other Profiles</p>
              </div>
            </div>
           </div>
         

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:15}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Let Matches contact you directory</p>
              </div>
            </div>
           </div>
         <div>
         </div>
        </div>

{/* card end */}

{/* card start */}
        <div  className='card-container-box-col' style={{cursor:"pointer"}}>
        <h4 style={{textAlign:"center",marginTop:20,fontSize:15,color:"#e67e22"}}>{dataPlan && dataPlan.getPlan[1].plantype} <span style={{fontWeight:"480",fontSize:15,marginBottom:5,color:"#777"}}>3 Months</span></h4>
         
         <p style={{textAlign:"center",marginTop:20,color:"#2ecc71",fontWeight:"800"}}><span>30% off</span> <span style={{marginLeft:10,color:"#777",fontWeight:"400",textDecoration:"line-through"}}>₹ 599</span></p>

           <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:30,}}>
            <FaRupeeSign size={29}/>
            <h5 style={{fontSize:33}}>499</h5>
           </div>
           <p style={{textAlign:"center",fontSize:10}}>₹ 199 per month</p>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:45,borderRadius:100,boxShadow:"2px 2px 2px 2px rgba(0,0,0,0.2)",display:"flex",alignItems:"center",justifyContent:"center",border:"1px solid #777"}} onClick={() =>handleNavigation("499","Gold")}>
              <p style={{textAlign:"center",marginTop:12}}>Continue</p>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:20}}>
            <div style={{width:"80%",height:20,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Send unlimited Messages</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center"}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>View upto 75 Contact Number</p>
              </div>
            </div>

           </div>

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Shadi Live passes worth INR2000</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Standout from other Profiles</p>
              </div>
            </div>
           </div>
         

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:15}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Let Matches contact you directory</p>
              </div>
            </div>
           </div>
         <div>
         </div>
        </div>

        {/* card end */}

{/* card start */}
        <div  className='card-container-box-col' style={{cursor:"pointer"}}>
        <h4 style={{textAlign:"center",marginTop:20,fontSize:15,color:"#8e44ad"}}>{dataPlan && dataPlan.getPlan[2].plantype}<span style={{fontWeight:"480",fontSize:15,marginBottom:5,color:"#777",marginLeft:2}}>6 Months</span></h4>
         
         <p style={{textAlign:"center",marginTop:20,color:"#2ecc71",fontWeight:"800"}}><span>30% off</span> <span style={{marginLeft:10,color:"#777",fontWeight:"400",textDecoration:"line-through"}}>₹ 699</span></p>

           <div style={{display:"flex",justifyContent:"center",alignItems:"center",height:30,}}>
            <FaRupeeSign size={29}/>
            <h5 style={{fontSize:33}} >599</h5>
           </div>
           <p style={{textAlign:"center",fontSize:10}}>₹ 166 per month</p>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:45,borderRadius:100,boxShadow:"2px 2px 2px 2px rgba(0,0,0,0.2)",display:"flex",alignItems:"center",justifyContent:"center",border:"1px solid #777"}}  onClick={() =>handleNavigation("599","Platinuim")} >
              <p style={{textAlign:"center",marginTop:12}}>Continue</p>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:20}}>
            <div style={{width:"80%",height:20,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Send unlimited Messages</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center"}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>View upto 75 Contact Number</p>
              </div>
            </div>

           </div>

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center"}}>
            <div style={{width:"80%",height:40,borderRadius:100,display:"flex",alignItems:"center",}}>
                <FaCheck color='#7bed9f'/>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:6}}>Shadi Live passes worth INR2000</p>
              </div>
            </div>
           </div>
           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:10}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Standout from other Profiles</p>
              </div>
            </div>
           </div>
         

           <div style={{display:"flex",width:"100%",alignItems:"center",justifyContent:"center",marginTop:15}}>
            <div style={{width:"80%",height:25,borderRadius:100,display:"flex",alignItems:"center",}}>
                <div>
              <p style={{marginTop:12,fontSize:13,marginLeft:15,textDecoration:"line-through"}}>Let Matches contact you directory</p>
              </div>
            </div>
           </div>
         <div>
         </div>
        </div>
  </div>
  </div>

</div>
<div className='footCardPlan'>
<Footer1/>
</div>
    </>
  )
}