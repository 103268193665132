import React from 'react'
import { Container, Row, Col, Tab, Tabs } from 'react-bootstrap'
import '../ComponentsCss/Matrimonysite.css'
export default function MatrimonySite() {
    return (
        <Container style={{ marginTop: 50 }} >
            <Row>
                <Col md={12} >
                    <h2 style={{ color: 'black', fontWeight: 'bold', textAlign: 'center', fontFamily: 'DM Sans' }}>
                        Browse Matrimonial  <span style={{ color: '#ff5a60', fontWeight: 'bold', textAlign: 'center' }}>Profiles By</span>
                    </h2>
                </Col>
            </Row>
            <Row >
                <Col md={12}   >
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
                        <Tab eventKey="home" title="Religion">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>Hindu</p>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="profile" title="Caste">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>Korku</p>
                                    <p className='Castbox'>Gond</p>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="contact" title="City">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>Chindawada</p>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="state" title="State">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>Madhya Pradesh</p>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="country" title="Country">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>India</p>
                                </Col>
                            </Row>
                        </Tab>
                        <Tab eventKey="occupation" title="Occupation">
                            <Row>
                                <Col md={12}>
                                    <p className='Castbox'>Business Owner </p>
                                    <p className='Castbox'>Supervisor </p>
                                    <p className='Castbox'>Software Engineer</p>
                                    <p className='Castbox'>Technical</p>
                                    <p className='Castbox'>Teaching</p>
                                </Col>
                            </Row>
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    )
}
