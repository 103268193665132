import React, { useEffect } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FaSadTear } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Navigation from "./Navigation";
function BuySub() {

  const navigate = useNavigate()

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[]);

  function handleClick() {

    navigate('/Subcriptionplan')
  }

  return (
    <>
      <Navigation />
      <Container style={{marginTop:'150px'}}>
        <Row style={{ marginTop: "20px" }}>
          <Col>
            <FaSadTear style={{ width: "100px", height: "100px" }} className="mx-auto d-block" />

            <h3 style={{ textAlign: "center", fontFamily: "DM sans" }}>You don't have any Subscription</h3>

            <h6 style={{ textAlign: "center", fontFamily: "DM sans" }}>Upgrade  Your profile to Make Profile</h6>


            <Button className="mx-auto d-block" style={{ marginBottom: "160px" }} onClick={handleClick}>Upgrade</Button>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BuySub;
