import React from 'react'
import AdminPageLayout from '../admin/AdmnPageLayout.js'
import { useQuery } from '@apollo/client'
import { Admin_Membership_Users } from '../user/grqphqloperation/query.js'
import ProfileList from '../admin/partisian/ProfileList.js'
import LoadingBar from '../LoadingBar.jsx'

function MembershipUsers() {
  const { data, loading } = useQuery(Admin_Membership_Users, {
    pollInterval: 10000,
  })
  return (
    <>
      <LoadingBar isLoading={loading} />
      <AdminPageLayout>
        {
          data && <ProfileList profiles={data.getMembershipUsers ? data.getMembershipUsers : []} />
        }
      </AdminPageLayout>
    </>
  )
}

export default MembershipUsers