 import {gql}  from "@apollo/client"

 export const SIGN_UP = gql`

query Query {
    getUsers {
      id
      firstName
      lastName
      email
      contact
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
    }
  }
`;

export const GET_ALL_USER =gql`
query GetAllUsers {
  getAllUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    isConnected
    userIdType
    userIdNumber
    userIdPic
    friends
  }
}
`

export const GET_USER_ALL = gql`
query Query {
  getAllUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    userIdType
    userIdNumber
    userIdPic
    friends
  }
}
`


export const GET_PROFILE =gql`
query GetcreateProfiles {
    getcreateProfiles {
      _id
      userId
      firstName
      lastName
      email
      mobile
      caste
      profile
      picture
      gender
      createProfilefor
      height
      age
      religion
      yourdiet
      meritalStatu
      youlivewithfamily
      state
      city
      country
      pincode
      occupation
      status
      highestQuqlification
      yourWorkWith
      As
      yourIncome
      monthOryear
      yearlyIncome
      dob
      friends
    }
  }
`
export const USER_DETAIL =gql`
query GetByIdUser($getByIdUserId: ID!) {
  getByIdUser(id: $getByIdUserId) {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    status
    razorpayPaymentId
    bookingAmount
    isConnected
    income
    friends 
    
  
  }
}

`;


export const GET_USER =gql`

query Query($id: ID) {
  getOneUser(_id: $id) {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    status
    razorpayPaymentId
    bookingAmount
    isConnected
    income
    userIdType
    userIdNumber
    userIdPic
    friends {
      id
      firstName
      lastName
      email
      mobile
      dob
      caste
      profile
      picture
      gender
      height
      age
      religion
      meritalStatus
      state
      city
      country
      pincode
      occupation
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
      razorpayPaymentId
      bookingAmount
      isConnected
      income
      userIdType
      userIdNumber
      userIdPic
    }
  }
}

`;

export const USER_NOTIFICATION =gql`
query Query($userId: ID) {
  getConnectionFor(userId: $userId) {
    connectionFor
    connectionForFname
    connectionForAge
    connectionForLname
    connectionForCaste
    connectionForContact
    connectionFrom
    connectionFromFname
    connectionFromLname
    connectionFromCaste
    connectionFromAge
    connectionFromContact
    timeDate
    status
  }
}
`;

export const CONNECTION_FROM_PROFILE =gql`

  query Query($userId: ID) {
  getConnectionFrom(userId: $userId) {
    connectionFor
    connectionForFname
    connectionForLname
    connectionForAge
    connectionForCaste
    connectionForContact
    connectionFrom
    connectionFromFname
    connectionFromLname
    connectionFromCaste
    connectionFromAge
    connectionFromContact
    timeDate
    status
  }
}

`


export const GET_USER_INFO=gql`
query GetUsers {
  getUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    isConnected
  }
}
`
export const GET_CHECK =gql`
query Query($userFor: ID, $userFrom: ID) {
  getCheck(userFor: $userFor, userFrom: $userFrom)
}
`
export const GET_SEARCH_USER_FILTER = gql`
query SearchUser($gender: String, $age: String, $caste: String, $logid: String) {
  searchUser(gender: $gender, age: $age, caste: $caste, Logid: $logid) {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    isConnected
  }
}
`
export const GET_ALL_MESSAGE=gql`
query GetContact {
  getContact {
    id
    name
    contact
    email
    message
    createdDateTime
    status
  }
}
`

export const GET_SINGLE_USER_ALL_CONNECTIONS = gql`
query Query($connId: ID) {
  getSingleUserAllConnection(connId: $connId) {
    connectionFor
    connectionForFname
    connectionForLname
    connectionForAge
    connectionForCaste
    connectionForContact
    connectionFrom
    connectionFromFname
    connectionFromLname
    connectionFromCaste
    connectionFromAge
    connectionFromContact
    timeDate
    status
  }
}

`

export const RANDOM_MATCHES = gql`
query RandomUsers {
  randomUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
  }
}
`

export const Admin_Blocked_Users = gql`
query GetBlokedUsers {
  getBlokedUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    isConnected
  }
}
`

export const Admin_Membership_Users = gql`
query GetMembershipUsers {
  getMembershipUsers {
    id
    firstName
    lastName
    email
    mobile
    dob
    caste
    profile
    picture
    gender
    height
    age
    religion
    meritalStatus
    state
    city
    country
    pincode
    occupation
    createDateTime
    subscriptionDate
    subscriptionPlanName
    subscriptionPlanStatus
    income
    status
    razorpayPaymentId
    bookingAmount
    isConnected
  }
}
`


export const PAYMENT_INTRAGATION = gql`
query Query {
  getPayament {
    id
    imageQR
    upiID
    status
  }
}
`

export const GET_ALL_PLAN = gql`
query Query {
  getPlan {
    id
    plantype
    price
    status
  }
}
`

export const GET_ADMIN_PASSWORD = gql`
query GetAdminContact($contact: String) {
  getAdminContact(contact: $contact) {
    id
    name
    contact
    email
    userName
    password
    status
  }
}
`

export const GET_FRIEND_REQUEST_BY_ID_USERID = gql`
query GetFriendRequestsByUserId($userId: ID) {
  getFriendRequestsByUserId(userId: $userId) {
    id
    status
    requester {
      id
      firstName
      lastName
      email
      mobile
      dob
      caste
      profile
      picture
      gender
      height
      age
      religion
      meritalStatus
      state
      city
      country
      pincode
      occupation
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
      razorpayPaymentId
      bookingAmount
      isConnected
      income
      userIdType
      userIdNumber
      userIdPic
    }
    recipient {
      id
      firstName
      lastName
      email
      mobile
      dob
      caste
      profile
      picture
      gender
      height
      age
      religion
      meritalStatus
      state
      city
      country
      pincode
      occupation
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
      razorpayPaymentId
      bookingAmount
      isConnected
      income
      userIdType
      userIdNumber
      userIdPic
    }
  }
}
`


export const GET_FRIEND_BY_ID_USERID = gql`
query GetFriendRequests($userId: ID) {
  getFriendRequests(userId: $userId) {
    id
    status
    recipient {
      id
      firstName
      lastName
      email
      mobile
      dob
      caste
      profile
      picture
      gender
      height
      age
      religion
      meritalStatus
      state
      city
      country
      pincode
      occupation
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
      razorpayPaymentId
      bookingAmount
      isConnected
      income
      userIdType
      userIdNumber
      userIdPic
    }
    requester {
      id
      firstName
      lastName
      email
      mobile
      dob
      caste
      profile
      picture
      gender
      height
      age
      religion
      meritalStatus
      state
      city
      country
      pincode
      occupation
      createDateTime
      subscriptionDate
      subscriptionPlanName
      subscriptionPlanStatus
      status
      razorpayPaymentId
      bookingAmount
      isConnected
      income
      userIdType
      userIdNumber
      userIdPic
    }
  }
}
`

export const GET_FRIEND_CHECK = gql`
query Query($requesterId: ID!, $recipientId: ID!) {
  getFriendRequestExists(requesterId: $requesterId, recipientId: $recipientId)
}
`